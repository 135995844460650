<template>
  <WStatsWidget
    :cols="12"
    :empty="allVerbatims?.length === 0"
    :justifyCenter="false"
    :loading="!allVerbatims"
    :maxHeight="expand ? '600px' : '300px'"
    :subtitle="displayedSubtitle"
    :title="title"
    @onContentScrollBottom="seeMore(perPage)"
    class="review-list-widget"
    contentPadding="0.75em"
    contentWidth="100%"
    minHeight="150px"
  >
    <template #options>
        <div class="d-flex align-center">
          <WSearchInput v-model="search" input-width="150px" v-if="formatedVerbatims" />

          <div class="ml-3 pointer">
           <w-icon class="f-18" @click="setFeeling(0)" :icon="feeling == 0 ? 'smiley-happy' : 'smiley-happy-outline'"/>
           <w-icon class="f-18 ml-2" @click="setFeeling(1)" :icon="feeling == 1 ? 'smiley-neutral': 'smiley-neutral-outline'"/>
           <w-icon class="f-18 ml-2" @click="setFeeling(2)" :icon="feeling == 2 ? 'smiley-angry' : 'smiley-angry-outline'"/>
          </div>

          <div class="ml-3">
            <v-icon class="pointer" @click="toggleDateSort">{{ dateSort == 'desc' ? 'mdi-sort-clock-ascending-outline' : 'mdi-sort-clock-descending-outline' }}</v-icon>
          </div>

          <div class="ml-3">
            <w-drop-down-menu
              data-html2canvas-ignore
              :items="exportOptions"
              :disabled="exportLoading !== null"
              :iconProperties="{ size: 'f-16', color: (exportLoading ? 'c-lightgrey' : 'c-grey') }"
              :icon="(exportLoading ? 'mdi-download-off' : 'mdi-download')"
            />
          </div>

        </div>
    </template>

    <template #content>
      <div class="verbatim-rows" v-if="formatedVerbatims" ref="verbatim-rows">
        <div v-for="verbatim, idx of formatedVerbatims" :key="verbatim.verbatimId" class="verbatim-row">
          <div class="d-flex align-center">
            <div class="verbatim-row-content" :style="{ maxWidth: $vuetify.breakpoint.smAndDown ? '90%' : '60%' }">
              <WBubble
                :content="verbatim.content"
                :stripeContainer="verbatim.disabled"
                class="align-center"
              >
                <template #icon>
                  <WFeelingIcon :feeling="verbatim.feeling" class="pr-3"/>
                </template>

                <template #footer>
                  <div class="d-flex align-center ga-1">
                    <WDate :date="verbatim.reviewDate" font="f-12" icon="mdi-clock-outline" />
                    <div
                      v-if="hasRightClassifyVerbatims && verbatim"
                      class="d-flex align-center"
                    >
                      <VerbatimTextAnalysisInfo
                        :processedAt="verbatim.textAnalysisProcessedAt"
                        :isReclassified="verbatim.isReclassified"
                      />
                    </div>
                  </div>
                </template>
              </WBubble>
            </div>
            <div class="verbatim-row-tags text-align-left ml-2 flex-grow-1 hidden-sm-and-down">
              <span
                v-for="topic of verbatim.topics"
              >
                <span
                  v-if="!topic.disabled && !topic.hide"
                  class="d-inline-block ml-2 my-1"
                  :id="getMenuActivatorId(verbatim, topic)"
                  @click="hasRightClassifyVerbatims && setMenuDatas(verbatim, topic)"
                >
                  <v-chip
                    label
                    small
                    outlined
                    :style="{ cursor: hasRightClassifyVerbatims ? 'pointer' : 'default' }"
                    :key="topic.topicId"
                  >
                    <WFeelingIcon :small="true" class="pr-2" :feeling="topic.feeling"/>
                      {{ _upperFirst(topic.topicName) }}
                  </v-chip>
                </span>
              </span>
              <span
                v-if="notDisabledHiddedTopics(verbatim.topics).length > 0"
                class="d-inline-block ml-2 my-1"
                @click="showHiddedTopics(verbatim.verbatimId)"
              >
                <v-chip
                  label
                  small
                  outlined
                  :style="{ cursor: 'pointer'}"
                  class="px-2"
                >
                  {{ `+${notDisabledHiddedTopics(verbatim.topics).length}` }}
                </v-chip>
              </span>
            </div>

            <div class="verbatim-row-actions ml-2">
              <router-link :to="{ name: 'Feedbacks', params: { status: 'all', subStatus: 'all', voterUniqueId: verbatim.voterUniqueId, section: 'review' } }" target="_blank">
                <v-icon class="c-lightgrey" small>mdi-open-in-new</v-icon>
              </router-link>
            </div>
          </div>

          <hr class="my-2" :style="{ borderTop: $colors.lightgrey }" v-if="idx + 1 < formatedVerbatims.length"/>
        </div>

        <TopicsVerbatimClassificationMenu
          :menuActivatorId="menuActivatorId"
          :verbatimId="menuVerbatimId"
          :topics="topics"
          @createTopicsVerbatims="(topicsVerbatim) => createTopicsVerbatims(topicsVerbatim)"
          @updateOrDeleteTopicsVerbatims="(topicsVerbatim) => updateOrDeleteTopicsVerbatims(topicsVerbatim)"
          @onCloseMenu="onCloseMenu"
        />

        <v-progress-circular
          v-if="!lastVerbatimFetched"
          indeterminate
          color="primary"
          class="mt-3"
        ></v-progress-circular>
      </div>
    </template>

    <template #footer-left>
      <span @click="toggleExpand" class="pointer" v-if="![0,1,2,3].includes(allVerbatims?.length)">{{ expand ? $t('see_less') : $t('see_more')  }}</span>
    </template>

    <template #footer-right>
      <span v-if="exportLoading">
        {{ exportLoading }}
        <v-icon small @click="exportLoading = null" color="error" class="ml-1">mdi-close</v-icon>
      </span>
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TopicsVerbatimClassificationMenu from '../../../shared/TopicsVerbatimClassificationMenu'
  import VerbatimTextAnalysisInfo from '../../../shared/VerbatimTextAnalysisInfo'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import TopicSemanticMixin from '@statistics/shared/topic_semantic_mixin'
  import exportToExcel from '@shared/helpers/export-to-excel.js'
  import dayjs from 'dayjs'
  import _upperFirst from 'lodash/upperFirst'
  import _uniqBy from "lodash/uniqBy"
  import _sortBy from "lodash/sortBy"
  import _uniq from "lodash/uniq"

  export default {
    name: "SentimentReviewsWidget",
    props: [ 'campaign', 'zoomedData' ],
    components: {
      TopicsVerbatimClassificationMenu,
      VerbatimTextAnalysisInfo
    },
    mixins: [
      WidgetMixin,
      TopicSemanticMixin
    ],
    data() {
      return {
        perPage: 100,
        offset: 0,
        loading: false,
        expand: false,
        totalVerbatim: 0,
        lastVerbatimFetched: true,
        allVerbatims: null,
        feeling: null,
        search: null,
        dateSort: "desc",
        exportLoading: null,
        menuActivatorId: undefined,
        menuVerbatimId: undefined,
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardParams',
        'hasRightClassifyVerbatims'
      ]),
      title() {
        return this.$t("reporting_verbatim")
      },
      baseRequest() {
        let request = this.dashboardFilterRequest.where({ campaign_id: this.campaign.id})

        if (this.hasTextAnalysisEnabled) {
          request = request.where({ text_analysis_topics_id: this.topicOptions.map(topic => topic.value) })
        }

        if (this.zoomedData?.type == 'topic') {
          request = request.where({ [this.topicIdColumn]: this.zoomedData.id })
        } else if (this.zoomedData?.type == 'theme') {
          request = request.where({ [this.themeIdColumn]: this.zoomedData.id })
        }

        if (this.feeling !== null) {
          request = request.where({ feeling: this.feeling })
        }

        if (this.search !== null) {
          request = request.where({ verbatims_ts_content: { '@@': { search: this.search.normalize("NFD")?.replace(/\p{Diacritic}/gu, "")?.replace(/  +/g, ' ') } } })
        }

        if (this.dateSort !== null) {
          request = request.order(['MAX_voters_vote_at', this.dateSort])
        }

        return request
      },
      formatedVerbatims() {
        if (this.allVerbatims) {
          return this.formatVerbatims(this.allVerbatims)
        }
      },
      nbLoadedVerbatims() {
        return this.formatedVerbatims?.length || 0
      },
      topicOptions() {
        if (this.zoomedData.type == 'topic') {
          return [
            {
              text: `${this.zoomedData.theme.name} > ${this.zoomedData.topic.name}`,
              value: this.zoomedData.id
            }
          ]
        } else if (this.zoomedData.type == 'theme') {
          return this.zoomedData.topics.map(topic => ({
            text: `${topic.theme.name} > ${topic.topic.name}`,
            value: topic.topic.id
          }))
        }
      },
      exportOptions() {
        return [
          { title: this.perPage, onClick: this.exportFastExcel },
          { title: this.$t('all'), onClick: this.exportExcel }
        ]
      },
      indexTopicsVerbatimByVerbatimId() {
        return this.allVerbatims?.reduce((h, topicsVerbatimsByTopicId, index) => {
          for (const [topicId, topicsVerbatim] of Object.entries(topicsVerbatimsByTopicId)) {
            h[topicsVerbatim.verbatimId] = index
          }
          return h
        }, {})
      }
    },
    asyncComputed: {
      verbatims: {
        async get() {
          this.offset = 0
          this.allVerbatims = await this.fetchVerbatims(this.baseRequest, 0, this.perPage)
          this.offset = this.perPage
          this.lastVerbatimFetched = Object.values(this.allVerbatims).length < 10
        }
      },
      topics: {
        async get() {
          let request = this.$basedRequest().select({
            text_analysis_preferences: [
              { 'text_analysis_topics_id': { as: 'topicId' } },
              { 'topic_public_name': { as: 'topicName' } }
            ]
          }).where({
            campaign_id: this.campaign.id
          }).order('topic_public_name', 'asc')

          return (await this.$resolve(request)).data?.textAnalysisPreferences || []
        },
        default: undefined
      }
    },
    methods: {
      _upperFirst,
      notDisabledHiddedTopics(topics) {
        return topics.filter(topic => topic.hide && !topic.disabled)
      },
      showHiddedTopics(verbatimId) {
        const allVerbatimIndex = this.indexTopicsVerbatimByVerbatimId[verbatimId]

        for (const [topicId, topicVerbatim] of Object.entries(this.allVerbatims[allVerbatimIndex])) {
          this.allVerbatims[allVerbatimIndex][topicId]['hide'] = false
        }
        this.$set(this.allVerbatims, allVerbatimIndex, this.allVerbatims[allVerbatimIndex])
      },
      setMenuDatas(verbatim, topic) {
        if (!this.menuActivatorId) {
          this.menuActivatorId = `#id_${verbatim.verbatimId}_${topic.topicId}`
          this.menuVerbatimId = verbatim.verbatimId
        }
      },
      onCloseMenu() {
        this.menuActivatorId = undefined
        this.menuVerbatimId = undefined
      },
      getMenuActivatorId(verbatim, topic) {
        return `id_${verbatim.verbatimId}_${topic.topicId}`
      },
      createTopicsVerbatims(topicsVerbatim) {
        const [updatedTopicId, newTopicsVerbatim] = Object.entries(topicsVerbatim)[0]

        // hide topic not in the current Theme
        const topicIds = this.topicOptions.map(topic => topic.value)
        newTopicsVerbatim['hide'] = !topicIds.includes(parseInt(updatedTopicId))

        const allVerbatimIndex = this.indexTopicsVerbatimByVerbatimId[newTopicsVerbatim.verbatimId]

        if (this.allVerbatims[allVerbatimIndex][updatedTopicId]) {
          this.$set(this.allVerbatims[allVerbatimIndex], updatedTopicId, newTopicsVerbatim)
        } else {
          const addedTopicsVerbatim = { ...this.allVerbatims[allVerbatimIndex], ...topicsVerbatim }
          this.$set(this.allVerbatims, allVerbatimIndex, addedTopicsVerbatim)
        }
      },
      updateOrDeleteTopicsVerbatims(topicsVerbatim) {
        const [updatedTopicId, newTopicsVerbatim] = Object.entries(topicsVerbatim)[0]

        // hide topic not in the current Theme
        const topicIds = this.topicOptions.map(topic => topic.value)
        newTopicsVerbatim['hide'] = !topicIds.includes(parseInt(updatedTopicId))

        const allVerbatimIndex = this.indexTopicsVerbatimByVerbatimId[newTopicsVerbatim.verbatimId]

        this.$set(this.allVerbatims[allVerbatimIndex], updatedTopicId, newTopicsVerbatim)
      },
      setFeeling(feeling) {
        this.allVerbatims = null
        this.feeling = (this.feeling === feeling ? null : feeling)
      },
      toggleExpand() {
        this.expand = !this.expand
        this.$refs.verbatims?.focus()
      },
      toggleDateSort() {
        this.allVerbatims = null
        this.dateSort = (this.dateSort == 'desc' ? 'asc' : 'desc')
      },
      async seeMore(perPage) {
        if (!this.loading && !this.lastVerbatimFetched && this.formatedVerbatims) {
          this.loading = true
          const verbatims = await this.fetchVerbatims(this.baseRequest, this.offset + 1, perPage)
          this.offset += perPage
          if (Object.values(verbatims).length == 0) {
            this.lastVerbatimFetched = true
          }

          this.allVerbatims = [...this.allVerbatims,  ...verbatims ]
          this.loading = false
        }
      },
      async fetchVerbatims(baseRequest, offset, perPage) {
        let filteredTopicsVerbatimRequest = baseRequest.select({
          main_topics_verbatims: [
            { 'MAX_feeling': { as: 'feeling' } },
            { [`MAX_${this.topicIdColumn}`]: { as: 'topicId' } },
            { [`MAX_${this.themeIdColumn}`]: { as: 'themeId' } },
            { [`MAX_${this.topicPublicNameColumn}`]: { as: 'topicName' } },
            { "MAX_voters_unique_id": { as: 'voterUniqueId' } },
            { "MAX_verbatims_content": { as: 'content' } },
            { "MAX_verbatims_id": { as: 'verbatimId' } },
            { "MAX_voters_vote_at": { as: 'reviewDate' } },
            { "MAX_verbatims_text_analysis_processed_at": { as: 'verbatimTextAnalysisProcessedAt' } },
            { "COUNT_topics_verbatim_reclassifications_id": { as: 'topicsVerbatimReclassificationsCount' } }
          ]
        }).limit(
          perPage
        ).offset(
          offset
        ).group([
          'verbatim_id',
          this.topicIdColumn
        ])

        let resultFilteredTopicsVerbatim = (await this.$resolve(filteredTopicsVerbatimRequest)).data

        let verbatimIds = Object.keys(resultFilteredTopicsVerbatim)
        let allTopicsVerbatimForVerbatims = this.$basedRequest().select({
          main_topics_verbatims: [
            { 'MAX_feeling': { as: 'feeling' } },
            { [`MAX_${this.topicIdColumn}`]: { as: 'topicId' } },
            { [`MAX_${this.themeIdColumn}`]: { as: 'themeId' } },
            { [`MAX_${this.topicPublicNameColumn}`]: { as: 'topicName' } },
            { "MAX_voters_unique_id": { as: 'voterUniqueId' } },
            { "MAX_verbatims_content": { as: 'content' } },
            { "MAX_verbatims_id": { as: 'verbatimId' } },
            { "MAX_voters_vote_at": { as: 'reviewDate' } },
            { "MAX_verbatims_text_analysis_processed_at": { as: 'verbatimTextAnalysisProcessedAt' } },
            { "COUNT_topics_verbatim_reclassifications_id": { as: 'topicsVerbatimReclassificationsCount' } }
          ]
        }).where({
          verbatim_id: verbatimIds
        }).group([
          'verbatim_id',
          this.topicIdColumn
        ])

        let resultAllTopicsVerbatimForVerbatims = (await this.$resolve(allTopicsVerbatimForVerbatims)).data
        for (const [verbatimId, topicsVerbatims] of Object.entries(resultAllTopicsVerbatimForVerbatims)) {
          for (const [topicId, topicsVerbatim] of Object.entries(topicsVerbatims)) {
            topicsVerbatim['hide'] = true
          }
          resultAllTopicsVerbatimForVerbatims[verbatimId] = { ...topicsVerbatims, ...resultFilteredTopicsVerbatim[verbatimId] }
        }

        return Object.values(resultAllTopicsVerbatimForVerbatims)
      },
      async fetchVerbatimsTotal(baseRequest) {
        let request = baseRequest.select({
          main_topics_verbatims: [
          { 'COUNT_id': { as: 'total' } }
          ]
        })

        const result = (await this.$resolve(request)).first()

        return result.total
      },
      formatVerbatims(verbatims) {
        const formatedVerbatims = _sortBy(
          _uniqBy(verbatims?.map(verbatimByTopicId => {
            const formatedVerbatim = { scores: [] }
            let disabledTopicsForVerbatim = []

            formatedVerbatim.isReclassified = Object.values(verbatimByTopicId).reduce((count, topicVerbatim) => {
              return count + topicVerbatim.topicsVerbatimReclassificationsCount
            }, 0) > 0

            for (const [topicId, topicVerbatim] of Object.entries(verbatimByTopicId)) {
              formatedVerbatim.content = topicVerbatim.content
              formatedVerbatim.voterUniqueId = topicVerbatim.voterUniqueId
              formatedVerbatim.verbatimId = topicVerbatim.verbatimId
              formatedVerbatim.reviewDate = dayjs(topicVerbatim.reviewDate)
              formatedVerbatim.textAnalysisProcessedAt = topicVerbatim.verbatimTextAnalysisProcessedAt ? dayjs(topicVerbatim.verbatimTextAnalysisProcessedAt) : undefined


              let feelingScore = 0
              if (!topicVerbatim.disabled) {
                feelingScore = (topicVerbatim.feeling == 0 ? 1 : topicVerbatim.feeling == 1 ? 0 : -1)
              }
              formatedVerbatim.feeling = (formatedVerbatim.feeling || 0) + feelingScore
              formatedVerbatim.scores.push(feelingScore)
              formatedVerbatim.topics = formatedVerbatim.topics || []
              formatedVerbatim.topicById = formatedVerbatim.topicById || {}

              const topicData = {
                feelingSign: (topicVerbatim.feeling == 0 ? "+" : topicVerbatim.feeling == 1 ? "=" : "-"),
                feeling: topicVerbatim.feeling,
                topicName: topicVerbatim.topicName,
                topicId: topicVerbatim.topicId,
                disabled: topicVerbatim.disabled || false,
                hide: topicVerbatim.hide || false
              }
              formatedVerbatim.topicById[topicData.topicId] = topicData
              formatedVerbatim.topics.push(topicData)
              disabledTopicsForVerbatim.push(topicData.disabled)
            }

            const uniqDisabledTopicsForVerbatim = _uniq(disabledTopicsForVerbatim)
            formatedVerbatim.disabled = uniqDisabledTopicsForVerbatim.length === 1 && uniqDisabledTopicsForVerbatim[0] === true
            disabledTopicsForVerbatim = []

            formatedVerbatim.feeling = (formatedVerbatim.feeling > 0 ? 0 : formatedVerbatim.feeling < 0 ? 2 : 1)

            return formatedVerbatim
          }), row => row.verbatimId)
        , row => row.reviewDate)

        return (this.dateSort === 'desc' ? formatedVerbatims.reverse() : formatedVerbatims)
      },
      async exportFastExcel() {
        this.exportExcel(true)
      },
      async exportExcel(fast = false) {
        if (!this.exportLoading) {
          this.exportLoading = `0%`
          const headers = [ ...[this.$t("components.dateFilter.types.review"), this.$t("feedback"), this.$t("reporting_verbatim")], ...this.topicOptions.map(topic => topic.text) ]
          let formatedVerbatims = this.formatedVerbatims
          const baseRequest = this.baseRequest

          if (fast == false) {
            const total = await this.fetchVerbatimsTotal(baseRequest)
            const shard = 100

            let offset = this.offset
            let allVerbatims = this.allVerbatims
            let lastVerbatimFetched = this.lastVerbatimFetched

            while (lastVerbatimFetched == false) {
              const verbatims = await this.fetchVerbatims(baseRequest, offset + 1, shard)

              if (Object.values(verbatims).length == 0) {
                lastVerbatimFetched = true
              } else {
                allVerbatims = [...allVerbatims,  ...verbatims ]
                offset += shard
              }

              if (!this.exportLoading) {
                return
              } else {
                this.exportLoading = `${allVerbatims.length} / ${total} (${Math.round(allVerbatims.length * 100 / total)}%)`
              }
            }

            formatedVerbatims = this.formatVerbatims(allVerbatims)
          }

          const rows = formatedVerbatims.map(verbatim => {
            return [
              ...[
                verbatim.reviewDate,
                `${this.$appBaseUrl}/dashboard/${this.currentDashboardParams.dashboardType}/${this.currentDashboardParams.dashboardId}/feedbacks/all/all/${verbatim.voterUniqueId}/review`,
                verbatim.content
              ],
              ...this.topicOptions.map(topic => {
                const topicData = verbatim.topicById[topic.value]
                return topicData ? `${topicData.topicName} ${topicData.feelingSign}` : ""
              })
            ]
          })
          exportToExcel(
            `${this.$t("reporting_verbatim")}_${this.zoomedData.name}`.toLowerCase(),
            this.$t("reporting_verbatim"),
            rows,
            headers
          )
          this.exportLoading = null
        }
      }
    },
    watch: {
      search(newVal) {
        if (newVal) {
          this.allVerbatims = null
        }
      }
    }
  }
</script>
