import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      documentScroll_isScrolling: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      if (document.documentElement.scrollTop > 0) {
        this.documentScroll_isScrolling = true

        return
      }

      this.documentScroll_isScrolling = false
    }
  }
}
