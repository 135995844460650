import ApiCall from '@api/apiCall.js'

export async function update(id, params) {
  const response = await new ApiCall().put(`/api/wizville/conversation_pre_filled_messages/${id}`, {
    conversation_pre_filled_message: params
  });

  return response.data
}

export async function exportConversationPreFilledMessages(campaignId){
  const response = await new ApiCall().get(`/api/wizville/conversation_pre_filled_messages/export?campaign_id=${campaignId}`)

  return response.data
}

export async function checkImportFileConversationPreFilledMessages(campaignId, file){
  let formData = new FormData();
  formData.append('file', file);
  const response = await new ApiCall().post(`/api/wizville/conversation_pre_filled_messages/check_file?campaign_id=${campaignId}`, 
    formData
  )
  return response
}
