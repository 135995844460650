import WizClient from "@shared/http/wizClient"

async function sendAchievements(campaignId) {
  const response = await WizClient.post(`campaigns/${campaignId}/send_achievements`)

  return response
}

export {
  sendAchievements
}
