import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, params) {
  const response = await new ApiCall().post("/api/wizville/voter_third_party_transfers", {
    voterUniqueId,
    voter_third_party_transfer: params
  });

  return response.data
}

export async function update(voterThirdPartyTransferUniqueId, params) {
  const response = await new ApiCall().put(`/api/wizville/voter_third_party_transfers/${voterThirdPartyTransferUniqueId}`, {
    voterThirdPartyTransferUniqueId,
    voter_third_party_transfer: params
  });

  return response.data
}
