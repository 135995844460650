import Canvg from 'canvg';

export default async function chartToPng(chart, { width = 800, height = 400, fileName = "export", title, subtitle } = {}) {
  try {
    const titleHeight = title ? 80 : 0;
    const subtitleHeight = subtitle ? 40 : 0;
    const exportWidth = width * 2;
    const exportHeight = height * 2;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let svgData = chart.getSVG({
      chart:{
        width: width,
        height: height
      }
    });

    const v = Canvg.fromString(ctx, svgData, { offsetY: titleHeight + subtitleHeight, ignoreDimensions: true });

    canvas.width = exportWidth;
    canvas.height = exportHeight + titleHeight + subtitleHeight;

    v.resize(exportWidth, exportHeight, 'xMidYMax meet');
    await v.render();

    if (title) {
      ctx.fillStyle = "#fff";
      ctx.fillRect(1, 0, exportWidth - 4 , titleHeight + subtitleHeight + 1);

      ctx.fillStyle = "#212121";
      ctx.font = "24px SourceSansProSemiBold";
      ctx.fillText(title, 10, 50);

      if (subtitle) {
        ctx.fillStyle = "#666";
        ctx.font = "14px SourceSansProSemiBold";
        ctx.fillText(subtitle, 10, 80);
      }
    }

    canvas.toBlob(blob => {
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL.createObjectURL(blob);
      anchor.click();
      URL.revokeObjectURL(anchor.href);
    });

  } catch(e) {
    console.log(e);
  }
}
