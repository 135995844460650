<template>
  <div class="wiz-profiler">
    <WProfilerBadge
      :nbTransactionsRunning="nbTransactionsRunning"
      :nbTransactionsKo="nbTransactionsKo"
      :nbTransactionsOk="nbTransactionsOk"
      @toggle="opened = !opened"
      class="transactions-badge"
    />
    
    <v-bottom-sheet v-model="opened" class="w-bottom-sheet" :fullscreen="transaction && opened">
      <v-sheet class="text-center">
        <WTransaction 
          v-if="transaction && opened"
          :transaction="transaction" 
          :key="`w-transaction-${transaction.transactionId}`"
          :scope="scope"
        />

        <WProfilerSheet
          :transactions="transactions"
          :heartBeat="heartBeat"
          @transaction="(t) => transaction = t"
          @clear="offsetTransactionId = lastTransactionId"
        />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import { transactions } from '@api/transaction.js'
  import WProfilerBadge from './WProfilerBadge'
  import WProfilerSheet from './WProfilerSheet'
  import WTransaction from './WTransaction'

  export default {
    name: 'WProfiler',
    props: {
      scope: { type: String, required: false, default: "user" }
    },
    components: {
      WProfilerBadge,
      WProfilerSheet,
      WTransaction
    },
    data() {
      return {
        heartBeat: 1,
        opened: false,
        transaction: null,
        offsetTransactionId: 0,
        nbExistingPathsLimit: 10
      }
    },
    computed: {
      transactions() {
        const heartBeat = this.heartBeat
        const currentHistoryStateKey = window.history?.state?.key
        const existingPaths = {}
        const filteredTransactions = []
        let nbExistingPaths = 0

        for (let i = transactions.length - 1; i >= 0; i--) {
          const transaction = transactions[i]
          if (transaction.status !== 'init' && transaction.transactionId > this.offsetTransactionId) {

            if (!existingPaths[transaction.historyStateKey]) {
              existingPaths[transaction.historyStateKey] = true
              nbExistingPaths += 1
            }
   
            if (nbExistingPaths > this.nbExistingPathsLimit) {
              break
            } else {
              filteredTransactions.push(transaction)
            }
          }
        }

        return filteredTransactions
      },
      transactionsRunning() {
        return this.transactions.filter((t) => t.status === 'running')
      },
      nbTransactionsRunning() {
        return this.transactionsRunning?.length || 0
      },
      transactionsOk() {
        return this.transactions.filter((t) => t.status === 'ok')
      },
      nbTransactionsOk() {
        return this.transactionsOk?.length || 0
      },
      transactionsKo() {
        return this.transactions.filter((t) => t.status === 'ko')
      },
      nbTransactionsKo() {
        return this.transactionsKo?.length || 0
      },
      lastTransactionId() {
        return Math.max(...this.transactions.map(t => t.transactionId))
      }
    },
    methods: {
      cancelAutoUpdate () {
        clearInterval(this.timer)
      }
    },
    created () {
      this.timer = setInterval(() => { this.heartBeat += 1 }, 100)
    },
    beforeUnmount () {
      this.cancelAutoUpdate()
    }
  }
</script>

<style lang="stylus" scoped>
  .transactions-badge
    position: fixed
    left: 50%
    transform: translateX(-50%)
    bottom: 5px
    z-index: 10000

</style>
