import _chunk from 'lodash/chunk'

async function parralelFor(concurrence, array, callback) {
  const arrayChunks = _chunk(array, concurrence)

  for (let i = 0; i < arrayChunks.length; i++) {
    const result = await Promise.all(arrayChunks[i].map((arrayItem, arrayItemIdx) => callback(arrayItem, i * concurrence + arrayItemIdx, array)))

    if (result.includes(false)) {
      break
    }
  }
}

export {
  parralelFor
}