<template>
  <div class="w-stars-and-reviews">
    <div class="d-flex align-center f-14">
      <v-rating
        class="w-rating d-none d-sm-flex"
        background-color="grey lighten-2"
        readonly
        :value="score ? Math.round(score * 2) / 2 : 0"
        half-increments
        length="5"
        size="20"
        :color="score ? $colors.gold : $colors.grey"
      >
      </v-rating>
      <span class="ml-1 f-13 c-primary" v-if="score">
        {{ score | round2 }}
        <w-color-sign
          class="ml-1"
          :value="scoreEvolution"
          :decimals="2"
        />
      </span>

    </div>
    <div class="d-flex flex-row" v-if="score">
      <span class="ml-1 sub-text f-12 c-lightgrey" v-html="translatedNbReview" />
      <w-color-sign
        class="ml-1"
        :value="nbReviewEvolution"
        :decimals="0"
        color="rgb(138, 138, 138)"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "WStarsAndReviews",
    props: {
      score: { type: Number | null, required: true },
      nbReview: { type: Number | null, required: true },
      scoreEvolution: { type: Number | null, required: false },
      nbReviewEvolution: { type: Number | null, required: false }
    },
    computed: {
      translatedNbReview() {
        return (this.nbReview ? this.$t('nbReview_html', { nbReview: this.nbReview.toLocaleString() }) : 0)
      },
    }
  }
</script>

<style lang="stylus" scoped>
</style>
