<template>
  <v-container class="campaign-translations" fluid>
    <v-row>
      <v-col>
        <WAutocomplete
          v-model="selectedFeatures"
          :items="featureItems"
          multiple
          outlined
        />
      </v-col>
      <v-col>
        <WAutocomplete
          v-if="availableLocales"
          v-model="selectedLocales"
          :items="availableLocales"
          multiple
        />
      </v-col>
    </v-row>
    <Translations
      :campaignId="this.currentCampaignPreferencesCampaignId"
      :features="selectedFeatures"
      :locales="selectedLocales"
      class="mx-0 mt-3"
      :key="selectedFeatures.join('_')"
    />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Translations from './Translations'

  export default {
    name: 'CampaignTranslations',
    components: {
      Translations
    },
    props: {
      feature: { type: String, default: 'survey' },
    },
    data() {
      return {
        selectedFeatures: [this.feature],
        selectedLocales: [],
      }
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferences',
        'currentCampaignPreferencesCampaignId',
      ]),
      featureItems() {
        const featureItems = [
          { text: 'Questionnaire', value: 'survey' },
          { text: 'Réponses types', value: 'conversation_pre_filled_message' }
        ]
        if (this.currentCampaignPreferences?.textAnalysisPreferenceEnabled) {
          featureItems.push({ text: 'Plan de classement', value: 'text_analysis' })
        }
        return featureItems
      }
    },
    asyncComputed: {
      availableLocales: {
        default: [],
        watch: ['selectedFeatures'],
        async get() {
          let request = this.$adminBasedRequest()
                            .select({ campaigns: ['available_locales'] })
                            .where({ id: this.currentCampaignPreferencesCampaignId })
          const locales = new Set((await this.$resolve(request)).first().availableLocales)

          if (this.selectedFeatures.includes('text_analysis')) {
            request = this.$adminBasedRequest()
                          .select({ users: ['DISTINCT_locale'] })
                          .where({ place_campaigns_campaign_id: this.currentCampaignPreferencesCampaignId })
            const response = await this.$resolve(request)
            response.data.users.forEach(data => locales.add(data.distinctLocale))
          }

          return [...locales].sort().map(locale => ({ text: locale, value: locale }))
        }
      }
    },
    watch: {
      availableLocales(value) {
        const masterLocale = this.currentCampaignPreferences.locale
        const locales = value.map(item => item.value).filter(locale => locale != masterLocale)
        this.selectedLocales = [masterLocale]
        if (locales[0]) {
          this.selectedLocales.push(locales[0])
        }
      }
    }
  }
</script>
