<template>
  <router-link
    class="w-stats-number"
    :is="linkTo ? 'router-link' : 'div'"
    :to="linkTo"
  >
    <div v-if="isLoading" class="skeleton">
      <v-skeleton-loader type="paragraph" class="pt-6 skeleton" />
    </div>
    <v-expansion-panels
      v-else
      :readonly="!dropdown"
      :value="dropdownOpen ? 0 : 1"
    >
      <v-expansion-panel>
        <v-expansion-panel-header :hide-actions="!dropdown" class="px-4">
          <div class="d-flex align-center c-primary">
            <div :class="`${numberFontSize} number text-left mr-5`">
              <slot name="number">
                <span>
                  {{ numberDisplay }}
                </span>
              </slot>
            </div>
            <div class="f-14 w-stats-widget-number-title">
              <slot name="title">
                <span class="text-left">{{title}}</span>
              </slot>
            </div>
            <div class="mx-5 flex-grow-1 text-right">
              <slot name="emoji">
                <span class="f-20">{{emoji}}</span>
              </slot>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content v-if="dropdown" class="fs c-primary f-14">
          <slot name="dropdown-content">
            <span class="c-primary">{{dropdownContent}}</span>
          </slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </router-link>
</template>

<script>
export default {
  name: 'WStatsNumber',
  props: {
    dropdown: { type: Boolean, required: false, default: false },
    dropdownOpen: { type: Boolean, required: false, default: false},
    dropdownContent: { type: String, required: false },
    title: { type: String, required: false, default: false },
    number: { type: Number, required: false, default: 0 },
    isLoading: { type: Boolean, required: false, default: false },
    emoji: { type: String, required: false },
    linkTo: { type: Object, required: false}
  },
  computed: {
    numberFontSize() {
      const numberLength = this.number?.toString()?.length
      const sizeFonts = { 1: 36, 2: 36, 3: 30, 4: 30, 5: 25, 6: 20, 7: 20, 8: 20 }

      return `fs-${sizeFonts[numberLength]}`
    },
    numberDisplay() {
      return this.number == null ? "_" : this.number
    }
  }
}
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'

  widget-height = 100px

  .w-stats-number
    .v-expansion-panels
      .v-expansion-panel::before
        bottom: unset

      .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon
        font-size: 35px;
        color: var(--primary);

      .v-expansion-panel-header
        min-height: widget-height

      .v-expansion-panel-content
        .v-expansion-panel-content__wrap
          padding: 0

    .skeleton
      height: widget-height
</style>


