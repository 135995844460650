<template>
  <span
    v-if="copied"
    class="d-flex align-center"
  >
    <slot name="copiedContent">
      <v-icon
        small
        class="c-primary"
      >
        mdi-check
      </v-icon>
    </slot>
  </span>
  <span
    v-else
    v-clipboard:copy="value"
    v-clipboard:success="onCopy"
    class="d-flex align-center"
  >
    <slot name="copyContent">
      <v-icon small
        class="c-primary"
      >
        mdi-content-copy
      </v-icon>
    </slot>
  </span>
</template>

<script>
  export default {
    name: "WCopyButton",
    props: {
      value: { required: true },
    },
    data() {
      return {
        copied: false
      }
    },
    methods: {
      onCopy(e) {
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, '2000');
      }
    }
  }
</script>

<style lang='stylus'>
</style>
