import ApiCall from '@api/apiCall.js'

async function sendPushMobileInsatisfactionTest(voterUniqueId) {
  const response = await new ApiCall().post(
    '/api/wizville/test/send_push_mobile_insatisfaction_test',
    { voterUniqueId }
  )

  return response
}

async function sendPushMobileNewMessageTest(voterUniqueId) {
  const response = await new ApiCall().post(
    '/api/wizville/test/send_push_mobile_new_message_test',
    { voterUniqueId }
  )

  return response
}

export {
  sendPushMobileInsatisfactionTest,
  sendPushMobileNewMessageTest
}