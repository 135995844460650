import WizClient from '@shared/http/wizClient'

async function localisation() {
  const response = await WizClient.get('/api/local_monitor/ip_tools/localisation')

  return response.data
}

export {
  localisation,
}
