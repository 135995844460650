<template>
  <div class="w-infinite-scroll">
    <div v-if="loading">
      <v-progress-linear indeterminate color="blue darken-2"></v-progress-linear>
    </div>
    <div class="d-flex flex-row w-infinite-scroll-headers align-center text-uppercase fs-11 c-lightgrey" v-if="!loading && headers && headers.length > 0">
      <div v-for="(header, index) in headers" class="w-infinite-scroll-header" :class="rowClassByName[header.name]" :style="rowStyleByName[header.name]">
        <slot :name="`header.${header.name}`" :header="header">
          {{ header.title }}
        </slot>
      </div>
    </div>

    <v-virtual-scroll
      v-if="!loading && rows && rows.length > 0"
      class="scroll-section"
      :height="(fullScreenMode ? (rows?.length || 0) * itemHeight : height)"
      :item-height="itemHeight"
      :items="rows"
      :bench="bench"
    >
      <template v-slot:default="{ item, index }">
        <div class="d-flex flex-row align-center f-12 w-infinite-scroll-rows py-2" :style="{ height: `${itemHeight}px`, boxSizing: 'border-box' }">
          <div v-for="(header, index) in headers" class="w-infinite-scroll-row" :class="rowClassByName[header.name]" :style="rowStyleByName[header.name]">
            <slot :name="`item.${header.name}`" :item="item" :header="header" :value="item[header.name]">
              {{ item[header.name] }}
            </slot>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
export default {
  name: "WInfiniteScroll",
  props: {
    height: {
      type: String,
      required: false,
      default: "300"
    },
    itemHeight: {
      type: String,
      required: false,
      default: "60"
    },
    headers: {
      required: false
    },
    rows: {
      required: false
    },
    fullScreenMode: {
      required: false,
      default: false
    },
    bench: {
      type: Number,
      required: false,
      default: 100
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    total() {
      return this.headers.length
    },
    rowClassByName() {
      const rowClassByName = {}

      this.headers.forEach((header, index) => {
        rowClassByName[header.name] = this.rowClass(index, header, this.total)
      })

      return rowClassByName
    },
    rowStyleByName() {
      const rowStyleByName = {}

      this.headers.forEach((header, index) => {
        rowStyleByName[header.name] = {
          width: (header.width ? header.width : 'auto'),
          maxWidth: (header.maxWidth ? header.maxWidth : 'auto'),
          minWidth: (header.minWidth ? header.minWidth : 'auto'),
          maxHeight: `${this.itemHeight - 16}px`,
          overflowY: header.overflowY || 'auto'
        }
      })

      return rowStyleByName
    }
  },
  methods: {
    rowClass(index, header, total) {
      const classes = header.class || []

      if (index < total - 1) {
        if (index == 0) {
          classes.push('pl-1')
        }
        classes.push('pr-3')
      } else {
        classes.push('pr-1')
      }

      return classes
    }
  }
}
</script>

<style lang="stylus">
  .w-infinite-scroll
    .v-virtual-scroll::-webkit-scrollbar
      display: none

    .v-virtual-scroll
      -ms-overflow-style: none;
      scrollbar-width: none;

    .w-infinite-scroll-rows, .w-infinite-scroll-headers
      border-bottom: thin solid rgba(0,0,0,.12)
      height: 40px
      
      .w-infinite-scroll-row
        -ms-overflow-style: none;
        scrollbar-width: none;
        
      .w-infinite-scroll-row::-webkit-scrollbar
        display: none;

    .scroll-section
      background:
        linear-gradient(white 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
      background-attachment: local, local, scroll, scroll;
</style>
