import ApiCall from '@api/apiCall.js'

export async function create(campaignId, dateBegin, dateEnd, limit, strategy) {
  const response = await new ApiCall().post(
    `/api/wizville/verbatim_annotation_batchs`,
    { campaignId, dateBegin, dateEnd, limit, strategy }
  )

  return response.data
}

export async function cancel(verbatimAnnotationBatchId) {
  const response = await new ApiCall().post(
    `/api/wizville/verbatim_annotation_batchs/${verbatimAnnotationBatchId}/cancel`
  )

  return response.data
}

export async function exportAnnotation(verbatimAnnotationBatchId) {
  const response = await new ApiCall().post(
    `/api/wizville/verbatim_annotation_batchs/${verbatimAnnotationBatchId}/export_annotation`
  )

  return response.data
}