import html2canvas from 'html2canvas'

export default function htmlToBase64(element, html2CanvasOptions) {
  return new Promise((resolve, reject) => {
    html2canvas(element, html2CanvasOptions).then(canvas => {
      const dataURL = canvas.toDataURL('image/png');
      const width = canvas.width;
      const height = canvas.height;
      canvas = null;
      resolve([dataURL, width, height]);
    });
  });
}
