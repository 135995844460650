<template>
  <v-menu
    bottom
    transition="slide-y-transition"
    offset-y
    @update:return-value="open = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="w-menu-select d-flex justify-space-between align-center flex-shrink-0"
        @click="open = !open"
        v-bind="attrs"
        v-on="on"
      >
        <slot name="selected-text" :item="selectedItem">
          <span>
            <template v-if="label">{{ label }}</template>
            <strong>{{ selectedItem.text }}</strong>
          </span>
        </slot>
        <v-icon class="f-18" v-if="open">mdi-chevron-up</v-icon>
        <v-icon class="f-18" v-else>mdi-chevron-down</v-icon>
      </div>
    </template>
    <v-list>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <v-list-item-group v-model="selectedIndex">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :disabled="i === selectedIndex"
        >
          <v-list-item-title dense>
            <slot name="option-text" :item="item">
              {{ item.text }}
            </slot>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      selectedIndex: this.value || this.allowEmpty ? null : 0,
      selectedItem: this.value ? this.value : (this.items[0] || {})
    }
  },
  computed: {},
  methods: {
    selectItem() {
      this.selectedItem = this.items[this.selectedIndex] || {}
      this.$emit("input", this.selectedItem)
    }
  },
  watch: {
    selectedIndex: {
      handler: "selectItem",
    },
    open() {
      this.$emit(this.open ? "open" : "close")
    },
    items(newItems) {
      if (!newItems.find((item) => item.value === this.selectedItem.value)) {
        this.selectedIndex = 0,
        this.selectedItem = this.items[0] || {}
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.w-menu-select
  cursor: pointer
  padding-left: 5px
  padding-right: 5px
  min-height: 45px!important
.v-menu__content
  border-radius: 0!important
.v-list
  padding: 0!important
</style>
