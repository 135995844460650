import deepmerge from '@shared/helpers/deepmerge';
import BasedResolver from "./basedResolver"

class BasedQuery {
  constructor(options, query = {
    "select": {},
    "where": {},
    "group": [],
    "scoped": [],
    "order": null,
    "limit": null,
    "offset": null,
    "having": null,
  }) {
    this.query = query
    this.options = options || { scope: "user" }
  }

  async resolve(queryName, options = {}, endPoint = 'hash') {
    try {
      if (queryName) {
        return new BasedResolver(this, queryName, endPoint, options).resolve()
      } else {
        throw "missing queryName : if you are using $resolve you should set component name"
      }
    } catch (e) {
      console.trace();
    }
  }

  //
  // { "voters": { "id": { as: "customId" } } }
  // { "voters": [ "id", "score" ] }
  //
  select(columns) {
    const request = this.clone()
    request.query.select = deepmerge(this.query.select, columns)
    return request
  }

  //
  // { "voters": { "id": { as: "customId" } } }
  // { "voters": [ "id", "score" ] }
  //
  reselect(columns) {
    const request = this.clone()
    request.query.select = columns
    return request
  }

  //
  // { "id": { ">": [ 12 ] } }
  // { "score": 10 }
  //
  where(filters) {
    const request = this.clone()
    request.query.where = deepmerge(this.query.where, filters)
    return request
  }

  //
  // "created_at"
  //
  defaultDateField(dateField) {
    const request = this.clone()
    request.dateField = dateField
    request.options.dateField = dateField
    return request
  }

  //
  // "10/12/2020", "28/04/2021"
  //
  dateBetween(dateBegin, dateEnd) {
    return this.where({
      [this.options.dateField || "created_at"]: {
        "<=>": {
          min: dateBegin,
          max: dateEnd
        }
      }
    })
  }

  //
  // ["id", "score"]
  //
  group(segments) {
    const request = this.clone()
    request.query.group = this.query.group.concat(segments)
    return request
  }

  //
  // ["id", "score"]
  //
  regroup(segments) {
    const request = this.clone()
    request.query.group = segments
    return request
  }

  //
  // 42
  //
  limit(limit_clause) {
    const request = this.clone()
    request.query.limit = limit_clause
    return request
  }

  //
  // 43
  //
  offset(offset_clause) {
    const request = this.clone()
    request.query.offset = offset_clause
    return request
  }

  //
  // [ 'scope_1' ]
  //
  scoped(scope_clauses) {
    const request = this.clone()
    request.query.scoped = request.query.scoped.concat(scope_clauses)
    return request
  }

  //
  // [ "id", "desc" ]
  //
  order(order_clause) {
    const request = this.clone()
    request.query.order = order_clause
    return request
  }

  //
  // { "COUNT_id": 2 }
  //
  having(having_clause) {
    const request = this.clone()
    request.query.having = having_clause
    return request
  }

  //
  // test@test.fr
  //
  as(user) {
    const request = this.clone()
    request.options.user = user
    request.user = user
    return request
  }

  getQuery() {
    return (this.query)
  }

  clone() {
    return new BasedQuery(this.options, JSON.parse(JSON.stringify(this.query)))
  }
}

export default BasedQuery