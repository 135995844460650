import DayJs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const advancedFormat = require('dayjs/plugin/advancedFormat')
const minMax = require('dayjs/plugin/minMax')
const isoWeek = require('dayjs/plugin/isoWeek')
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const localizedFormat = require('dayjs/plugin/localizedFormat')

DayJs.extend(minMax)
DayJs.extend(customParseFormat)
DayJs.extend(isSameOrBefore)
DayJs.extend(isSameOrAfter)
DayJs.extend(advancedFormat)
DayJs.extend(quarterOfYear)
DayJs.extend(isoWeek)
DayJs.extend(quarterOfYear)
DayJs.extend(relativeTime)
DayJs.extend(utc)
DayJs.extend(timezone)
DayJs.extend(localizedFormat)

require('dayjs/locale/ar')
require('dayjs/locale/de')
require('../dayjs/locale/el')
require('../dayjs/locale/en')
require('../dayjs/locale/es')
require('../dayjs/locale/fr')
require('../dayjs/locale/it')
require('../dayjs/locale/nl')
require('dayjs/locale/pl')
require('../dayjs/locale/pt')
require('../dayjs/locale/ro')
require('dayjs/locale/sk')

import { i18n } from '@i18n/setup'

const translationPath = 'components.dashboardAnswer.feedbackItem.footer.date'
let currentTz = localStorage.currentTz || DayJs.tz.guess() || "Europe/Paris"

function dayjs(date = null, options = {}) {
  const format = options.format
  const tz = options.tz

  if (!tz) {
    if (date && format) {
      return DayJs(date, format).tz(currentTz)
    } else if (date) {
      return DayJs(date).tz(currentTz)
    }
  } else {
    if (date && format) {
      return DayJs.tz(DayJs(date, format), currentTz)
    } else if (date) {
      return DayJs.tz(date, currentTz)
    }
  }

  return DayJs().tz(currentTz)
}

function compare(dateA, dateB) {
  if (dateA && dateB) {
    const date1 = dayjs(dateA)
    const date2 = dayjs(dateB)

    if (date1 > date2) {
      return 1
    } else if (date1 < date2) {
      return -1
    } else if (date1 == date2) {
      return 0
    }
  }

  return null
}

function nCompletedMonthAgoSql(n) {
  return sqlDate(dayjs().subtract(n, 'month').startOf('M').format("YYYY-MM-DD 00:00:00"))
}

function nMonthAgoSql(n) {
  return dayjs().subtract(n, 'month').format("YYYY-MM-DD 00:00:00")
}

function sqlDateStartOfMonth(date, offsetMonth) {
  if (offsetMonth > 0) {
    return sqlDate(dayjs(date).add(offsetMonth, 'month').startOf('M').format("YYYY-MM-DD 00:00:00"))
  } else if (offsetMonth < 0) {
    return sqlDate(dayjs(date).subtract(offsetMonth, 'month').startOf('M').format("YYYY-MM-DD 00:00:00"))
  } else {
    return sqlDate(dayjs(date).startOf('M').format("YYYY-MM-DD 00:00:00"))
  }
}

function sqlDateEndOfMonth(date, offsetMonth) {
  if (offsetMonth > 0) {
    return sqlDate(dayjs(date).add(offsetMonth, 'month').endOf('M').format("YYYY-MM-DD 00:00:00"), '23:59:59')
  } else if (offsetMonth < 0) {
    return sqlDate(dayjs(date).subtract(offsetMonth, 'month').endOf('M').format("YYYY-MM-DD 00:00:00"), '23:59:59')
  } else {
    return sqlDate(dayjs(date).endOf('M').format("YYYY-MM-DD 00:00:00"), '23:59:59')
  }
}

function complete(date, withoutSuffix = true) {
  return dayjs(date).locale(i18n.locale).fromNow(withoutSuffix)
}

function ddmmyyyy(date) {
  return dayjs(date).locale(i18n.locale).format("DD/MM/YYYY")
}

function short(date, with_time) {
  const format = with_time ? i18n.t(`${translationPath}.complete`) : i18n.t(`${translationPath}.without_time`)
  return dayjs(date).locale(i18n.locale).format(format)
}

function shortMonthAndYear(date) {
  return dayjs(date).locale(i18n.locale).format("MMM YYYY").replace('.', '')
}

function longMonthAndYear(date) {
  return dayjs(date).locale(i18n.locale).format("MMMM YYYY")
}

function day(date) {
  return dayjs(date).locale(i18n.locale).format("DD")
}

function week(date) {
  const w = dayjs(date).locale(i18n.locale).isoWeek()
  return `${i18n.t('week_abbr')} ${w}`
}

function month(date, offsetMonth = 0) {
  if (offsetMonth > 0) {
    return dayjs(date).add(offsetMonth, 'month').locale(i18n.locale).format("MMMM YYYY")
  } else if (offsetMonth < 0) {
    return dayjs(date).subtract(offsetMonth, 'month').locale(i18n.locale).format("MMMM YYYY")
  } else {
    return dayjs(date).locale(i18n.locale).format("MMMM YYYY")
  }
}

function quarterYear(date) {
  const q = dayjs(date).locale(i18n.locale).quarter()
  return `${i18n.t('quarter_abbr')}${q} ${year(date)}`
}

function quarterPos(date) {
  const q = dayjs(date).locale(i18n.locale).quarter()
  return -parseInt(`${year(date)}${q}`)
}

function quarterPosHuman(qpos) {
  const str = String(qpos < 0 ? -qpos : qpos)
  return `${i18n.t('quarter_abbr')}${str.slice(4)} ${str.slice(0, 4)}`
}

function year(date) {
  return dayjs(date).locale(i18n.locale).format("YYYY")
}

function hasTime(date) {
  return dayjs(date).format("HH:mm:ss") !== '00:00:00'
}

function current() {
  const currentdate = new Date();
  const datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + "_"
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();

  return datetime
}

function sqlDate(date, time = "00:00:00") {
  if (date) {
    let formatedDate = undefined

    if (date.match(/\d\d\d\d\/\d\d\/\d\d/)) {
      formatedDate = DayJs(date, 'YYYY/MM/DD').format(`YYYY-MM-DD ${time}`)
    } else if (date.match(/\d\d\/\d\d\/\d\d\d\d/)) {
      formatedDate = DayJs(date, 'DD/MM/YYYY').format(`YYYY-MM-DD ${time}`)
    } else if (date.match(/\d\d\d\d\-\d\d\-\d\d/)) {
      formatedDate = DayJs(date, 'YYYY-MM-DD').format(`YYYY-MM-DD ${time}`)
    } else {
      formatedDate = DayJs(date).format(`YYYY-MM-DD ${time}`)
    }

    return dayjs(formatedDate, { tz: currentTz }).utc().format()
  }

  return null
}

function filename(name, ext) {
  return `${name}-${(new Date().toJSON().slice(0,10))}${ext}`
}

const TIME_ZONES = {
  "International Date Line West": { "long": "Pacific/Midway" },
                 "Midway Island": { "long": "Pacific/Midway" },
                "American Samoa": { "long": "Pacific/Pago_Pago" },
                        "Hawaii": { "long": "Pacific/Honolulu" },
                        "Alaska": { "long": "America/Juneau" },
    "Pacific Time (US & Canada)": { "long": "America/Los_Angeles" },
                       "Tijuana": { "long": "America/Tijuana" },
   "Mountain Time (US & Canada)": { "long": "America/Denver" },
                       "Arizona": { "long": "America/Phoenix" },
                     "Chihuahua": { "long": "America/Chihuahua" },
                      "Mazatlan": { "long": "America/Mazatlan" },
    "Central Time (US & Canada)": { "long": "America/Chicago" },
                  "Saskatchewan": { "long": "America/Regina" },
                   "Guadalajara": { "long": "America/Mexico_City" },
                   "Mexico City": { "long": "America/Mexico_City" },
                     "Monterrey": { "long": "America/Monterrey" },
               "Central America": { "long": "America/Guatemala" },
    "Eastern Time (US & Canada)": { "long": "America/New_York" },
                "Indiana (East)": { "long": "America/Indiana/Indianapolis" },
                        "Bogota": { "long": "America/Bogota" },
                          "Lima": { "long": "America/Lima" },
                         "Quito": { "long": "America/Lima" },
        "Atlantic Time (Canada)": { "long": "America/Halifax" },
                       "Caracas": { "long": "America/Caracas" },
                        "La Paz": { "long": "America/La_Paz" },
                      "Santiago": { "long": "America/Santiago" },
                  "Newfoundland": { "long": "America/St_Johns" },
                      "Brasilia": { "long": "America/Sao_Paulo" },
                  "Buenos Aires": { "long": "America/Argentina/Buenos_Aires" },
                    "Georgetown": { "long": "America/Guyana" },
                     "Greenland": { "long": "America/Godthab" },
                  "Mid-Atlantic": { "long": "Atlantic/South_Georgia" },
                        "Azores": { "long": "Atlantic/Azores" },
                "Cape Verde Is.": { "long": "Atlantic/Cape_Verde" },
                        "Dublin": { "long": "Europe/Dublin" },
                     "Edinburgh": { "long": "Europe/London" },
                        "Lisbon": { "long": "Europe/Lisbon" },
                        "London": { "long": "Europe/London" },
                    "Casablanca": { "long": "Africa/Casablanca" },
                      "Monrovia": { "long": "Africa/Monrovia" },
                           "UTC": { "long": "Etc/UTC" },
                      "Belgrade": { "long": "Europe/Belgrade" },
                    "Bratislava": { "long": "Europe/Bratislava" },
                      "Budapest": { "long": "Europe/Budapest" },
                     "Ljubljana": { "long": "Europe/Ljubljana" },
                        "Prague": { "long": "Europe/Prague" },
                      "Sarajevo": { "long": "Europe/Sarajevo" },
                        "Skopje": { "long": "Europe/Skopje" },
                        "Warsaw": { "long": "Europe/Warsaw" },
                        "Zagreb": { "long": "Europe/Zagreb" },
                      "Brussels": { "long": "Europe/Brussels" },
                    "Copenhagen": { "long": "Europe/Copenhagen" },
                        "Madrid": { "long": "Europe/Madrid" },
                         "Paris": { "long": "Europe/Paris" },
                     "Amsterdam": { "long": "Europe/Amsterdam" },
                        "Berlin": { "long": "Europe/Berlin" },
                          "Bern": { "long": "Europe/Berlin" },
                          "Rome": { "long": "Europe/Rome" },
                     "Stockholm": { "long": "Europe/Stockholm" },
                        "Vienna": { "long": "Europe/Vienna" },
           "West Central Africa": { "long": "Africa/Algiers" },
                     "Bucharest": { "long": "Europe/Bucharest" },
                         "Cairo": { "long": "Africa/Cairo" },
                      "Helsinki": { "long": "Europe/Helsinki" },
                          "Kyiv": { "long": "Europe/Kiev" },
                          "Riga": { "long": "Europe/Riga" },
                         "Sofia": { "long": "Europe/Sofia" },
                       "Tallinn": { "long": "Europe/Tallinn" },
                       "Vilnius": { "long": "Europe/Vilnius" },
                        "Athens": { "long": "Europe/Athens" },
                      "Istanbul": { "long": "Europe/Istanbul" },
                         "Minsk": { "long": "Europe/Minsk" },
                     "Jerusalem": { "long": "Asia/Jerusalem" },
                        "Harare": { "long": "Africa/Harare" },
                      "Pretoria": { "long": "Africa/Johannesburg" },
                        "Moscow": { "long": "Europe/Moscow" },
                "St. Petersburg": { "long": "Europe/Moscow" },
                     "Volgograd": { "long": "Europe/Moscow" },
                        "Kuwait": { "long": "Asia/Kuwait" },
                        "Riyadh": { "long": "Asia/Riyadh" },
                       "Nairobi": { "long": "Africa/Nairobi" },
                       "Baghdad": { "long": "Asia/Baghdad" },
                        "Tehran": { "long": "Asia/Tehran" },
                     "Abu Dhabi": { "long": "Asia/Muscat" },
                        "Muscat": { "long": "Asia/Muscat" },
                          "Baku": { "long": "Asia/Baku" },
                       "Tbilisi": { "long": "Asia/Tbilisi" },
                       "Yerevan": { "long": "Asia/Yerevan" },
                         "Kabul": { "long": "Asia/Kabul" },
                  "Ekaterinburg": { "long": "Asia/Yekaterinburg" },
                     "Islamabad": { "long": "Asia/Karachi" },
                       "Karachi": { "long": "Asia/Karachi" },
                      "Tashkent": { "long": "Asia/Tashkent" },
                       "Chennai": { "long": "Asia/Kolkata" },
                       "Kolkata": { "long": "Asia/Kolkata" },
                        "Mumbai": { "long": "Asia/Kolkata" },
                     "New Delhi": { "long": "Asia/Kolkata" },
                     "Kathmandu": { "long": "Asia/Kathmandu" },
                        "Astana": { "long": "Asia/Dhaka" },
                         "Dhaka": { "long": "Asia/Dhaka" },
           "Sri Jayawardenepura": { "long": "Asia/Colombo" },
                        "Almaty": { "long": "Asia/Almaty" },
                   "Novosibirsk": { "long": "Asia/Novosibirsk" },
                       "Rangoon": { "long": "Asia/Rangoon" },
                       "Bangkok": { "long": "Asia/Bangkok" },
                         "Hanoi": { "long": "Asia/Bangkok" },
                       "Jakarta": { "long": "Asia/Jakarta" },
                   "Krasnoyarsk": { "long": "Asia/Krasnoyarsk" },
                       "Beijing": { "long": "Asia/Shanghai" },
                     "Chongqing": { "long": "Asia/Chongqing" },
                     "Hong Kong": { "long": "Asia/Hong_Kong" },
                        "Urumqi": { "long": "Asia/Urumqi" },
                  "Kuala Lumpur": { "long": "Asia/Kuala_Lumpur" },
                     "Singapore": { "long": "Asia/Singapore" },
                        "Taipei": { "long": "Asia/Taipei" },
                         "Perth": { "long": "Australia/Perth" },
                       "Irkutsk": { "long": "Asia/Irkutsk" },
                  "Ulaan Bataar": { "long": "Asia/Ulaanbaatar" },
                         "Seoul": { "long": "Asia/Seoul" },
                         "Osaka": { "long": "Asia/Tokyo" },
                       "Sapporo": { "long": "Asia/Tokyo" },
                         "Tokyo": { "long": "Asia/Tokyo" },
                       "Yakutsk": { "long": "Asia/Yakutsk" },
                        "Darwin": { "long": "Australia/Darwin" },
                      "Adelaide": { "long": "Australia/Adelaide" },
                      "Canberra": { "long": "Australia/Melbourne" },
                     "Melbourne": { "long": "Australia/Melbourne" },
                        "Sydney": { "long": "Australia/Sydney" },
                      "Brisbane": { "long": "Australia/Brisbane" },
                        "Hobart": { "long": "Australia/Hobart" },
                   "Vladivostok": { "long": "Asia/Vladivostok" },
                          "Guam": { "long": "Pacific/Guam" },
                  "Port Moresby": { "long": "Pacific/Port_Moresby" },
                       "Magadan": { "long": "Asia/Magadan" },
                   "Solomon Is.": { "long": "Asia/Magadan" },
                 "New Caledonia": { "long": "Pacific/Noumea" },
                          "Fiji": { "long": "Pacific/Fiji" },
                     "Kamchatka": { "long": "Asia/Kamchatka" },
                  "Marshall Is.": { "long": "Pacific/Majuro" },
                      "Auckland": { "long": "Pacific/Auckland" },
                    "Wellington": { "long": "Pacific/Auckland" },
                    "Nuku'alofa": { "long": "Pacific/Tongatapu" },
                   "Tokelau Is.": { "long": "Pacific/Fakaofo" },
                         "Samoa": { "long": "Pacific/Apia" }
}

function setTimeZone(tz) {
  currentTz = TIME_ZONES[tz]?.long || DayJs.tz.guess() || "Europe/Paris"
  localStorage.currentTz = currentTz
  DayJs.tz.setDefault(currentTz)
}

export {
  TIME_ZONES,
  setTimeZone,
  nCompletedMonthAgoSql,
  nMonthAgoSql,
  complete,
  short,
  hasTime,
  current,
  shortMonthAndYear,
  longMonthAndYear,
  day,
  week,
  month,
  quarterYear,
  quarterPos,
  quarterPosHuman,
  year,
  sqlDate,
  sqlDateStartOfMonth,
  sqlDateEndOfMonth,
  dayjs,
  ddmmyyyy,
  compare,
  filename
}
