export function displayBorders(pdf, borders, color, posX, posY, width, height) {
  setColor(pdf, color || pdf.defaultTextColor, 'fill');
  setColor(pdf, color || pdf.defaultTextColor, 'draw');
  let bordersToDisplay = borders;
  if (typeof(borders) === 'string') {
    bordersToDisplay = borders === 'all' ? ['left', 'top', 'right', 'bottom'] : [borders];
  }
  bordersToDisplay.map((border) => {
    switch(border) {
      case 'top':
        pdf.doc.line(posX, posY, posX + width, posY, 'DF');
        break;
      case 'bottom':
        pdf.doc.line(posX, posY + height, posX + width, posY + height, 'DF');
        break;
      case 'left':
        pdf.doc.line(posX, posY, posX, posY + height, 'DF');
        break;
      case 'right':
        pdf.doc.line(posX + width, posY, posX + width, posY + height, 'DF');
        break;
    }
  });
}

export function displayBackground(pdf, color, posX, posY, width, height) {
  if (width && height) {
    setColor(pdf, color || pdf.defaultBackgroundColor, 'fill');
    pdf.doc.rect(posX, posY, width, height, 'F');
  }
}

export function setColor(pdf, color, type) {
  const ch1 = typeof(color) !== 'object' ? color : color.ch1;
  switch(type) {
    case 'text':
      pdf.doc.setTextColor(ch1, color.ch2, color.ch3, color.ch4)
      break;
    case 'fill':
      pdf.doc.setFillColor(ch1, color.ch2, color.ch3, color.ch4);
      break;
    case 'draw':
      pdf.doc.setDrawColor(ch1, color.ch2, color.ch3, color.ch4);
    break;
  }
}

export function setFont(pdf, font) {
  pdf.doc.setFont(font.family || pdf.defaultFont.family, font.style);
}

export function reduceText(pdf, text, maxWidth, overflow) {
  const splittedText = maxWidth ? pdf.doc.splitTextToSize(text, maxWidth) : [text];

  if (splittedText.length === 1 || overflow === 'linebreak') {
    return splittedText;
  }
  return `${splittedText[0].slice(0, -2)}...`;
}

export function applyTextStyle(pdf, color, font, fontSize) {
  setColor(pdf, color || pdf.defaultTextColor, 'text');
  setFont(pdf, font || pdf.defaultFont);
  pdf.doc.setFontSize(fontSize || pdf.defaultFontSize);
}

export function computeCursorXFromAlign(align, cursorX, parentWidth, elementWidth = 0) {
  if (!parentWidth) {
    return cursorX;
  }
  switch(align) {
    case 'left':
      return cursorX;
    case 'center':
      return cursorX + parentWidth/2 - elementWidth/2;
    case 'right':
      return cursorX + parentWidth - elementWidth;
  }
}

export function computeCursorYFromBaseline(baseline, cursorY, parentHeight, elementHeight) {
  if (!parentHeight) {
    return cursorY;
  }
  switch(baseline) {
    case 'top':
      return cursorY;
    case 'middle':
      return cursorY + parentHeight/2 - elementHeight/2;
    case 'bottom':
      return cursorY + parentHeight - elementHeight;
  }
}


export function computeShapeCursorXFromAlign(align, cursorX, parentWidth, elementWidth = 0) {
  if (!parentWidth) {
    return cursorX;
  }
  switch(align) {
    case 'left':
      return cursorX + elementWidth/2;
    case 'center':
      return cursorX + parentWidth/2;
    case 'right':
      return cursorX + parentWidth - elementWidth/2;
  }
}

export function computeShapeCursorYFromBaseline(baseline, cursorY, parentHeight, elementHeight) {
  if (!parentHeight) {
    return cursorY + elementHeight/2;
  }
  switch(baseline) {
    case 'top':
      return cursorY + elementHeight/2;
    case 'middle':
      return cursorY + parentHeight/2;
    case 'bottom':
      return cursorY + parentHeight - elementHeight/2;
  }
}

export function computeImageDimensions(desiredWidth, desiredHeight, parentWidth, parentHeight, imageWidth, imageHeight) {
  const width = clamp(desiredWidth, parentWidth);
  const height = clamp(desiredHeight, parentHeight);

  if (desiredWidth && desiredHeight) {
    return [width, height];
  } else if(desiredWidth) {
    return [width, clamp(width * imageHeight/imageWidth, parentHeight)]
  } else if(desiredHeight) {
    return [clamp(height * imageWidth/imageHeight, parentWidth), desiredHeight]
  } else if (parentWidth && parentHeight) {
    return [parentWidth, parentHeight];
  } else if(parentWidth) {
    return [parentWidth, parentWidth * imageHeight/imageWidth];
  } else if(parentHeight) {
    return [parentHeight * imageWidth/imageHeight, parentHeight];
  } else {
    return [imageWidth, imageHeight];
  }
}

export function clamp(value, max) {
  if(!max) {
    return value;
  }
  return value < max ? value : max;
}
