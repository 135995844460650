<template>
  <div class="w-repartition-vertical-frieze">
    <div class="ver-item-score">
    <slot name="item-score" :item="itemScore">
        {{itemScore}}
    </slot>
    </div>

    <div class="vertical-rankings" >
      <v-tooltip top v-for="(part, idx) in [{number: nbPromoters, type: 'promoters'}, {number: nbNeutrals, type: 'neutrals'}, {number: nbDetractors, type: 'detractors'}]" :key="idx">
        <template #activator="{ on }">
          <div v-on="on" class="vertical-ranking" :style="`background:${colors[idx]}; height: ${percentage(part.number)}%`"></div>
        </template>
        <div v-html="litteralValue(percentage(part.number), part.type)" />
      </v-tooltip>
    </div>

    <div class="ver-item-name">
        <slot name="item-name" :item="itemName">
            {{itemName}}
        </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "WRepartitionVerticalFrieze",
  props: {
    nbPromoters: { type: Number, default: 0 },
    nbDetractors: { type: Number, default: 0 },
    nbNeutrals: { type: Number, default: 0 },
    itemScore: { type: Number, default: 0 },
    itemName: { type: String, default: "" },
  },
  data() {
    return {
      total: 100,
      colors: ["#67B18C",  "#C1BFC7", "#C26170"],
    }
  },
  methods: {
    litteralValue: function(nb, type) {
      return this.$t('percentage_of_nps_type', { number: nb, type: this.$t(type) })
    },
    percentage: function(item){
      if (this.total != 100) {
        return (item / (this.total/100)).toFixed(0)
      }
      return item
    },
    totalValues() {
      this.total = this.nbPromoters + this.nbDetractors + this.nbNeutrals
    }
  },
  beforeMount(){
    this.totalValues()
 }
}
</script>

<style lang='stylus' scoped>
  .w-repartition-vertical-frieze

    .vertical-rankings
      width: 80px
      text-align: -webkit-center
      height: 100px

    .vertical-ranking
      width: 80%
      text-align: center

    .ver-item-name, .ver-item-score
      text-align: center
</style>
