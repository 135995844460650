<template>
  <div class="w-rating-circular">
    <v-progress-circular
      :size="36"
      :rotate="360"
      :value="formattedValue"
      :color="$colors.success"
    >
      <span v-if="value < 0.01" class="fs-11">{{ "<1%" }}</span>
      <span v-else class="fs-11">{{ formattedValue }}%</span>
    </v-progress-circular>
  </div>
</template>

<script>
  export default {
    name: "WRatingCircular",
    props: {
      value: { type: Number, required: true }
    },
    computed: {
      formattedValue() {
        return this.$options.filters.toPercent(this.value)
      }
    }
  }

</script>
