import { render, staticRenderFns } from "./CampaignPreferencesLayout.vue?vue&type=template&id=17a072db&scoped=true"
import script from "./CampaignPreferencesLayout.vue?vue&type=script&lang=js"
export * from "./CampaignPreferencesLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a072db",
  null
  
)

export default component.exports