<template>
  <div class="text-center">
    <v-progress-circular
      v-if="npsExist"
      :rotate="360"
      :size="80"
      :width="5"
      :value="getFragmentedPosition"
      color="#2db3ab"
      class="w-stats-nps-widget"
    >
      <div class="d-flex flex-column align-center">
        <span class="c-primary lh-34 f-34 font-weight-bold">
          {{ nps.toFixed(decimals) }}<span class="f-14">{{ unit }}</span>
        </span>
        <w-color-sign
          v-if="evolution"
          :decimals="decimals"
          :value="evolution"
        />
      </div>
    </v-progress-circular>
    <div
      v-if="nbReview"
      class="mt-1 sub-text f-12 c-lightgrey"
      v-html="translateNbReview"
    />
  </div>
</template>

<script>
  export default {
    name: "WStatsNpsWidget",
    props: {
      nps: { type: Number | null, required: true },
      nbReview: { type: Number | null, required: false, default: null },
      evolution: { type: Number, required: false, default: null },
      decimals: { type: Number, required: false, default: 0 },
      unit: { type: String, required: false, default: "" }
    },
    computed: {
      getFragmentedPosition() {
        return (this.nps + 100) / 2
      },
      npsExist() {
        return (typeof this.nps === 'number')
      },
      translateNbReview() {
        return this.$t(
          'nbReview_without_google_logo',
          { nbReview: this.nbReview?.toLocaleString() }
        )
      }
    }
  }
</script>
