<template>
  <span class="wiz-quote">
    <WIcon icon="quote-left" />
      <span class="content">{{ content }}</span>
    <WIcon icon="quote-right" />
  </span>
</template>

<script>
  export default {
    name: 'WQuote',
    props: {
      content: { type: String, required: true, default: '' },
    }
  }
</script>

<style lang="stylus" scoped>
  .wiz-quote
    & >>> .wiz-icon
      color: #CBCBCB
    .content
      padding-left: 0.45rem
      padding-right: 0.45rem
</style>
