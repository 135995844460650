import _sortBy from 'lodash/sortBy'

export function deepSort(tree, fctValue = null) {
  const sortedTree = {}

  function present(v, fctValue = null) {
    const value = (fctValue ? fctValue(v) : v)
    const isPresent = value && value !== "" && value !== [] && value !== {}
  
    return isPresent
  }
  
  if (tree) {
    if (Array.isArray(tree)) {
      return _sortBy(tree, v => fctValue ? fctValue(v) : v).filter(v => present(v, fctValue))
    } else {
      for (const key of _sortBy(Object.keys(tree), v => fctValue ? fctValue(v) : v)) {
        if (present(key, fctValue)) {
          if (Array.isArray(tree[key])) {
            const leafs = _sortBy(tree[key], v => fctValue ? fctValue(v) : v).filter(v => present(v, fctValue))
            sortedTree[key] = leafs
          } else if (typeof(tree[key]) === 'object') {
            const subtree = deepSort(tree[key], fctValue)
            sortedTree[key] = subtree         
          }
        }
      }
    }
  }

  return sortedTree
}

export function getPaths(node, fctValue = null, path = [], paths = {}) {
  function resolve(v, fctValue) {
    return (fctValue ? fctValue(v) : v)
  }

  if (Array.isArray(node)) {
    for (const value of node) {
      for (const subkey of path) {
        paths[resolve(subkey, fctValue)] ||= {}
        paths[resolve(subkey, fctValue)][resolve(value, fctValue)] = true
      }
    }
  } else if (typeof(node) === 'object') {
    for (const [key, value] of Object.entries(node)) {
      getPaths(value, fctValue, [...path, key], paths)

      for (const subkey of path) {
        paths[resolve(subkey, fctValue)] ||= {}
        paths[resolve(subkey, fctValue)][resolve(key, fctValue)] = true
      }
    }
  }

  return paths
}

export function eachNode(node, fctNode = null, level = 0) {
  if (Array.isArray(node)) {
    for (const value of node) {
      fctNode(node, value, [], level)
    }
  } else if (typeof(node) === 'object') {
    for (const [key, value] of Object.entries(node)) {
      eachNode(value, fctNode, level + 1)
      fctNode(node, key, value, level)
    }
  }
}