import ApiCall from '@api/apiCall.js'

export async function create(params) {
  const response = await new ApiCall().post("/api/wizville/conversation_pre_filled_message_groups", {
    conversation_pre_filled_message_group: params
  });

  return response.data
}

export async function update(id, params) {
  const response = await new ApiCall().put(`/api/wizville/conversation_pre_filled_message_groups/${id}`, {
    conversation_pre_filled_message_group: params
  });

  return response.data
}

export async function destroy(id) {
  const response = await new ApiCall().delete(`/api/wizville/conversation_pre_filled_message_groups/${id}`);

  return response.data
}
