import ApiCall from '@api/apiCall.js'

export async function create(placeCampaignAchievementIds, achievementActionTemplateIds, actionsDescription) {
  const response = await new ApiCall().post("/api/wizville/place_campaign_achievements_batch/create",
    params(placeCampaignAchievementIds, achievementActionTemplateIds, actionsDescription)
  );

  return response.data
}

export async function update(placeCampaignAchievementIds, achievementActionTemplateIds, actionsDescription) {
  const response = await new ApiCall().put("/api/wizville/place_campaign_achievements_batch/update",
    params(placeCampaignAchievementIds, achievementActionTemplateIds, actionsDescription)
  );

  return response.data
}

export async function destroy(placeCampaignAchievementId) {
  const response = await new ApiCall().post("/api/wizville/place_campaign_achievements_batch/destroy", {
    place_campaign_achievements_batch: {
      place_campaign_achievement_ids: [placeCampaignAchievementId]
    }
  });

  return response.data
}

function params(placeCampaignAchievementIds, achievementActionTemplateIds, actionsDescription) {
  return {
    place_campaign_achievements_batch: {
      place_campaign_achievement_ids: placeCampaignAchievementIds,
      achievement_action_template_ids: achievementActionTemplateIds,
      actions_description: actionsDescription
    }
  }
}
