export const env = document.currentScript.dataset.env
export const project = document.currentScript.dataset.project
export const production = (env === "production")
export const staging = !production
export const localMonitor = document?.currentScript?.dataset?.project === "localMonitor"
export const legacyUrl = document?.currentScript?.dataset?.legacyUrl || "http://wizville.localhost:3001"
export const adminLegacyUrl = `${legacyUrl}/admin`
export const legacyOldUrl = legacyUrl.replace("//wizville", "//old.wizville")
export const sessionToken = document?.currentScript?.dataset?.sessionToken
export const userLocale = document?.currentScript?.dataset?.userLocale
export const redirectUrl = document?.currentScript?.dataset?.redirectUrl
export const database = databaseFromPort(document?.currentScript?.dataset?.databasePort)
export const local = (legacyUrl.match(/localhost/) !== null)
export const appBaseUrl = (env === 'production' ? 'https://app.wizville.fr' : (env === 'preprod' ? 'https://app.wizville-preprod.fr' : (env === 'dev' ? 'https://app.wizville-dev.fr' :  "http://app.wizville.localhost:3000")))

function databaseFromPort(port) {
  switch(port) {
    case '7654':
      return 'DEV'
    case '6543':
      return 'PREPROD'
    default:
      return 'LOCAL'
  }
}

