/* eslint-disable */

function toBlob(byteCharacters, contentType='', sliceSize=512) {
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const textToFile = (fileName, fileContent, options = {}) => {
  const base64 = options['base64'] || false
  const binary = options['binary'] || false

  let data = fileContent

  data = (base64 ? atob(data) : data)
  data = (binary ? toBlob(data) : data)

  let fileURL = window.URL.createObjectURL(new Blob([data]));
  let fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
}

export default textToFile;
