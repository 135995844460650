import ApiCall from '@api/apiCall.js'

async function reportVoter(voterUniqueId, { allowNewFeedback, reason, comment }) {
  const response = await new ApiCall().post(
    '/api/wizville/trustville/report_voter',
    { 
      voterUniqueId,
      trustvilleReport: {
        allowNewFeedback, 
        reason, 
        comment
      }
    }
  )

  return response
}

export {
  reportVoter
}