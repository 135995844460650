<template>
  <div>
    <v-data-table
      v-if="items"
      :headers="headers"
      :items="items"
      mobile-breakpoint="0"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :footer-props="{ 'items-per-page-options': [15, 50, 100] }"
      class="v-datatable-without-y-borders"
    >
      <template v-slot:item.infoLocales="{ value }">
        <slot name="infoLocales" :infoLocales="value">
          <span>{{ value }}</span>
        </slot>
      </template>

      <template v-slot:item.date="{ value }">
        <span>{{ value | moment('DD-MM-YYYY HH:mm') }}</span>
      </template>

      <template v-slot:item.status="{ value }">
        <span :class="{ 'c-green': value === 'ok', 'c-error': value === 'ko' }" class="fs-13">
          {{ formatStatus(value) }}
        </span>
      </template>

      <template v-slot:item.imported="{ value }">
        <span class="fs-13 c-primary">{{ value }}</span>
      </template>

      <template v-slot:item.total="{ value }">
        <span class="fs-13 c-primary">{{ value }}</span>
      </template>
    </v-data-table>
    <div v-else
      class="mb-4"
    >
      <v-skeleton-loader type="paragraph" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WScheduledActionTraceTable',
    props: ['headers', 'items', 'paginateOptions', 'serverItemsLength'],
    data() {
      return {
        options: this.paginateOptions,
      }
    },
    methods: {
      formatStatus(status) {
        if (status === 'ok') {
          return this.$t('product_import_preference_history_page_status_success')
        } else {
          return this.$t('product_import_preference_history_page_status_error')
        }
      },
      paginate(newVal, oldVal) {
        if (
          newVal.page != oldVal.page ||
          newVal.itemsPerPage != oldVal.itemsPerPage
        ) {
          this.$emit('paginate', newVal)
        }
      },
    },
    watch: {
      options: {
        handler: 'paginate',
        deep: true,
      },
    }
  }
</script>
