<template>
  <EvolutionWidget
    :campaign="campaign"
    :title="title"
    :basedRequest="basedRequest"
    :indicatorAlias="indicatorAlias"
    :serieColors="serieColors"
    class="sentiment-evolution-widget"
    :key="feeling"
  >
    <template #title>
      <div class="d-flex align-center">
        <div>
          {{ title }}
        </div>
        <div style="width: 80px" class="ml-1">
          <v-select
            v-model="feeling"
            :items="feelingOptions"
            hide-details
            dense
            class="pt-0 mt-0"
          ></v-select>
        </div>
      </div>
    </template>
  </EvolutionWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'
  import TopicSemanticMixin from '@statistics/shared/topic_semantic_mixin'

  export default {
    name: "SentimentEvolutionWidget",
    props: ['campaign', 'zoomedData'],
    components: {
      EvolutionWidget
    },
    mixins: [
      TopicSemanticMixin
    ],
    data() {
      return {
        feeling: 0,
        feelingOptions: [
          { text: this.$t('positives'), value: 0 },
          { text: this.$t('neutrals').toLowerCase(), value: 1 },
          { text: this.$t('negatives'), value: 2 }
        ]
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest'
      ]),
      basedRequest() {
        let request = this.dashboardFilterRequest.select({
          main_topics_verbatims: [
            { rate_feeling_: { feeling: this.feeling, cast: 'integer', mod: 'ROUND', as: 'rateFeeling' } }
          ]
        }).where({
          campaign_id: this.campaign.id
        })

        if (this.hasTextAnalysisEnabled) {
          request = request.where({ text_analysis_topic_id: this.topicOptions.map(topic => topic.value) })
        }

        if (this.zoomedData?.type == 'topic') {
          request = request.where({ [this.topicIdColumn]: this.zoomedData.id })
        } else if (this.zoomedData?.type == 'theme') {
          request = request.where({ [this.themeIdColumn]: this.zoomedData.id })
        }

        return request
      },
      indicatorAlias() {
        return 'rateFeeling'
      },
      serieColors() {
        if (this.feeling == 0) {
          return ['#2DB3AB']
        } else if (this.feeling == 1) {
          return ['#dae3e6']
        } else if (this.feeling == 2) {
          return ['#d34e6e']
        }
      },
      title() {
        return this.$t('part_of_feeling')
      },
      topicOptions() {
        if (this.zoomedData.type == 'topic') {
          return [
            {
              text: `${this.zoomedData.theme.name} > ${this.zoomedData.topic.name}`,
              value: this.zoomedData.id
            }
          ]
        } else if (this.zoomedData.type == 'theme') {
          return this.zoomedData.topics.map(topic => ({
            text: `${topic.theme.name} > ${topic.topic.name}`,
            value: topic.topic.id
          }))
        }
      },
    },
  }
</script>