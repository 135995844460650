import ApiCall from '@api/apiCall.js'

async function getContext(path, params = {}) {
  try {
    const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")
    const response = await new ApiCall('context').get(`${path}?${encodeGetParams}`)
    return response.data.context

  } catch(error) {
    console.error(error)
    return null
  }
}

async function platform() {
  return (await getContext('/api/wizville/context/platform'))
}

async function authAdmin() {
  return (await getContext('/api/wizville/context/auth_admin'))
}

async function authUser() {
  return (await getContext('/api/wizville/context/auth_user'))
}

async function authDashboard(dashboardType, dashboardId) {
  return (await getContext('/api/wizville/context/auth_dashboard', { dashboardId, dashboardType }))
}

async function authCampaignPreferences(campaignId) {
  return (await getContext('/api/wizville/context/auth_campaign_preferences', { campaignId }))
}

async function authVoter(voterUniqueId) {
  return (await getContext('/api/wizville/context/auth_voter', { voterUniqueId }))
}

async function authSurvey(campaignId) {
  return (await getContext('/api/wizville/context/auth_survey', { campaignId }))
}

async function voterThirdPartyTransfer(voterThirdPartyTransferUniqueId) {
  return (await getContext('/api/wizville/context/voter_third_party_transfer', { voterThirdPartyTransferUniqueId }))
}

export {
  authCampaignPreferences,
  platform,
  authAdmin,
  authUser,
  authDashboard,
  authVoter,
  authSurvey,
  voterThirdPartyTransfer
}
