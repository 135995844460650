<template>
  <div class="achievements">
    <AchievementsNavigation :page="page" />
    <LeaderboardPage
      v-if="page === 'leaderboard'"
    />
    <MyAchievementsPage
      :user-id="currentUserId"
      v-if="page === 'my_achievements'"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AchievementsNavigation from './AchievementsNavigation'
  import LeaderboardPage from './LeaderboardPage'
  import MyAchievementsPage from './MyAchievementsPage'

  export default {
    name: "Achievements",
    components: {
      AchievementsNavigation,
      LeaderboardPage,
      MyAchievementsPage,
    },
    props: {
      page: {
        type: String,
        required: true
      },
      elementId: {},
    },
    computed: {
      ...mapGetters([
        'mainCampaignId',
        'currentUserId',
      ])
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'
    
  .achievements
    padding-top: $filters-height
</style>
