<template>
  <div>
    <v-card-text class="fs-12 pt-2 text-center">
      <div class="text-center">
        <v-progress-circular
          v-if="LocalPosition !== null"
          :rotate="360"
          :size="90"
          :width="5"
          :value="getFragmentedPosition"
          color="#2db3ab"
        >
          <v-row class="mr-1">
             <v-col class="pa-0">
              <span class="c-primary f-32">
                {{ LocalPosition }}
              </span>
            </v-col>
            <v-col class="pa-0 labels-container">
              <span class="ranking-label c-primary f-10">{{ LocalPosition | ordinal }}</span>
              <div class="color-sign-label ml-2">
                <w-color-sign :value="LocalEvolution" />
              </div>
            </v-col>
          </v-row>
        </v-progress-circular>
      </div>
    </v-card-text class>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "WLocalCompetitorsWidget",
    props: {
      LocalPosition: {
        required: true,
        type: Number
      },
      LocalEvolution: {
        required: false,
        type: Number,
        default: 0
      },
    },
    computed: {
      getFragmentedPosition: function() {
        return this.LocalPosition + 100 / 2
      },
    }
  }
</script>

<style lang="stylus" scoped>
</style>