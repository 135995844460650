import WizClient from "@shared/http/wizClient"

async function updateCategories(params = {}) {
  const response = await WizClient.post("/lm_brands/lm_brand_category_update", params)

  return response.data
}

export {
  updateCategories
}
