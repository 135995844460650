<template>
  <flag :iso="flagLocale" class="rounded-flag ml-2 mr-2" />
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagLocale() {
      return (
        // See `https://github.com/stevenrskelton/flag-icon/tree/master/gif` and
        // `https://en.wikipedia.org/wiki/ISO_3166-1` to search for the right country codes.
        {
          cs: 'cz',
          da: 'dk',
          el: 'gr',
          en: 'gb',
          uk: 'ua',
        }[this.locale] || this.locale
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.rounded-flag
  border-radius 1em
</style>
