<template>
  <div class="w-rate-bar d-flex">
    <div :style="barStyle" class="rate-bar text-align-center">
    </div>

    <div class="fs-12 pl-2" v-if="label && label !== ''">
      {{ label }}%
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WRatebar',
    props: {
      value: {
        type: Number,
        required: true
      },
      barColor: {
        type: String,
        required: false,
        default: null
      },
      height: {
        type: String,
        required: false,
        default: "20px"
      },
      label: {
        type: String,
        required: false,
        default: ""
      }
    },
    computed: {
      barStyle() {
        return {
          width: `${this.value}%`,
          backgroundColor: this.barColor || this.$colors.success,
          height: this.height
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-rate-bar
    .rate-bar
      border-radius: 3px
</style>
