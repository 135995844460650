import ApiCall from '@api/apiCall.js'

export async function editSmartBenchmarkPreferences(campaignId, campaignSmartBenchmarkPreferences, stemGroupTopicIds) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/edit_smart_benchmark_preferences`, {
    campaignSmartBenchmarkPreferences,
    stemGroupTopicIds
  });

  return response.data
}

export async function duplicateSmartBenchmarkPreferences(campaignId, campaignSmartBenchmarkPreferences) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/duplicate_smart_benchmark_preferences`, {
    campaignSmartBenchmarkPreferences
  });

  return response.data
}

export async function deleteSmartBenchmarkPreferences(campaignId, campaignSmartBenchmarkPreferences) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/delete_smart_benchmark_preferences`, {
    campaignSmartBenchmarkPreferences
  });

  return response.data
}

export async function makeDefaultSmartBenchmarkPreferences(campaignId, campaignSmartBenchmarkPreferences) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/make_default_smart_benchmark_preferences`, {
    campaignSmartBenchmarkPreferences
  });

  return response.data
}

export async function editCampaignNetImpactScorePreference(campaignId, campaignNetImpactScorePreferences) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/edit_campaign_net_impact_score_preferences`, {
    campaignNetImpactScorePreferences,
  });

  return response.data
}

export async function editCampaignAchievementPreference(campaignId, campaignAchievementPreferences, retroactive, retroactivityDelay) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/edit_campaign_achievement_preferences`, {
    campaignAchievementPreferences, retroactive, retroactivityDelay
  });

  return response.data
}

export async function sendCampaignAchievementNotification(campaignId) {
  const response = await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/send_achievements_notifications`, {});

  return response.data
}

export async function editTextAnalysisPreference(campaignId, textAnalysisPreference) {
  const response =  await new ApiCall().post(`/api/wizville/campaign_preferences/${campaignId}/edit_text_analysis_preferences`, {
    textAnalysisPreference,
  });

  return response.data
}

export async function editCampaignSmartAnswersPreferences(campaignId, preference) {
  const response = await new ApiCall().post(
    `/api/wizville/campaign_preferences/${campaignId}/edit_campaign_smart_answers_preferences`,
    { preference }
  )
  return response.data
}
