<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('WConfirmationModal.title') }}
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cancel()"
          >
            {{ cancelLabel || $t('WConfirmationModal.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirm()"
          >
            {{ confirmLabel || $t('WConfirmationModal.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: { type: Boolean, default: false },
      text: { type: String, required: true },
      confirmLabel: { type: String, default: null },
      cancelLabel: { type: String, default: null },
    },
    emits: [
      'confirm',
      'cancel',
      'input',
    ],
    methods: {
      confirm() {
        this.$emit('input', false)
        this.$emit('confirm')
      },
      cancel() {
        this.$emit('input', false)
        this.$emit('cancel')
      },
    },
  }
</script>
