<template>
  <footer class="d-flex flex-wrap align-center ma-2">
    <v-btn :href="links.about[locale] || links.about.en" text x-small>{{ $i18n.t('layout.footer.about') }}</v-btn>
    <v-btn :href="links.help[locale] || links.help.en" target="_blank" text x-small>{{ $i18n.t('layout.footer.help') }}</v-btn>
    <v-btn :href="links.contact[locale] || links.contact.en" target="_blank" text x-small>{{ $i18n.t('layout.footer.contact') }}</v-btn>
    <v-menu offset-y v-if="currentUser.isAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small text v-bind="attrs" v-on="on">
          <span class="pl-2"></span>{{ $i18n.t('languages.' + lang) }}</span>
          <v-icon right dark>mdi-menu-up</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(lang, key) in items" :key="key">
          <v-list-item-title>
            <a href="#" @click="changeLang(key)"><span class="pl-2">{{ $i18n.t('languages.' + key) }}</span></a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </footer>
</template>

<script>
import { LANGUAGES_NAME } from '@i18n/setup'
import { mapGetters } from 'vuex'

export default ({
  data() {
    return {
      locale: localStorage.locale || 'en',
      lang: localStorage.locale_name || 'English',
      items: LANGUAGES_NAME,
      links: {
        about: {
          fr: 'https://wizville.com/fr',
          en: 'https://wizville.com/en'
        },
        help: {
          fr: 'https://wizville.zendesk.com/hc/fr',
          en: 'https://wizville.zendesk.com/hc/en-gb'
        },
        contact: {
          fr: 'https://www.wizville.com/contact/support-wizville/',
          en: 'https://www.wizville.com/en/contact/support-wizville/'
        }
      }
    }
  },
  methods: {
    async changeLang(lang) {
      localStorage.locale_name = LANGUAGES_NAME[lang]
      localStorage.locale = lang
      await this.$api.wizville.users.update(this.currentUserId, { locale: lang })

      window.location.reload()
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserId"
    ])
  }
})
</script>

<style scoped>
  footer {
    text-align: center;
    width: 100%;
  }
</style>
