import ApiCall from '@api/apiCall.js'

export async function sendByEmail(voterUniqueId, params) {
  const response = await new ApiCall().post(`/api/wizville/voters/${voterUniqueId}/send_by_email`, {
    voterUniqueId,
    send_by_email: params
  })

  return response.data
}

export async function close(voterUniqueId, params) {
  const response = await new ApiCall().post(`/api/wizville/voters/${voterUniqueId}/close`, {
    voterUniqueId,
    close: params
  })

  return response.data
}

export async function updateVoterSmartAnswer(voterUniqueId, { smartAnswer }) {
  const response = await new ApiCall().post(
    `/api/wizville/voters/${voterUniqueId}/update_smart_answer`,
    { voterUniqueId, smartAnswer }
  )

  return response.data
}

export async function removeVoterSmartAnswer(voterUniqueId) {
  const response = await new ApiCall().post(
    `/api/wizville/voters/${voterUniqueId}/remove_smart_answer`,
    { voterUniqueId }
  )

  return response.data
}
