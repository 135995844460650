import $api from '@api'

const platformContext = {
  state: () => ({
    loaded: null,
    trackingEvents: [],
    facebookAppId: null
  }),
  getters: {
    trackingEvents(state) {
      return state.trackingEvents
    },
    facebookAppId(state) {
      return state.facebookAppId
    }
  },
  mutations: {
    setPlatformContext(state, data) {
      state.loaded = Date.now()
      state.trackingEvents = data.trackingEvents
      state.facebookAppId = data.facebookAppId
    },
  },
  actions: {
    async platformContext(context) {
      if (!context.state.loaded) {
        const ctx = await $api.wizville.contexts.platform()

        if (ctx) {
          context.commit('setPlatformContext', ctx)
        }
      }
    },
  },
}

export default platformContext
