export const transactions = []

let transactionId = 1

export class Transaction {
  constructor(type, name) {
    this.historyStateKey = window.history?.state?.key
    this.pathname = window.location.pathname
    this.status = 'init'
    this.type = type
    this.name = name
    this.startedAt = null
    this.endedAt = null
    this.context = null
    this.result = null
    this.duration = null
    this.transactionId = transactionId
    
    if (transactions.length > 1000) {
      transactions.length = 0
      transactionId = 0
    }

    transactionId += 1 
    transactions.push(this)
  }

  start(context) {
    this.historyStateKey = window.history?.state?.key
    this.location = window.location.pathname
    this.status = 'running'
    this.context = context
    this.startedAt = new Date().getTime()
  }

  ok(result) {
    this.status = 'ok'
    this.result = result
    this.endedAt = new Date().getTime()
    this.duration = this.endedAt - this.startedAt
  }

  ko(result) {
    this.status = 'ko'
    this.result = result
    this.endedAt = new Date().getTime()
    this.duration = this.endedAt - this.startedAt
  }

  elapsedTime() {
    return (((this.endedAt || new Date().getTime()) - this.startedAt) / 1000)
  }
}