import Row from './row';

class Page {
  constructor({
    pdf,
    orientation = 'p',
    bodyMargin = { left: 0, top: 0 },
    header,
    footer
  } = {}) {
    this.pdf = pdf;
    this.orientation = orientation;
    this.bodyMargin = bodyMargin;
    this.header = header;
    this.footer = footer;
    this.cursorX = 0;
    this.cursorY = 0;
    this.headerHeight = 0;
    const widthInPt = orientation === 'p' ? 595.28 : 841.89;
    const heightInPt = this.orientation === 'p' ? 841.89 :  595.28;
    this.width = widthInPt * 96 / 72;
    this.height = heightInPt * 96 / 72;
    this.bodyWidth = this.width - this.bodyMargin.left * 2;
  }

  async init() {
    if (this.header) {
      await this.addHeader();
    }

    this.bodyHeight = this.height - (this.headerHeight + (this.footer?.height || 0) + this.bodyMargin.top * 2);

    if (this.footer) {
      await this.addFooter();
    }

    this.cursorX = this.bodyMargin.left;
    this.cursorY = this.headerHeight + this.bodyMargin.top;
    this.cursorStatus = 'body';
  }

  async addHeader() {
    const headerMarginTop = this.header.marginTop || 0;

    this.cursorStatus = 'header';
    this.cursorX = this.header.marginLeft || 0;
    this.cursorY = headerMarginTop;
    await this.header.content(this.pdf);
    this.headerHeight = this.header.height ?  this.header.height + headerMarginTop : this.cursorY;
  }

  async addFooter() {
    this.cursorStatus = 'footer';
    this.cursorX = this.footer.marginLeft || 0;
    this.cursorY = this.headerHeight + this.bodyHeight + this.bodyMargin.top * 2 + (this.footer.marginTop || 0);
    await this.footer.content(this.pdf);
  }

  notEnoughtHeightInBody(height, marginTop) {
    return height &&
      this.cursorStatus === 'body' &&
      (this.cursorY + height + marginTop) > (this.headerHeight + this.bodyMargin.top + this.bodyHeight)
  }

  getWidth() {
    switch (this.cursorStatus) {
      case 'header':
        return this.width - (this.header.marginLeft || 0) * 2;
      case 'body':
        return this.width - this.bodyMargin.left * 2;
      case 'footer':
        return this.width - (this.footer.marginLeft || 0) * 2;
    }
  }
}

export default Page;
