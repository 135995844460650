<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    min-width="305px"
    min-height="500px"
    class="emoji-picker"
    v-bind="menuProps"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on">
        <v-text-field
          class="text-field"
          label="Emoji"
          :value="emoji || initEmoji"
          readonly
          :append-icon="textFieldIcon"
          background-color="#eef1f5"
          hide-details="auto"
          dense
          outlined
          filled
          clearable
        />
      </div>
    </template>

    <v-card class="emoji-picker-menu-content">
      <VEmojiPicker @select="onEmojiSelected" />
    </v-card>
  </v-menu>
</template>

<script>
  import { emit } from 'process'
  import { VEmojiPicker } from 'v-emoji-picker'

  export default {
    name: 'WEmojiPicker',
    components: {
      VEmojiPicker
    },
    props: {
      menuProps: { 
        required: false 
      },
      initEmoji: {
        required: false
      }
    },
    data() {
      return {
        open: false,
        emoji: null
      }
    },
    computed: {
      textFieldIcon() {
        return this.open ? 'mdi-chevron-up' : 'mdi-chevron-down'
      }
    },
    methods: {
      onEmojiSelected(emoji) {
        this.emoji = emoji.data
        this.open = false
        this.$emit('emojiSelected', this.emoji)
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .text-field
    width: 110px
</style>
