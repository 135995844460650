export default function imgUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var img = new Image;
    img.crossOrigin = 'anonymous';
    img.onload = function() {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL('image/png');
      canvas = null;
      resolve([dataURL, img.width, img.height]);
    };
    img.onerror = function() {
      reject(new Error("Failed to load image"))
    }
    img.src = url;
  })
}
