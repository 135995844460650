<template>
  <WStatsWidget
    v-if="campaign === undefined"
    :title="title"
    :cols="12"
    :loading="true"
    :empty="false"
  />
  <EvolutionWidget
    v-else
    :title="title"
    :campaign="campaign"
    :basedRequest="basedRequest"
    :indicatorAlias="indicatorAlias"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'

  export default {
    name: "NpsEvolution",
    props: {
      campaign: { type: Object }
    },
    components: {
      EvolutionWidget
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'isLcl'
      ]),
      basedRequest() {
        return this.dashboardFilterRequest.select({
          voters: [
            {
              'avg_nps': {
                cast: 'integer',
                mod: 'ROUND',
                as: this.indicatorAlias
              }
            },
            { nb_review: { as: 'nbReview' } }
          ]
        })
        .where({
          campaign_id: this.campaign.id
        })
      },
      indicatorAlias() {
        return 'avgNps'
      },
      title() {
        return `${this.$t('widget_evolution_title')} - ${this.isLcl ? 'IRC' : 'NPS'}`
      }
    }
  }
</script>
