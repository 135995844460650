import Canvg from 'canvg';

export default async function svgToBase64(svgData, options) {
  const width = options.width || 800;

  return new Promise(async (resolve, reject) => {
    try {
      let canvas = document.createElement('canvas');
      canvas.width = options.width;

      const ctx = canvas.getContext("2d");

      let v = Canvg.fromString(ctx, svgData);

      v.resize(options.width*2, options.height*2, 'xMinYMin meet');
      await v.render();

      const dataURL = canvas.toDataURL('image/png');
      const width = canvas.width;
      const height = canvas.height;
      canvas = null;

      resolve([dataURL, width, height]);
    } catch(e) {
      console.log(e);
      reject(new Error("Failed to load SVG"))
    }
  });
}
