<template>
  <div>
    <v-progress-circular
      v-if="position !== null"
      :rotate="360"
      :size="90"
      :width="5"
      :value="getFragmentedPosition"
      :color="getColorPosition"
    >
      <div :class="`d-flex align-center justify-center ga-1 c-primary ${getFontPositionSize} pb-0 mb-0`">
        <div>{{ position }}</div>
        <w-color-sign
          v-if="displayEvolution"
          :value="evolution"
          :showZero="true"
          class="sign"
        />
      </div>
    </v-progress-circular>
    <v-tooltip :disabled="disableTooltip" v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="f-32 c-textgrey d-flex align-center justify-center ga-1">
          <span>{{ $t('nc') }}</span>
          <a :href="urlToBenchmark" target="_blank" class="link">
            <v-icon
              :class="`${getIconInfoSize} ${iconInfoColor}`"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </a>
        </div>
      </template>
      <span>{{ nrTooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  const GOLD = "#FFD333"
  const SILVER = "#A5C0C4"
  const BRONZE = "#C89C73"
  const OTHER = "#3CB6AE"

  export default {
    name: "WPositionWidget",
    props: {
      position: { type: Number, default: 0 },
      evolution: { type: Number, default: 0 },
      nrTooltipText: {type: String, require: false },
      iconInfoSize: { type: String, default: 'medium' },
      iconInfoColor: { type: String, default: 'c-lightgrey' },
      maxPosition: { type: Number, required: false },
      displayEvolution: {
        required: false,
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        urlToBenchmark: 'https://wizville.zendesk.com/hc/fr/articles/7909896871196-Benchmark-Avis-Google-R%C3%A8gles-de-collecte-des-avis'
      }
    },
    computed: {
      getFragmentedPosition() {
        return this.maxPosition ?
          this.getFragmentedPositionWithMaxPosition :
          this.getFragmentedPositionWithoutMaxPosition
      },
      getFragmentedPositionWithoutMaxPosition() {
        if (this.position < 10 && this.position > 0)
          return 100 - (this.position - 1) * 10
        return 0
      },
      getFragmentedPositionWithMaxPosition() {
        if (this.position && this.maxPosition) {
          if (this.position === 1) {
            return 100
          } else {
            return Math.round(100 - (this.position / this.maxPosition * 100))
          }
        }
        return 0
      },
      getFontPositionSize() {
        const sizes = { 1: "f-32", 2: "f-30", 3: "f-25", 4: "f-20" }
        const positionSize = this.position.toString().length
        if (sizes[positionSize]) {
          return sizes[positionSize]
        }
        return "f-18"
      },
      getIconInfoSize() {
        const sizes = { small: 'f-12', medium: 'f-16', large: 'f-32' }
        return sizes[this.iconInfoSize]
      },
      getColorPosition: function() {
        switch(this.position) {
          case 1:
            return GOLD
          case 2:
            return SILVER
          case 3:
            return BRONZE
          default:
            return OTHER
        }
      },
      disableTooltip() {
        return this.nrTooltipText === null;
      }
    },
  }
</script>

<style lang="stylus" scoped>
  .sign
    position: relative
    top: 2px

  .link
    position: relative
    top: -2px
</style>
