import WizClient from '@shared/http/wizClient'

async function confirmCompetitiveReportSubscriber(token) {
  const response = await WizClient.put(
    '/api/local_monitor/subscribers/confirm',
    { token }
  )

  return response.data
}

export {
  confirmCompetitiveReportSubscriber
}
