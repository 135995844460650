export function trackUser(currentUserId, currentUserData) {
  window.posthog.identify(
    currentUserId,
    currentUserData
  )
}

export function resetUser() {
  window.posthog.reset(true)
}

export function trackPageView(data) {
  window.posthog.capture('$pageview', data)
}

export function trackEvent(eventLabel, data) {
  window.posthog.capture(eventLabel, data)
}

export default { trackUser, resetUser,  trackPageView, trackEvent }