class BasedResult {
  constructor(query, options, result) {
    this.result = result
    this.data = result.data
    this.query = query
    this.options = options
  }

  first() {
    const result = this.result

    if (result) {
      let deep =  this.query.group.length
      let leaf = null
      let i = 0

      if (deep > 0) {
        while (i < deep) {
          if (i === 0 || leaf) {
            leaf = Object.values(i === 0 ? result.data : leaf)
            leaf = (leaf ? leaf[0] : null)
          }

          i += 1
        }
      } else {
        leaf = Object.values(result.data)[0]
        leaf = (leaf ? leaf[0] : null)
      }

      return leaf || null
    }
  }
}

export default BasedResult
