import WizClient from "@shared/http/wizClient"

async function uploadOnDrive(params = {}) {
  const response = await WizClient.post("/net_impact_score/upload_on_drive", params)

  return response.data
}

export {
  uploadOnDrive
}
