<template>
  <div class="w-bubble d-flex">
    <slot name="icon">
      <v-icon v-if="icon" class="pr-3">{{ icon }}</v-icon>
    </slot>

    <div
      class="px-3 py-2 text-align-left"
      :class="[stripeContainer ? 'bubble-container-stripe' : 'bubble-container']"
    >
      <div class="bubble-content f-14 lh-17">
        <slot name="content">
          {{ content }}
        </slot>
      </div>

      <div class="bubble-footer c-grey f-11 pt-1">
        <slot name="footer">
          {{ subcontent }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: { required: false },
      subcontent: { required: false },
      icon: { required: false },
      stripeContainer: {
        required: false,
        default: false
      },
    },
    name: "WBubble",
  }
</script>

<style lang="stylus" scoped>
  .w-bubble
    .bubble-container
      background: #F4F5F7
      border-radius: 8px
    .bubble-container-stripe
      background: repeating-linear-gradient(
        45deg,
        #f4f5f7 0px,
        #f4f5f7 10px,
        white 10px,
        white 20px
      )
      border-radius: 8px
</style>
