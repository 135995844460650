<template>
  <WStatsWidget
    :cols="12"
    :justifyCenter="false"
    :title="$t(title)"
    class="widget-evolution"
    contentPadding="0.75em"
    contentWidth="100%"
  >
    <template #subtitle>
      <HeaderSubtitle/>
    </template>

    <template #options>
      <div class="d-flex">
        <WidgetSettingsMenu
          :competitorScope="competitorScope"
          :competitorMode="competitorMode"
          :periodType="periodType"
          :campaignSmartBenchmarkPreferenceId="campaignSmartBenchmarkPreferenceId"
        >
        </WidgetSettingsMenu>
        <w-drop-down-menu
          v-show="!loading"
          data-html2canvas-ignore
          class="ml-auto"
          :items="exportItems"
          icon="mdi-download"
        />
      </div>
    </template>

    <template #content>
      <WTimelineChart
        ref="timelineChart"
        class="pb-3"
        :key="requestObj['id']"
        :connectNulls="periodType === 'genesis'"
        :quarterOffset="currentUser.quarterOffset"
        @loadingStatusChanged="changeLoadingStatus"
        :tooltipContent="tooltipContent"
        :defaultOptions="{
          periodFrom: datesScope.dateBegin,
          periodTo: datesScope.dateEnd,
          request: requestObj,
          tickUnit: dashboardFilterUnit,
          to: datesScope.dateEnd,
          periodNumber: 6,
          tickInterval: 1,
          min: 1,
          max: 5.1
        }"
        type="line"
      />
    </template>
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _uniqueId from "lodash/uniqueId"
  import WidgetSettingsMenu from '../WidgetSettingsMenu'
  import HeaderSubtitle from '../shared/HeaderSubtitle'

  export default {
    name: "widget-evolution",
    components: {
      WidgetSettingsMenu,
      HeaderSubtitle
    },
    props: {
      competitorMode: {
        required: true,
        type: String
      },
      periodType: {
        required: true,
        type: String
      },
      competitorScope: {
        required: true,
        type: String
      },
      campaignSmartBenchmarkPreferenceId: {
        required: false
      },
      lmBrandIds: {
        required: false
      }
    },
    data() {
      return {
        title: this.$t("competitors_widget_evolution_title"),
        loading: true,
        exportElementId: _uniqueId('evolution-')
      }
    },
    computed: {
      ...mapGetters([
        "currentDashboardBasedTable",
        "lrmDashboardFilterRequest",
        "placeIdsScope",
        "datesScope",
        "dashboardFilterUnit",
        'currentDashboard',
        'dashboardFilterDates',
        'currentUser'
      ]),
      requestObj() {
        const lrmDashboardFilterRequest = this.lrmDashboardFilterRequest
        const placeIdsScope = this.placeIdsScope
        const periodType = this.periodType
        const datesScope = this.datesScope
        const competitorMode = this.competitorMode
        const competitorScope = this.competitorScope
        const lmBrandIds = this.lmBrandIds
        const currentDashboardBasedTable = this.currentDashboardBasedTable
        const uniqueId = Math.random().toString(36).substr(2, 9)
        const self = this

        const requestObj = {
          id: uniqueId,
          dateBegin: null,
          dateEnd: null,

          dateBetween(dateBegin, dateEnd) {
            this.dateBegin = dateBegin
            this.dateEnd = dateEnd
            return this
          },
          async resolve(
            queryName,
            {
              tickNumber,
              tickUnit,
              timeSerieParams
            },
            endPoint
          ) {
            const request = lrmDashboardFilterRequest.select({
              [currentDashboardBasedTable]: [{
                lrm_ranking_evolution: { params: {
                  period: periodType,
                  place_id: placeIdsScope,
                  competitor_mode: competitorMode,
                  competitor_scope: competitorScope,
                  date_begin: this.dateBegin,
                  date_end: this.dateEnd,
                  ranking_date_begin: datesScope.dateBegin,
                  ranking_date_end: datesScope.dateEnd,
                  tick_unit: tickUnit,
                  competitor_lm_brand_ids: lmBrandIds
                } }
              }]
            })

            const result = (await self.$resolve(request)).first()
            return {
              data: result?.lrmRankingEvolution || {}
            }
          },
        };

        return Object.create(requestObj)
      },
      exportItems() {
        return [
          { title: 'PNG', onClick: this.exportToPng },
          { title: 'PDF', onClick: this.exportToPdf }
        ]
      },
    },
    methods: {
      tooltipContent(graph) {
        return `
        <div style="text-align: center;">
          <h1 style="font-size: 14px; font-weight: 700;">${graph.point.category}</h1>
          <br>
          <span>${graph.series.name}</span>
          <br>
          <span style="font-weight: 700; font-size: 24px;">${Number(graph.point.y).toFixed(1)}</span>
          <br>
          <span>${this.$t('nb_review', { count: graph.series.userOptions.rawData[graph.point.index].nbReview })}</span>
        </div>`
      },
      exportFilename() {
        const pageName = this.$t(this.$route.name);
        const campaignName = this.currentDashboard.name;
        const period = this.dashboardFilterDates.text;
        const date = this.$date().format('ddd DD MMM HH_mm');

        return `${pageName} - ${campaignName} - ${period} - ${date}`;
      },
      changeLoadingStatus(status) {
        this.loading = status;
      },
      exportToPng() {
        this.$refs.timelineChart.exportToPng(this.exportFilename(), this.title);
      },
      async exportToPdf() {
        this.$refs.timelineChart.exportToPdf(this.exportFilename(), this.title);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'

  .widget-evolution-header
    height: $widget-header-height
</style>
