<template>
  <div class="wiz-stacked-bar">
    <div
      :style="parentStyle"
      class="wiz-stacked-bar__parent"
    >
      <div
        v-for="(childValue, index) in adjustValues"
        :key="index"
        class="wiz-stacked-bar__child"
        :class="buildClass(childValue)"
        :style="buildStyle(childValue)"
      />
    </div>
  </div>
</template>

<script>
  import _filter from 'lodash/filter'
  import _get from 'lodash/get'
  import _sum from 'lodash/sum'
  import _map from 'lodash/map'
  import _size from 'lodash/size'

  const HEIGHT = 18
  const WIDTH = 100
  const MAX_PERCENT = 100

  export default {
    name: "WStackedBar",
    props: {
      /**
       * Height of stacked bar
       */
      height: {
        type: Number,
        default: HEIGHT
      },
      /**
       * Width of stacked bar
       */
      width: {
        type: Number,
        default: WIDTH
      },
      /**
       * Stackedbar data list
       */
      values: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      /**
       * Stackedbar parent style, contains height and width
       * @type {Object}
       */
      parentStyle() {
        return {
          height: `${this.height}px`,
          width: `${this.width}px`
        }
      },
      /**
       * Adjust stackedbar datas in case of the sum is not 100%
       * @type {Array}
       */
      adjustValues() {
        const { values } = this
        const sumArray = _sum(_map(values, 'width'))

        // Filter only the child which width is more than 0
        let compactedValues = _filter(values, function(elt) {
          return _get(elt, 'width') > 0
        })
        return _map(compactedValues, function(childElement, index) {
          const length = _size(values)

          if (sumArray > 0 && sumArray !== MAX_PERCENT && index === length - 1){
            return {
              width: (_get(childElement, 'width') - (sumArray - MAX_PERCENT)) + '%',
              backgroundColor: _get(childElement, 'backgroundColor')
            }
          }

          return {
            width: _get(childElement, 'width') + '%',
            backgroundColor: _get(childElement, 'backgroundColor')
          }
        })
      }
    },
    methods: {
      isThemeColor(value) { return value.backgroundColor.startsWith('c') },
      buildClass(value) {
        let result = {}

        result[`background-${value.backgroundColor}`] = this.isThemeColor(value)
        return result
      },
      buildStyle(value) {
        let result = Object.assign({}, value)

        if (this.isThemeColor(value)) { result['backgroundColor'] = null }

        return result
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .wiz-stacked-bar {
    &__child {
      height: 100%
      display: inline-block

      &:first-child {
        border-radius: 3px 0 0 3px
      }

      &:last-child {
        border-radius: 0 3px 3px 0
      }
    }
  }
</style>
