import ApiCall from '@api/apiCall.js'
import BasedQuery from "@api/based/basedQuery"
import { i18n, setLanguageAsync } from '@i18n/setup'

export async function update(userId, userParams) {
  if (userParams['locale']) {
    setLanguageAsync(userParams['locale'])
  }
  const response = await new ApiCall().put(`/api/wizville/users/${userId}`, {
    user: userParams
  })

  return response.data
}

export async function getUserFilters(dashboardType, dashboardId) {
  const userFilters = ((await new BasedQuery({ scope: "user" }).select({
    [dashboardType === 'multi' ? 'dashboard_multis' :  'dashboard_basics']: [ "user_filters" ]
  }).where({
    id: dashboardId
  }).resolve("getUserFilters")).first())?.userFilters

  return userFilters
}

export async function sendPasswordResetEmail(email) {
  const response = await new ApiCall().post(
    '/api/wizville/users/send_password_reset_email',
    { email }
  )

  return response.data
}

export async function sendEmailConfirmEmail(new_email, email) {

  const response = await new ApiCall().post(
    '/api/wizville/users/send_email_confirm_email',
    {
      new_email,
      email
    }
  )

  return response.data
}

export async function confirmEmail(reset_token) {
  const response = await new ApiCall().post(
    '/api/wizville/users/confirm_email',
    {
      reset_token
    }
  )

  return response.data
}

export async function resetPassword(reset_token, new_password) {
  const response = await new ApiCall().post(
    '/api/wizville/users/reset_password',
    {
      reset_token,
      new_password,
    }
  )

  if (response.data?.sessionToken) {
    localStorage.wizvilleSessionToken = response.data?.sessionToken
  }

  return response.data
}

export async function validatePasswordResetToken(token) {
  const response = await new ApiCall().get(
    `/api/wizville/users/validate_password_reset_token?reset_token=${token}`
  )

  return response.data
}

export async function validateEmailConfirmToken(token) {
  const response = await new ApiCall().get(
    `/api/wizville/users/validate_email_confirm_token?reset_token=${token}`
  )

  return response.data
}

export async function exportCollaboratorCVS(userId, dashboardType, dashboardId, placeFilter, inactiveCollaboratorsFilter, filterDeactivatedCollaborators, selectedUserIds) {
  const params = {
    id: userId,
    dashboard_type: dashboardType,
    dashboard_id: dashboardId,
    place_filter: placeFilter,
    inactive_collaborators_filter: inactiveCollaboratorsFilter,
    filter_deactivated_collaborators: filterDeactivatedCollaborators,
    selected_user_ids: selectedUserIds
  }
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `/api/wizville/users/export_collaborators_list?${encodeGetParams}`
  )

  return response.data
}

export async function resendUserAccess(selected_user_ids) {
  const response = await new ApiCall().post(
      '/api/wizville/collaborators/resend_users_access',
      { selected_user_ids }
  )

  return response.data
}