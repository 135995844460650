import ApiCall from '@api/apiCall.js'

export async function create(params) {
  const response = await new ApiCall().post("/api/wizville/tracking_events", {
    tracking_event: params
  })

  return response.data
}

export async function update(id, params) {
  const response = await new ApiCall().put(`/api/wizville/tracking_events/${id}`, {
    tracking_event: params
  })

  return response.data
}