
export const ADVANCED_SEARCH_FEATURES = [
    {
        text: 'Veille concurrentielle',
        value: 'lrm_brand_gmb',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Classification Verbatim',
        value: 'text_analysis',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Smart benchmark',
        value: 'campaign_smart_benchmark',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Net impact score',
        value: 'campaign_net_impact_score',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Réponses intelligentes ',
        value: 'campaign_smart_answers',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Réponses types',
        value: 'conversation_pre_filled_messages',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Réponses automatisées',
        value: 'conversation_pre_filled_message_groups',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Succès',
        value: 'campaign_achievement',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'TrustVille magasin',
        value: 'trustville',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'TrustVille produit',
        value: 'trustville_product',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Avis google',
        value: 'google_brands',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Avis produit (nouvelle plateforme)',
        value: 'product_feeds',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Avis produit (ancienne plateforme)',
        value: 'product_feeds_old',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Routage URL',
        value: 'emailings',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Routage web',
        value: 'scenarios',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Multilingue',
        value: 'multilingual_questionnaires',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Questionnaire cross campagne',
        value: 'questionnaire_pages',
        selected: false,
        enabled: true,
        defaultEnabled: true
    }
]