import { render, staticRenderFns } from "./AutomatedGrades.vue?vue&type=template&id=f2cb3706"
import script from "./AutomatedGrades.vue?vue&type=script&lang=js"
export * from "./AutomatedGrades.vue?vue&type=script&lang=js"
import style0 from "./AutomatedGrades.vue?vue&type=style&index=0&id=f2cb3706&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports