import ApiCall from '@api/apiCall.js'

export async function deactivate(userIds) {
  const response = await new ApiCall().post('/api/wizville/collaborators/deactivate', {
    user_ids: userIds
  })

  return response.data
}

export async function activate(userIds) {
  const response = await new ApiCall().post('/api/wizville/collaborators/activate', {
    user_ids: userIds
  })

  return response.data
}

export async function createUsers(users) {
  const response = await new ApiCall().post('/api/wizville/collaborators/create', {
    users
  })

  return response.data
}

export async function editUser(users) {
  const response = await new ApiCall().post('/api/wizville/collaborators/edit', {
    users
  })

  return response.data

}

export async function getUser(userId) {
  const response = await new ApiCall().post(`/api/wizville/collaborators/get_user`, {
    user_id: userId
  })

  return response.data
}
