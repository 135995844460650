import WizClient from '@shared/http/wizClient'

export async function DELETEcompetitiveReport(lmCompetitiveReportId) {
  await WizClient.delete(
    `/api/local_monitor/competitive_reports/${lmCompetitiveReportId}`
  )
}

export async function POSTsendCompetitiveReport(lmCompetitiveReportId) {
  await WizClient.post(
    `/api/local_monitor/competitive_reports/${lmCompetitiveReportId}/send_competitive_report`
  )
}

export async function POSTgenerateAndSendNewCompetitiveReport(lmCompetitiveReportId) {
  await WizClient.post(
    `/api/local_monitor/competitive_reports/${lmCompetitiveReportId}/send_competitive_report`,
    { 
      reset: true
    }
  ) 
}

export async function sendCompetitiveReportDebug(lmCompetitiveReportId) {
  await WizClient.post(
    `/api/local_monitor/competitive_reports/${lmCompetitiveReportId}/send_competitive_report`,
    { 
      debug: true
    }
  )
}

export async function exportDataPoints(lmCompetitiveReportId, mode) {
  const response = await WizClient.get(`/api/local_monitor/competitive_reports/${lmCompetitiveReportId}/export_data_points?mode=${mode}`)

  return response.data.csv
}

