import WizClient from '@shared/http/wizClient'

async function createCompetitiveReport(user, locale, mainLmPlaceId, lmPlacesId) {
  const response = await WizClient.post('/api/local_monitor/',
    {
      user,
      locale,
      competitive_report: {
        main_lm_place: mainLmPlaceId,
        lm_places: lmPlacesId.concat(mainLmPlaceId)
      }
    }
  )

  return response.data
}

async function sendCompetitiveReport(users, token, firstname) {
  const response = await WizClient.post('/api/local_monitor/send_suscribers/',
    {
      users,
      token,
      firstname
    }
  )

  return response.data
}

export {
  createCompetitiveReport, sendCompetitiveReport
}
