<template>
  <v-row no-gutters class="ranking-layout">
    <v-col cols="12" class="px-bloc">
      <v-row no-gutters class="flex-nowrap align-center">
        <RankingNavigation class="ranking-navigation" :scope="scope" :rank-by="rankBy" :rankById="rankById" v-if="!fullScreenMode" />
        <w-drop-down-menu
          class="ml-auto"
          v-if="!tableLoading && !fullScreenMode"
          :items="exportOptions"
          icon="mdi-download"
        />
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <RankingTablePlace :scope="scope" v-if="isPlaceRanking" ref="table" @tableLoading="onTableLoading" />
          <RankingTableGroup :scope="scope" :key="rankById" :rank-by-id="rankById" v-if="isGroupRanking" ref="table" @tableLoading="onTableLoading" />
          <RankingTableVoterInfo :scope="scope" :key="rankById" :rank-by-id="rankById" v-if="isVoterInfoRanking" ref="table" @tableLoading="onTableLoading" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import RankingTablePlace from "./RankingTablePlace"
  import RankingTableGroup from "./RankingTableGroup"
  import RankingTableVoterInfo from "./RankingTableVoterInfo"
  import RankingNavigation from "./RankingNavigation"
  import { mapGetters } from 'vuex'
  
  export default {
    name: "Ranking",
    components: {
      RankingNavigation,
      RankingTablePlace,
      RankingTableGroup,
      RankingTableVoterInfo
    },
    props: {
      rankBy: {
        type: String,
        required: true
      },
      rankById: {
        required: true
      },
      scope: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'fullScreenMode'
      ]),
      isPlaceRanking() {
        return this.rankBy == 'places'
      },
      isGroupRanking() {
        return this.rankBy == 'group'
      },
      isVoterInfoRanking() {
        return this.rankBy == 'voter_info'
      }
    },
    methods: {
      onTableLoading(value) {
        this.tableLoading = value
      }
    },
    data() {
      return {
        tableLoading: false,
        exportOptions: []
      }
    },
    watch: {
      tableLoading() {
        this.exportOptions = [
          { title: 'PDF', onClick: this.$refs.table.exportToPdf },
          { title: 'Excel', onClick: this.$refs.table.exportToExcel }
        ]
      }
    }

  }
</script>

<style lang="stylus">
  @import '~@theme/constants.styl'

  .ranking-layout
    padding-top: $filters-height
  .ranking-navigation
    width: calc(100% - 44px)
</style>
