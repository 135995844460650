import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, params) {
  const response = await new ApiCall().post("/api/wizville/voter_closings", {
    voterUniqueId,
    voter_closing: params
  });

  return response.data
}
