<template>
  <v-file-input 
    class="mt-0 pt-0"
    :label="label" 
    v-model="file" 
    @change="loadTextFromFile($event)" 
    ref="file"
    hide-details
    clearable
  >
  </v-file-input>
</template>

<script>
export default {
  name: "WFileReader",
  props: [ 'label' ],
  data() {
    return {
      file: null
    }
  },
  methods: {
    loadTextFromFile(file) {
      if (file) {
        const reader = new FileReader()

        reader.onload = e => this.$emit("load", e.target.result)
        reader.readAsBinaryString(file)
      }
    }
  }
};
</script>
