import ApiCall from '@api/apiCall.js'

export async function create(topicsVerbatimParams) {
  const response = await new ApiCall().post("/api/wizville/topics_verbatims", {
    topics_verbatim: topicsVerbatimParams
  });

  return response.data
}

export async function update(topicsVerbatimId, topicsVerbatimParams) {
  const response = await new ApiCall().put(`/api/wizville/topics_verbatims/${topicsVerbatimId}`, {
    topics_verbatim: topicsVerbatimParams
  });

  return response.data
}

export async function destroy(topicsVerbatimId) {
  const response = await new ApiCall().delete(`/api/wizville/topics_verbatims/${topicsVerbatimId}`);

  return response.data
}
