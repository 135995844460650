<template>
  <div class="w-select-tree">
    <div v-for="(value, key) in tree" :key="key">
      <template v-if="items[`${node(key, value, tree)}_0`].value === 'allPlacesData'">
        <!-- Handle allPlacesData: Render all sublevels -->
        <div v-for="(value1, key1) in value" :key="key1" class="level allPlaceData">
          <WTreeSelectCheckbox
              :semanticPresence="false"
              :item="items[`${node(key1, value1, value)}_1`]"
              @change="toggle"
              @expand="expand"
              :level="1"
          >
            <template #label="{ item }">
              <slot name="option" :item="item">
                <span class="fs-13 c-primary">{{ item.value }}</span>
              </slot>
            </template>

            <template #expand="{ item }">
              <slot name="expand" :item="item">
              </slot>
            </template>
          </WTreeSelectCheckbox>

          <template v-if="hasSubLevel(key1, value1, value)">
            <div v-for="(value2, key2) in value1" :key="key2" class="level">
              <WTreeSelectCheckbox
                  :semanticPresence="false"
                  :item="items[`${node(key2, value2, value1)}_2`]"
                  @change="toggle"
                  @expand="expand"
                  :level="2"
              >
                <template #label="{ item }">
                  <slot name="option" :item="item">
                    <span class="fs-13 c-primary">{{ item.value }}</span>
                  </slot>
                </template>
              </WTreeSelectCheckbox>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <!-- Default behavior for all other nodes -->
        <WTreeSelectCheckbox
            :semanticPresence="semanticPresence"
            :item="items[`${node(key, value, tree)}_0`]"
            @change="toggle"
            @expand="expand"
            :level="0"
        >
          <template #label="{ item }">
            <slot name="option" :item="item" v-if="!semanticPresence">
              <span class="fs-13 c-primary">{{ item.value }}</span>
            </slot>
          </template>

          <template #expand="{ item }">
            <slot name="expand" :item="item">
              <w-icon
                  icon="map-markers"
                  class="fb-12 mr-1"
                  v-if="semanticPresence && item.value !== 'allPlacesData'"
              />
              <span
                  class="fs-13 c-primary"
                  v-if="semanticPresence && item.value !== 'allPlacesData'"
              >
                {{ $t(`group_semantics.${item.value}`) }}
              </span>
              <v-btn
                  v-if="!item.isLeaf && semanticPresence && item.value !== 'allPlacesData'"
                  icon
                  small
                  :ripple="false"
                  :color="item.active && !item.disabled ? 'white' : 'primary'"
              >
                <v-icon small>
                  {{ item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </slot>
          </template>
        </WTreeSelectCheckbox>

        <!-- Recursive logic for sublevels -->
        <template v-if="hasSubLevel(key, value, tree)">
          <div v-for="(value1, key1) in value" :key="key1" class="level">
            <WTreeSelectCheckbox
                :semanticPresence="false"
                :item="items[`${node(key1, value1, value)}_1`]"
                @change="toggle"
                @expand="expand"
                :level="1"
            >
              <template #label="{ item }">
                <slot name="option" :item="item">
                  <span class="fs-13 c-primary">{{ item.value }}</span>
                </slot>
              </template>

              <template #expand="{ item }">
                <slot name="expand" :item="item">
                </slot>
              </template>
            </WTreeSelectCheckbox>

            <template v-if="hasSubLevel(key1, value1, value)">
              <div v-for="(value2, key2) in value1" :key="key2" class="level">
                <WTreeSelectCheckbox
                    :semanticPresence="false"
                    :item="items[`${node(key2, value2, value1)}_2`]"
                    @change="toggle"
                    @expand="expand"
                    :level="2"
                >
                  <template #label="{ item }">
                    <slot name="option" :item="item">
                      <span class="fs-13 c-primary">{{ item.value }}</span>
                    </slot>
                  </template>
                </WTreeSelectCheckbox>
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import WTreeSelectCheckbox from "./WTreeSelectCheckbox"

export default {
  name: 'WSelectTree',
  props: {
    tree: {
      type: [Object, Array],
      required: true
    },
    value: {
      type: [String, Number, Array],
      required: true
    },
    searchedOptions: {
      type: [Object, Array],
      required: false
    },
    semanticPresence: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WTreeSelectCheckbox
  },
  data() {
    return {
      expandedValues: {},
      disabledValues: {},
      values: {},
      paths: {}
    }
  },
  computed: {
    searchedValues() {
      return this.searchedOptions?.reduce((h, option) => {
        h[option] = true
        return h
      }, {})
    },
    items() {
      const items = {}

      this.$helpers.tree.eachNode(this.tree, (parent, value, childs, level) => {
        items[`${value}_${level}`] = this.item(parent, value, childs)
       })

      return items
    }
  },
  watch: {
    value(value) {
      this.disabledValues = this.getDisabledValues(value)
      this.values = this.getValues(value)
    }
  },
  methods: {
    hasSubLevel(key, value, parent) {      
      const node = this.node(key, value, parent)

      return !Array.isArray(parent) && this.isExpanded(node)
    },
    node(key, value, parent) {
      return (Array.isArray(parent) ? value : key)
    },
    expand(value) {
      this.expandedValues = { ...this.expandedValues, ...{ [value]: !this.expandedValues[value] }}
    },
    toggle(newValue) {
      if (this.values[newValue]) {
        this.$emit('input', this.value?.filter(v => v != newValue))
      } else {
        const values = (Array.isArray(this.tree) || !this.paths[newValue] ? this.value : this.value?.filter(v => !this.paths[newValue][v]))
        this.$emit('input', [...(values || []), newValue])
      }
    },
    isActive(value) {
      return this.values[value] || this.disabledValues[value]
    },
    isSearched(value) {
      return this.searchedValues && this.searchedValues[value]
    },
    isVisible(value) {
      if (this.searchedValues) {
        return this.isSearched(value) || this.isExpanded(value)
      } else {
        return true
      }
    },
    isExpanded(value) {
      if (this.searchedValues) {
        const path = (this.paths ? Object.keys(this.paths[value] || {}) : [])|| []
        for (const searched of path) {
          if (this.searchedValues[searched]) {
            return true
          }
        }
      }

      return this.expandedValues[value]
    },
    isDisabled(value) {
      return this.disabledValues[value]
    },
    isLeaf(parent, value, childs) {
      return Array.isArray(parent) || childs === {} || !childs || Object.keys(childs)?.length == 0
    },
    item(parent, value, childs) {
      return {
        disabled: this.isDisabled(value),
        active: this.isActive(value),
        searched: this.isSearched(value),
        expanded: this.isExpanded(value),
        value: value,
        hidden: !this.isVisible(value),
        isLeaf: this.isLeaf(parent, value, childs)
      }
    },
    getDisabledValues(value) {
      const disabledValues = {}

      for (const baseValue of value || []) {
        for (const v of Object.keys(this.paths[baseValue] || {})) {
          disabledValues[v] = true
        }
      }

      return disabledValues
    },
    getExpandedValues(value) {
      const expandedValues = {}

      if (this.paths) {
        for (const [parent, values] of Object.entries(this.paths || {})) {
          if (Object.keys(values).some(v => value.includes(v))) {
            expandedValues[parent] = true
          }
        }
      }

      return expandedValues
    },
    getValues(value) {
      const values = {}

      for (const baseValue of value || []) {
        values[baseValue] = true
      }

      return values
    }
  },
  created() {
    this.paths = this.$helpers.tree.getPaths(this.tree)
    this.values = this.getValues(this.value)
    this.disabledValues = this.getDisabledValues(this.value)
    this.expandedValues = this.getExpandedValues(this.value)
  }
};
</script>

<style lang="stylus">
  .w-select-tree
    .v-input--selection-controls__input
      margin-right: 4px !important
  .level.allPlaceData
    margin-left: -20px!important
</style>