import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5d388e0e&scoped=true&v-if=voter.voterScore%20%7C%7C%20voter.voterScore%20%3D%3D%200"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5d388e0e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d388e0e",
  null
  
)

export default component.exports