<template>
  <svg
    @click="onClick"
    class="wiz-icon"
    :class="classObj"
    width="1em"
    height="1em"
    :style="iconStyle_"
  >
    <use :xlink:href="formatIconName" />
  </svg>
</template>

<script>
  export default {
    name: "WizIcon",
    props: {
      /**
       * Icon id
       */
      icon: {
        type: String,
        required: true
      },
      /**
       * Additional class for wiz-icon
       */
      className: {
        type: String,
        default: ''
      },
      /**
       * Styles for icons
       */
      iconStyle: {
        type: Object,
        default: null
      },
      flipHorizontally: {
        type: Boolean,
        default: false
      },
      /**
       * Flip the icon vertically
       */
      flipVertically: {
        type: Boolean,
        default: false
      },
      /**
       * Rotate the icon at 90deg
       */
      rotate90: {
        type: Boolean,
        default: false
      },
      /**
       * Rotate the icon at 180deg
       */
      rotate180: {
        type: Boolean,
        default: false
      },
      /**
       * Rotate the icon at 270deg
       */
      rotate270: {
        type: Boolean,
        default: false
      },
      /**
       * Try to apply automatic color to icon
       */
      autoColor: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      /**
       * Format icon name with #<icon_name>
       */
      formatIconName() {
        const { icon } = this
        return '#' + icon
      },
      iconStyle_() {
        const { iconStyle, autoColor, icon } = this
        if (autoColor === false) { return iconStyle }

        let color = null

        switch (icon) {
          case 'wizville':
            color = '#65C095'
            break
          case 'google':
            color = '#4285F4'
            break
          case 'facebook':
            color = '#3B5998'
            break
          case 'pages_jaunes':
            color = '#EDCA01'
            break
          case 'bazaar_voice':
            color = '#EDCA01'
            break
          case 'trip_advisor':
            color = '#00af87'
            break
        }
        if (color === null) { return iconStyle }

        return Object.assign({}, { color }, iconStyle)
      },
      classObj() {
        const {
          icon,
          className,
          flipHorizontally,
          flipVertically,
          rotate90,
          rotate180,
          rotate270
        } = this

        return {
          [`wiz-icon-${icon}`]: true,
          [className]: !!className,
          'icon-flip-horizontal': flipHorizontally,
          'icon-flip-vertical': flipVertically,
          'icon-rotate-90': rotate90,
          'icon-rotate-180': rotate180,
          'icon-rotate-270': rotate270
        }
      }
    },
    methods: {
      onClick(el) {
        this.$emit('click', el)
      }
    }
  }
</script>

<style lang="stylus">
  // Place here the default values for the css variables used in svg icons
  #star-half
    --star-half-empty-color: #CBCBCB

</style>

<style lang="stylus" scoped>
  .wiz-icon
    display: inline-flex
    vertical-align: middle
    position: relative
    top: -1px

    &.icon-flip
      &-horizontal
        transform: scaleX(-1)
        filter: FlipH
      &-vertical
        transform: scaleY(-1)
        filter: FlipV
      &-both
        transform: scale(-1, -1)

    &.icon-rotate
      &-90
        transform: rotate(90deg)
      &-180
        transform: rotate(180deg)
      &-270
        transform: rotate(270deg)

</style>
