<template>
  <v-data-table   
    class="v-datatable-without-pagination"
    :headers="headers"
    :items="formatedTransactions"
    :group-by="groupBy"
    @click:row="showDetails"
    sort-by="startedAt"
    height="calc(50vh - 60px)"
    disable-pagination
    fixedHeader
    dense
  >
    <template #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length" class="text-align-left pl-3">
          <v-badge :color="statusColor(statuses[group])" :content="statuses[group]" v-if="groupBy === 'statusIdx'" />
          <span v-else-if="groupBy === 'typeIdx'">{{ types[group] }}</span>
          <span v-else="">{{ group }}</span>
        </td>
    </template>

    <template #header.name="{ header }">
      <span @click="groupBy = (groupBy === 'parent' ? null : 'parent')" class="pointer">
        {{ header.text }}
      </span>
    </template>
    
    <template #header.status="{ header }">
      <span @click="groupBy = (groupBy === 'statusIdx' ? null : 'statusIdx')" class="pointer">
        Status
      </span>
    </template>

    <template #header.type="{ header }">
      <span @click="groupBy = (groupBy === 'typeIdx' ? null : 'typeIdx')" class="pointer">
        Type
      </span>
    </template>

    <template #item.status="{ value, item }">
      <v-badge :color="item.statusColor" :content="value" />
    </template>

    <template #item.name="{ value , item}">
      <v-icon v-if="item.active" small class="bottom_1px">mdi-star</v-icon>
      <span :class="{ active: item.active }" class="pointer">{{ value }}</span>
    </template>

    <template #item.startedAt="{ value }">
      {{ Math.floor(value) }}m ago
    </template>
    
    <template #item.duration="{ value }">
      {{ (-value / 1000).toFixed(1) }}s
    </template>

    <template v-slot:footer.prepend>
      <div class="d-flex flex-row-reverse full-width ml-3">
        <v-btn small icon @click="$emit('clear')" class="text-align-right"><v-icon>mdi-cancel</v-icon></v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
  import _sortBy from 'lodash/sortBy'

  export default {
    name: 'WProfilerSheet',
    props: [ 'transactions', 'value', 'heartBeat'],
    data() {
      return {
        headers: [
          { text: 'Type', value: 'type', width: "120px", sortable: false, cellClass: 'pointer nowrap pl-3' },
          { text: 'Source', value: 'name', width: "200px", sortable: false, cellClass: 'pointer nowrap' },
          { text: 'Status', value: 'status', width: "100px", sortable: false, cellClass: 'pointer' },
          { text: 'Duration', value: 'duration', width: "100px", cellClass: 'pointer' },
          { text: 'At', value: 'startedAt', cellClass: 'pointer' },
          { text: 'Page', value: 'location'  }
        ],
        groupBy: "typeIdx",
        activeRow: null,
      }
    },
    computed: {
      formatedTransactions() {
        const heartBeat = this.heartBeat

        const transactions = this.transactions.map(transaction => {
          return {
            startedAt: (new Date().getTime() - transaction.startedAt) / 1000 / 60,
            endedAt: (new Date().getTime() - transaction.startedAt) / 1000 / 60,
            pathname: transaction.pathname,
            typeIdx: this.types.indexOf(transaction.type),
            type: transaction.type,
            name: transaction.name,
            parent: transaction.name.split(".")[0],
            status: transaction.status,
            statusIdx: this.statuses.indexOf(transaction.status),
            statusColor: this.statusColor(transaction.status),
            duration: -(transaction.duration || new Date().getTime() - transaction.startedAt),
            context: transaction.context,
            result: transaction.result,
            transaction: transaction,
            location: transaction.pathname,
            active: transaction.transactionId === this.activeRow?.transaction?.transactionId
          }
        })
        
        return _sortBy(transactions, (t) => -t.startedAt)
      },
      statuses() {
        return ['running', 'ko', 'ok']
      },
      types() {
        return ['context', 'api', 'based']
      }
    },
    methods: {
      statusColor(status) {
        if (status === 'ok') {
          return 'success'
        } else if (status === 'ko') {
          return 'error'
        } else {
          return 'grey'
        }
      },
      showDetails(row) {
        if (this.activeRow?.transaction?.transactionId === row?.transaction?.transactionId) {
          this.activeRow = null
          this.$emit('transaction', null)
        } else {
          this.activeRow = row
          this.$emit('transaction', row.transaction)
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .active
    font-weight: bold
</style>
