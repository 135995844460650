<template>
  <div
    class="statistics-navigation pl-sm-1"
    :class="{ 'shadow-bottom': showShadow }"
  >
    <w-nav-tabs
      :router-links-properties="routerLinksProperties"
      scrollable
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _upperFirst from 'lodash/upperFirst'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
  import NisMixin from '@statistics/shared/nis_mixin'
  import DocumentScrollMixin from '@shared/mixins/document_scroll'

  export default {
    name: "StatisticsNavigation",
    props: {
      dashboardStatsPages: {}
    },
    mixins: [
      WidgetMixin,
      NisMixin,
      DocumentScrollMixin
    ],
    data() {
      return {
        titles: {}
      }
    },
    computed: {
      ...mapGetters([
        'currentDashboardPublicPlatforms',
        'isDemoUser',
        'currentDashboardScopedCampaigns',
        'currentDashboardCampaignIds',
        'mainWizvilleCampaignWithTopics',
        'currentUserIsAdmin',
        'isDashboardMono',
        'currentDashboardInsatisfactionNotificationEnabled',
        'hasAccessToNewPlatformStatisticVerbatimAnalysis'
      ]),
      showShadow() {
        return this.documentScroll_isScrolling || this.$vuetify.breakpoint.xsOnly
      },
      nbDashboardStatsPages() {
        return this.dashboardStatsPages?.length || 0
      },
      routerLinksProperties() {
        let routerLinksProperties = []

        for (const dashboardStatsPage of this.dashboardStatsPages) {
          routerLinksProperties.push(
            {
              title: this.dashboardStatsPagesName(dashboardStatsPage),
              active: this.$route.params.sectionType === 'summary',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'summary',
                  sectionId: dashboardStatsPage.position
                }
              }
            }
          )
        }

        if (this.mainWizvilleCampaignWithAvgScore) {
          routerLinksProperties.push(
            {
              title: this.$t('customer_satisfaction'),
              active: this.$route.params.sectionType === 'satisfaction',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'satisfaction',
                  sectionId: 'default',
                  subSectionId: 'default'
                }
              }
            }
          )
        }

        if (this.mainCampaignWithNpsQuestion) {
          routerLinksProperties.push(
            {
              title: this.isLcl ? "Indicateur Recommandation Client" : "Net Promoter Score",
              active: this.$route.params.sectionType === 'nps',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'nps',
                  sectionId: 'default',
                  subSectionId: 'default'
                }
              }
            }
          )
        }

        if (this.currentDashboardPublicPlatforms.length > 0) {
          const title = this.currentDashboardPublicPlatforms.length > 1 ?
            this.$t('public_platforms') :
            this.$t(`source_${this.currentDashboardPublicPlatforms[0]}`)
          routerLinksProperties.push(
            {
              title: title,
              active: this.$route.params.sectionType === 'social',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'social',
                  sectionId: this.currentDashboardPublicPlatforms[0]
                }
              }
            }
          )
        }

        if (this.mainWizvilleCampaignWithTopics) {
          routerLinksProperties.push(
            {
              title: this.$t('topics'),
              active: this.$route.params.sectionType === 'topics',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'topics',
                  sectionId: 'default',
                  subSectionId: this.mainWizvilleCampaignWithTopics.id
                }
              }
            }
          )
        }

        if (this.shouldDisplayNisPage) {
          routerLinksProperties.push(
            {
              title: this.$t('net_impact_score'),
              active: this.$route.params.sectionType === 'nis',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'nis',
                  sectionId: 'default',
                  subSectionId: this.nisMixin_eligibleCampaigns[0].id
                }
              }
            }
          )
        }

        if (this.shouldDisplayFeedbackTreatmentPage) {
          routerLinksProperties.push(
            {
              title: this.$t('feedbackTreatmentIndicators'),
              active: this.$route.params.sectionType === 'feedbackTreatment',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'feedbackTreatment',
                  subSectionId: this.currentDashboardCampaignIds[0]
                }
              }
            }
          )
        }

        if (this.mainCampaignWithQcmQuestion) {
          routerLinksProperties.push(
            {
              title: this.$t('questions'),
              active: this.$route.params.sectionType === 'questions',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'questions',
                  sectionId: 'all',
                  subSectionId: this.mainCampaignWithQcmQuestion.id
                }
              }
            }
          )
        }

        if (this.mainCampaignWithVerbatimAnalysisEnabled &&
            this.hasAccessToNewPlatformStatisticVerbatimAnalysis
        ) {
          routerLinksProperties.push(
            {
              title: this.$t('feeling'),
              active: this.$route.params.sectionType === 'verbatim',
              route: {
                name: 'Statistics',
                params: {
                  sectionType: 'verbatim',
                  sectionId: 'all',
                  subSectionId: this.mainCampaignWithVerbatimAnalysisEnabled.id
                }
              }
            }
          )
        }

        return routerLinksProperties
      },
      mainCampaign() {
        return this.currentDashboardScopedCampaigns[0]
      },
      mainCampaignWithNpsQuestion() {
        return this.currentDashboardScopedCampaigns
          .find((campaign) => campaign.hasNpsQuestion)
      },
      mainCampaignWithVerbatimAnalysisEnabled() {
        return this.currentDashboardScopedCampaigns
          .find((campaign) => campaign.hasVerbatimAnalysisEnabled || campaign.hasTextAnalysisEnabled)
      },
      shouldDisplayNisPage() {
        return this.nisMixin_canUseNisFeature(this.nisMixin_eligibleCampaigns, false)
      },
      shouldDisplayFeedbackTreatmentPage() {
        return (this.isDemoUser || this.currentUserIsAdmin) &&
          this.isDashboardMono && this.currentDashboardInsatisfactionNotificationEnabled
      },
      mainCampaignWithQcmQuestion() {
        return this.currentDashboardScopedCampaigns.find((campaign) => campaign.hasQcmQuestion)
      },
      mainWizvilleCampaignWithAvgScore() {
        return this.currentDashboardScopedCampaigns.find((campaign) => campaign.hasAvgScore && campaign.sources.includes('wizville'))
      }
    },
    methods: {
      dashboardStatsPagesName(dashboardStatsPage) {
        let title = ''
        if (dashboardStatsPage.title) {
          title = dashboardStatsPage.title
        } else {
          title = this.$t('dashboard_stats_page_default_title')
        }

        this.titles[title] = (this.titles[title] ? 0 : this.titles[title] + 1)
        if (this.titles[title] > 0) {
          title = `${title} ${this.titles[title]}`
        }

        return title
      },
    }
  }
</script>

<style lang="stylus">
  .statistics-navigation
    width: 100%
    background-color: var(--bodygrey)
    &.shadow-bottom
      box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, .2)
</style>
