import WizClient from "@shared/http/wizClient"
import fileDownload from "js-file-download"
import contentDisposition from "content-disposition"

async function downloadExport(params = {}) {
  const response = await WizClient.post("/billings/export", params, {
    responseType: "blob"
  })

  let filename = contentDisposition.parse(
    response.headers["content-disposition"]
  ).parameters.filename

  fileDownload(response.data, filename)

  return response
}

export { downloadExport }
