<template>
  <div style="width: 100%">
    <h2
      v-if="!!title"
      class="text-f10"
    >
      {{ title }}
    </h2>

    <Chart :options="chartOptions" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

const DEFAULT_COLORS = [
  "#FFB800",
  "#DB3572",
  "#88C788",
  "#BA0022",
  "#2E1C4D",
  "#C48754"
]

export default {
  components: { Chart },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    categories: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    }
  },
  computed: {
    chartOptions () {
      let options = {
        chart: {
          type: 'bar'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            tickLength: 0
          },
          alternateGridColor: '#f9fafb',
          categories: this.categories,
          lineColor: 'transparent',
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          },
        },
        yAxis: {
          min: 0,
          title: ""
        },
      }

      let series = this.series.map ( (s, index) => {
        return { ...s, color: this.colors[index] }
      })

      return { ...options, series }
    }
  }
}
</script>

<style lang='stylus' scoped>
</style>
