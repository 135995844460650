<template>
  <v-select
    class="w-locale-select"
    v-model="selectedLocale"
    :items="locales"
    :label="label"
    outlined
    dense
    :hide-details="hideDetails"
    @change="updateValue"
  >
    <template v-slot:selection="data">
      <country-flag
        class="w-locale-select-flag"
        :country="data.item.flag || ''"
        :rounded="false"
      />
      <span>{{ data.item.text }}
        <template v-if="!!customMessages[data.item.value]">&nbsp;({{ customMessages[data.item.value] }})</template>
      </span>
    </template>

    <template v-slot:item="data">
      <country-flag
        :class="`w-locale-select-flag item-${data.item.flag}`"
        :country="data.item.flag || ''"
        :rounded="false"
      />
      <span>{{ data.item.text }}
        <template v-if="!!customMessages[data.item.value]">&nbsp;({{ customMessages[data.item.value] }})</template>
      </span>
    </template>
  </v-select>
</template>

<script>
import _find from "lodash/find"
import CountryFlag from 'vue-country-flag'

export default {
  components: {
    CountryFlag
  },
  props: {
    value: {
      type: String,
      required: false
    },
    locales: {
      type: Array,
      required: false,
      default: () => {
        return [
          // See `https://github.com/stevenrskelton/flag-icon/tree/master/gif` and
          // `https://en.wikipedia.org/wiki/ISO_3166-1` to search for the right country codes.
          { text: "Allemand", value: "de", flag: "de" },
          { text: "Anglais", value: "en", flag: "gb" },
          { text: "Anglais (US)", value: "en_US", flag: "us" },
          { text: "Arabe", value: "ar", flag: "sa" },
          { text: "Canadien", value: "fr_CA", flag: "ca" },
          { text: "Espagnol", value: "es", flag: "es" },
          { text: "Flamand", value: "fl", flag: "be" },
          { text: "Français", value: "fr", flag: "fr" },
          { text: "Grec", value: "el", flag: "gr" },
          { text: "Italien", value: "it", flag: "it" },
          { text: "Néerlandais", value: "nl", flag: "nl" },
          { text: "Polonais", value: "pl", flag: "pl" },
          { text: "Portugais", value: "pt", flag: "pt" },
          { text: "Roumain", value: "ro", flag: "ro" },
          { text: "Russe", value: "ru", flag: "ru" },
          { text: "Slovaque", value: "sk", flag: "sk" },
          { text: "Tchèque", value: "cs", flag: "cz" },
          { text: "Ukrainien", value: "uk", flag: "ua" },
        ]
      }
    },
    label: {
      type: String,
      required: false
    },
    hideDetails: {
      type: Boolean,
      required: false
    },
    customMessages: {
      type: Object,
      default: () => { return {} }
    }
  },
  methods: {
    setLocale() {
      let locale = this.value ? this.value : "fr"

      this.selectedLocale = _find(this.locales, { value: locale })
      this.updateValue(locale)
    },
    updateValue(value) {
      this.$emit("input", value)
    }
  },
  created() {
    this.setLocale()
  },
  updated() {
    this.setLocale()
  }
}
</script>

<style lang="stylus">
  .w-locale-select .v-select__slot .v-select__selections .w-locale-select-flag
    margin: -0.5em -.5em -.6em -.7em

  .v-list .v-list-item .w-locale-select-flag
    margin: -0.5em -.5em -.6em -.7em
</style>
