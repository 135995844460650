<template>
  <v-menu
    content-class="sub-nav-menu"
    v-if="properties.childNav"
    bottom
    offset-y
    :disabled="!properties.isActive"
    eager
    :key="reload"
  >
    <template v-slot:activator="{ on, attrs }">
      <Link
        :properties="{ ...properties, ...{ on, attrs, subNavTitle, subNavBadge } }"
        :replaceTitle="replaceTitle"
      />
    </template>

    <v-list>
      <v-list-item
        v-for="(childNav, index) in properties.childNav"
        v-if="!childNav.shouldHide"
        :key="index"
        dense
      >
        <router-link
          :to="{ name: childNav.route.name, params: childNav.route.params, query: childNav.route.query }"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
        >
          <Link
            :properties="{ ...childNav, ...{ href, navigate, isActive: computeSubNavActive(childNav, isActive), isSubNav: true, index: index } }"
            @activated=subNavActivated
          />
        </router-link>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import Link from './Link'

  export default {
    name: "LinkWithSubNav",
    components: {
      Link
    },
    data() {
      return {
        activeSubNav: undefined,
        reload: 0
      }
    },
    props: {
      properties: {
        type: Object,
        required: true
      },
      replaceTitle: {
        type: false,
        required: false,
        default: true
      }
    },
    methods: {
      subNavActivated(index) {
        this.activeSubNav = this.properties.childNav[index]
      },
      computeSubNavActive(nav, isActive) {
        if (this.properties.isActive) {
          if (nav.default && (this.activeSubNav === undefined || this.activeSubNav === null)) {
            return true
          }
          if (nav.className === this.activeSubNav?.className) {
            return true
          }

          return isActive
        }
        return false
      }
    },
    computed: {
      subNavTitle() {
        return this.activeSubNav && !this.activeSubNav.default ?
          this.activeSubNav.title :
          null
      },
      subNavBadge() {
        return this.activeSubNav && !this.activeSubNav.default ?
          this.activeSubNav.optionEls?.badge :
          null
      }
    },
    watch: {
      properties: {
        handler(newValue) {
          this.reload += 1
          if (!newValue.isActive) {
            this.activeSubNav = null
          }
        },
        deep: true
      }
    }
  }
</script>

<style lang="stylus">
  .sub-nav-menu
    .v-list-item--dense
      min-height: 30px
</style>
