<template>
  <v-form ref="form" v-model="valid" class="w-form-layout">
    <slot name="header">
      <div class="w-form-header">
      </div>
    </slot>
    
    <div class="w-form-content header">
      <slot name="content">
      </slot>
    </div>

    <slot name="footer">
      <div class="w-form-footer py-3">
        <div class="w-form-footer-left">
          <slot name="footer-left">
          </slot>
        </div>

        <div class="w-form-footer-right">
          <slot name="footer-right">
          </slot>
        </div>
      </div>
    </slot>
  </v-form>
</template>

<script>
  export default{
    name: "WFormLayout",
    props: [ 'value' ],
    computed: {
      valid: {
        get()  { 
          return this.value
        },
        set(valid) { 
          this.$emit('input', valid)
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-form-layout
    background-color: white

    .w-form-footer
      border-top: 1px solid #E5E7E8
      position: sticky
      bottom: 0px
      z-index: 10
      background-color: white
      display: flex
      justify-content: space-between
      align-items: center
</style>