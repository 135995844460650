<template>
  <router-link
    :to="{ name: routerLinkProperty.route.name, params: routerLinkProperty.route.params, query: routerLinkProperty.route.query }"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
  >
    <div
      :class="[[{ 'link-active': routerLinkProperty.active || isActive }, 'nav-tabs-link d-flex align-center']]"
    >
      <LinkWithSubNav
        v-if="routerLinkProperty.childNav"
        :properties="{ ...routerLinkProperty, ...{ href, navigate, isActive: routerLinkProperty.active || isActive } }"
        :replaceTitle="replaceTitle"
      />

      <Link
        v-else
        :properties="{ ...routerLinkProperty, ...{ href, navigate, isActive: routerLinkProperty.active || isActive } }"
      />
    </div>
  </router-link>
</template>

<script>
  import Link from './Link'
  import LinkWithSubNav from './LinkWithSubNav'

  export default {
    name: "Nav",
    components: {
      Link,
      LinkWithSubNav
    },
    props: {
      routerLinkProperty: {
        type: Object,
        required: true
      },
      replaceTitle: {
        type: false,
        required: false,
        default: true
      }
    }
  }
</script>

<style lang="stylus">
</style>
