<template>
  <div
    class="d-flex flex-column"
    style="height: calc(100% - 39px)"
  >
    <div
      v-scroll.self="onSurveyScroll"
      class="flex-grow-0 flex-shrink-1 overflow overflow-auto pa-4"
      :style="voterSurveyComponents.style"
    >
      <template v-for="componentName in voterSurveyComponents.components">
        <VoterSurvey
          v-if="componentName === 'survey'"
          :voter="voter"
          :product="product"
          :selectedVoterSurvey="selectedVoterSurvey"
          :fromLanguage="fromLanguage"
          :gBrandName="gBrandName"
          :fieldsCustomization="fieldsCustomization"
          :prospectFields="prospectFields"
        />
        <VoterTimeline
          v-if="componentName === 'timeline'"
          :voter="voter"
          ref="timeline"
          class="my-10"
        />
      </template>
    </div>
    <div
      class="d-flex flex-column flex-grow-0 flex-shrink-0 mt-auto"
      :class="{ 'px-4': $vuetify.breakpoint.mdAndDown }"
    >
      <VoterAction
        v-if="actionTabsReady"
        v-show="$vuetify.breakpoint.mdAndUp"
        :voter="voter"
        :actions="actions"
        :section="section"
        :gBrandName="gBrandName"
        :fieldsCustomization="fieldsCustomization"
        :prospectFields="prospectFields"
        :smartAnswer="smartAnswer"
        class="flex-grow-0 flex-shrink-0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cloneDeep from "lodash/cloneDeep"

import VoterHeader from '../VoterHeader'
import VoterSurvey from '../VoterSurvey'
import VoterTimeline from '../VoterTimeline'
import VoterAction from '../VoterAction'

import VoterMixin from '../../voter_mixin'
import InsatisfactionMixin from '../../insatisfaction_mixin'

export default{
  name: "VoterReviewSection",
  mixins: [
    VoterMixin,
    InsatisfactionMixin
  ],
  components: {
    VoterHeader,
    VoterSurvey,
    VoterTimeline,
    VoterAction
  },
  props: {
    gBrandName: { required: true },
    fieldsCustomization: { required: true },
    prospectFields: { required: true },
    actions: { required: true },
    section: { required: true },
    smartAnswer: { type: String },
  },
  data: () => ({
    voterSurvey: null,
    voterSurveyTranslated: null,
    fromLanguage: null,
    isTranslated: false,
    answersToTranslate: [],
    product: null,
  }),
  computed: {
    ...mapGetters([
      'currentVoterSmartAnswersEnabled',
      'currentUserLocale',
      'currentUserFeedbackContent'
    ]),
    // Reset the survey only when the loading could be slow
    shouldResetSurvey() {
      return (
        this.currentUser.locale != this.voter.language ||
        this.currentDashboardType === "DashboardMulti"
      )
    },
    actionTabsReady() {
      return this.insatisfaction !== undefined
    },
    voterSurveyComponents() {
      const components = ['survey', 'timeline']
      return this.$route.query.anchor !== 'timeline' && !this.voterNeedsMessageReview
        ? { components }
        : {
            style: 'display: flex; flex-direction: column-reverse;',
            components: components.reverse(),
          }
    },
    selectedVoterSurvey() {
      if ((this.currentUserFeedbackContent === "translated" || this.currentUserFeedbackContent === null) &&
        this.voter.language != this.currentUserLocale
      ) {
        return this.isTranslated ? this.voterSurveyTranslated : null
      } else {
        return this.voterSurvey
      }
    }
  },
  methods: {
    updateVoterSurveyTranslated(translatedAnswers) {
      translatedAnswers.forEach((translatedAnswer) => {
        if (translatedAnswer.text) {
          const translatedContent = translatedAnswer.text.replaceAll('&#39;', "'").replaceAll('&quot;', '"')
          const answer = this.voterSurveyTranslated.answers.find((answer) => answer.id == translatedAnswer.key)
          if (answer.id == translatedAnswer.key) {
            if (answer.subtype == "ProductsQuestionAnswer") {
              answer.productQuestionAnswer.commentQuestionAnswer = translatedContent
            } {
              switch (answer.type) {
              case "QcmQuestionAnswer":
                answer.questionCommentAnswers[0].text = translatedContent
                break
              case "FreeQuestionAnswer":
                answer.freeAnswer = translatedContent
                break
              }
            }
          }
        }
      })

      this.isTranslated = true
    },
    async translateAnswers() {
      try {
        const response = await this.$api.wizville.translations.translate_in_hashes(
          this.answersToTranslate,
          'text',
          this.fromLanguage,
          this.currentUserLocale
        )

        this.fromLanguage = this.fromLanguage || response.detectedFromLocale

        if (response.status === "ok") {
          this.updateVoterSurveyTranslated(response.translatedContent)
        }
      } catch (e) {
        console.error(e)
        this.updateVoterSurveyTranslated(this.answersToTranslate)
      }
    },
    shouldTranslate() {
      const isTranslatable = this.fromLanguage &&
                             this.currentUserLocale !== this.fromLanguage &&
                             this.answersToTranslate.length &&
                             this.voterSurveyTranslated
      if (!isTranslatable)
        this.isTranslated = true

      return isTranslatable
    },
    filteredAnswersToTranslate() {
      const supportedTypes = ["QcmQuestionAnswer", "FreeQuestionAnswer"]

      return this.voterSurvey
                 .answers
                 .filter(answer => (supportedTypes.includes(answer.type) || answer.subtype == "ProductsQuestionAnswer"))
                 .map(answer => {
                   let text
                   if (answer.subtype == "ProductsQuestionAnswer"){
                    text = answer?.productQuestionAnswer?.commentQuestionAnswer
                   } else {
                     switch (answer.type) {
                      case "QcmQuestionAnswer":
                       if (answer.questionCommentAnswers) {
                         text = answer.questionCommentAnswers[0]?.text
                       }
                       break
                     case "FreeQuestionAnswer":
                       text = answer.freeAnswer
                       break
                     }
                   }
                   return { key: answer.id, text: text }
                 })
                 .filter(answer => answer.text != null)
    },
    onSurveyScroll() {
      if (this.currentVoterShowedActions &&
        !this.currentVoterIsActionSubMenuOpened
      ) {
        this.$store.dispatch('setShowVoterActions', false)
      }
    },
    async loadVoterSurvey() {
      const request = this.$basedRequest()
        .where({
          unique_id: this.voter.uniqueId,
        })
        .select({
          voters: ['voter_survey']
        })

      this.voterSurvey  = (await this.$resolve(request)).first()?.voterSurvey
    },
    onSurveyLoaded() {
      this.isTranslated = false
      this.voterSurveyTranslated = cloneDeep(this.voterSurvey)
      this.answersToTranslate = this.filteredAnswersToTranslate()
      const productAnswers = this.voterSurvey.answers.filter( answer => answer.product )
      this.product = productAnswers.length > 0 ? productAnswers[0].product : null
    }
  },
  watch: {
    voterSurvey() {
      if (this.voterSurvey != null) {
        if (this.voter.id) {
          this.fromLanguage = this.voter.language
          this.onSurveyLoaded()
        }
        if (this.shouldTranslate()) {
          this.translateAnswers()
        }
      }
    },
    'voter.id': {
      handler(value) {
        if (this.voter.id) {
          if (this.shouldResetSurvey){
            this.voterSurvey = null
            this.voterSurveyTranslated = null
          }
          this.loadVoterSurvey()
        }
      }
    }
  },
  mounted() {
    this.loadVoterSurvey()
  }
}
</script>
