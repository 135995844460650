<template>
  <div class="c-primary fb-13 ml-auto">
    <span>
      <span
        v-if="isLanguageSupported(value) && countryCode(value) !== 'no-flag'"
        :style="{ display: 'inline-block', height: '19px', verticalAlign: 'middle' }"
      >
        <CountryFlag
          :country="countryCode(value)"
          :rounded="false"
          size="small"
        />
      </span>
      <span class="mr-2">
        {{ displayedValue }}
      </span>

      <span style="display:inline-block">
        <w-copy-button
          :value="displayedValue"
        >
          <template v-slot:copiedContent>
            <v-icon
              small
              class="c-oslo-gray"
            >
              mdi-check
            </v-icon>
          </template>
          <template v-slot:copyContent>
            <v-icon
              small
              class="c-oslo-gray"
            >
              mdi-content-copy
            </v-icon>
          </template>
        </w-copy-button>
      </span>
    </span>
  </div>
</template>

<script>
import { LANGUAGES_NAME } from '@i18n/setup'
import CountryFlag from 'vue-country-flag'

const NO_FLAG = 'no-flag'

export default {
  name: 'RowValue',
  components: {
    CountryFlag
  },
  props: {
    value: { required: true },
    valueType: { type: String, default: '' },
  },
  computed: {
    displayedValue() {
      if (this.valueType === 'datetime') {
        return this.$helpers.dates.short(this.value, this.$helpers.dates.hasTime(this.value))
      } else if (this.isLanguageSupported(this.value)) {
        return this.$t(`languages.${this.value}`)
      } else {
        return this.value
      }
    }
  },
  methods: {
    isLanguageSupported(value) {
      return !!Object.keys(LANGUAGES_NAME).includes(value)
    },
    countryCode(locale) {
      // See `https://github.com/stevenrskelton/flag-icon/tree/master/gif` and
      // `https://en.wikipedia.org/wiki/ISO_3166-1` to search for the right country codes.
      // When you don't want a flag to be displayed, use `NO_FLAG`.
      const mapping = {
        'ar': NO_FLAG,
        'da': 'dk',
        'en': 'gb',
      }
      return mapping[locale] || locale
    }
  }
}
</script>
