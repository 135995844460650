<template>
  <div class="public-platforms">
    <PublicPlatformsNavigation
      platform="platform"
    />
    <Google v-if="platform == 'google'"/>
    <Facebook v-if="platform == 'facebook'"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PublicPlatformsNavigation from './PublicPlatformsNavigation'
  import Google from './Google'
  import Facebook from './Facebook'

  export default {
    name: "PublicPlatforms",
    components: {
      PublicPlatformsNavigation,
      Google,
      Facebook
    },
    props: {
      platform: {
        required: false,
        type: String
      }
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
      ]),
    }
  }
</script>

<style lang="stylus" scoped>
</style>
