var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WStatsWidget',{staticClass:"widget-evolution",attrs:{"cols":12,"justifyCenter":false,"title":_vm.$t(_vm.title),"contentPadding":"0.75em","contentWidth":"100%"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('HeaderSubtitle')]},proxy:true},{key:"options",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('WidgetSettingsMenu',{attrs:{"competitorScope":_vm.competitorScope,"competitorMode":_vm.competitorMode,"periodType":_vm.periodType,"campaignSmartBenchmarkPreferenceId":_vm.campaignSmartBenchmarkPreferenceId}}),_vm._v(" "),_c('w-drop-down-menu',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"ml-auto",attrs:{"data-html2canvas-ignore":"","items":_vm.exportItems,"icon":"mdi-download"}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('WTimelineChart',{key:_vm.requestObj['id'],ref:"timelineChart",staticClass:"pb-3",attrs:{"connectNulls":_vm.periodType === 'genesis',"quarterOffset":_vm.currentUser.quarterOffset,"tooltipContent":_vm.tooltipContent,"defaultOptions":{
        periodFrom: _vm.datesScope.dateBegin,
        periodTo: _vm.datesScope.dateEnd,
        request: _vm.requestObj,
        tickUnit: _vm.dashboardFilterUnit,
        to: _vm.datesScope.dateEnd,
        periodNumber: 6,
        tickInterval: 1,
        min: 1,
        max: 5.1
      },"type":"line"},on:{"loadingStatusChanged":_vm.changeLoadingStatus}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }