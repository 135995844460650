import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, internalMemoParams) {
  const response = await new ApiCall().post("/api/wizville/internal_memos", {
    voterUniqueId,
    internal_memo: internalMemoParams
  });

  return response.data
}

export async function update(voterUniqueId, internalMemoUniqueId, internalMemoParams) {
  const response = await new ApiCall().put(`/api/wizville/internal_memos/${internalMemoUniqueId}`, {
    voterUniqueId,
    internal_memo: internalMemoParams
  });

  return response.data
}

export async function destroy(voterUniqueId, internalMemoUniqueId) {
  const response = await new ApiCall().delete(`/api/wizville/internal_memos/${internalMemoUniqueId}`, {
    // Need to wrap params into a data object so that axios can send it in a DELETE request
    // https://github.com/axios/axios/issues/736
    data: {
      voterUniqueId
    }
  });

  return response.data
}
