export default class IconToBase64Exporter {
  constructor() {
    this.alreadyExportedIcons = {};
  }

  iconToBase64(iconName, color) {
    return new Promise(async (resolve, reject) => {
      const alreadyExportedIconInfo = this.getAlreadyExportedIconInfo(iconName, color);

      if (alreadyExportedIconInfo) {
        resolve(alreadyExportedIconInfo);
      } else {
        try {
          const icons = await import(/* webpackChunkName: "@mdi/js" */ "@mdi/js");
          const width = 24;
          const height = 24;
          let canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.strokeStyle = color;
          ctx.fillStyle = color;
          ctx.lineWidth = 0.1;
          const path = new Path2D(icons[iconName]);
          ctx.stroke(path);
          ctx.fill(path);
          const dataURL = canvas.toDataURL('image/png');
          canvas = null;
          this.saveAlreadyExportedIcon(iconName, color, [dataURL, width, height]);
          resolve([dataURL, width, height]);
        } catch(e) {
          console.log(e);
          reject(new Error("Failed to load Icon"))
        }
      }
    })
  }

  saveAlreadyExportedIcon(iconName, color, info) {
    const key = this.buildIconKey(iconName, color);

    this.alreadyExportedIcons = { ...this.alreadyExportedIcons, ... { [key]: info } };
  }
  getAlreadyExportedIconInfo(iconName, color) {
    const key = this.buildIconKey(iconName, color);

    return this.alreadyExportedIcons[key];
  }

  buildIconKey(iconName, color) {
    return [iconName, color].join('-');
  }
}
