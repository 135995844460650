<template>
  <v-snackbar
    :key="snackbar.message"
    @input="updateSnackbar"
    :value="snackbar.display"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiLine"
    :top="snackbar.top"
    :bottom="snackbar.bottom"
    :right="snackbar.right"
    :left="snackbar.left"
    :vertical="snackbar.vertical"
    :absolute="snackbar.absolute"
  >
    <div class="d-flex flex-row">
      <span class="text-left mt-1">
        <v-icon v-if="snackbar.icon" color="white" class="pr-2">{{ snackbar.icon }}</v-icon>
        <span class="subtitle-2" style="line-height: 24px; position: relative; top: 2px;" v-html="snackbar.message" />
      </span>
      <v-btn class="ml-auto" dark icon @click="updateSnackbar(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
  import { mapGetters } from "vuex"

  export default {
    name: 'WSnackBar',
    computed: {
      ...mapGetters([
        'snackbar'
      ])
    },
    methods: {
      updateSnackbar(value) {
        this.$store.dispatch('updateSnackbarDisplay', value)
      }
    }
  }
</script>

<style lang="stylus">
</style>
