import ApiCall from '@api/apiCall.js'

export async function refreshBrand() {
  const response = await new ApiCall().post('/api/wizville/facebook/refresh_brand')

  return response.data
}

export async function createFacebookBrand(params) {
  const response = await new ApiCall().post('/api/wizville/facebook/create_facebook_brand',
    params
  )

  return response.data
}

export async function destroyFacebookBrand(facebookBrandId) {
  const response = await new ApiCall().post('/api/wizville/facebook/destroy_facebook_brand',
    {
      facebook_brand_id: facebookBrandId
    }
  )

  return response.data
}

export async function refreshFacebookBrandPlaceReviews(facebookBrandPlaceId) {
  const response = await new ApiCall().post('/api/wizville/facebook/refresh_facebook_brand_place_reviews', {
    facebook_brand_place_id: facebookBrandPlaceId,
  });

  return response.data;
}

