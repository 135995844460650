<template>
  <div>
    <div class="w-rating-with-stars d-flex align-center f-14">
      <v-rating
        class="w-rating"
        background-color="grey lighten-2"
        readonly
        :value="scoreForStars"
        half-increments
        length="5"
        size="20"
        :color="$colors.gold"
      ></v-rating>
      <v-tooltip
        top
        max-width="300"
        :disabled="!displayTooltip"
      >
        <template #activator="{ on }">
          <span
            v-on="on"
          >
            <span class="ml-1 f-13 c-darkergrey">{{ value.toFixed(decimals) }}</span>
          </span>
        </template>
        <span v-if="displayTooltip">
          {{ this.tooltipContent }}
        </span>
      </v-tooltip>
    </div>
    <slot name="ratingDetails">
    </slot>
  </div>
</template>

<script>
  export default {
    name: "WRatingWithStars",
    props: {
      value: { type: Number, required: true },
      onFive: { type: Boolean, required: false, default: true },
      tooltipContent: { type: String, required: false, default: ''},
      decimals: { type: Number, required: false, default: 1 }
    },
    computed: {
      displayTooltip() {
        return !!this.tooltipContent.length
      },
      scoreForStars() {
        if (this.value === null) {
          return 0
        }

        const displayedScore = this.onFive ? this.value : this.value / 2

        return Math.round(displayedScore * 2) / 2
      }
    }
  }

</script>
