<template>
  <div
    class="d-flex ga-2"
    :class="{ 'flex-row-reverse': right }"
  >
    <slot v-if="$vuetify.breakpoint.smAndUp" name="avatar">
    </slot>
    <div
      class="d-flex flex-column ga-1"
      :style="{ width: width }"
    >
      <div
        class="d-flex justify-space-between"
        :class="{ 'flex-row-reverse': right }"
      >
        <div class="d-flex flex-column justify-center">
          <slot name="title">
          </slot>
          <slot name="subtitle">
          </slot>
        </div>
        <div
          class="d-flex align-center ga-1"
          :class="{ 'flex-row-reverse': right }"
        >
          <slot name="details">
          </slot>

          <slot name="actions">
          </slot>
        </div>
      </div>
      <slot name="content">
      </slot>
      <slot name="footer">
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      left: { required: false, type: Boolean },
      right: { required: false, type: Boolean }
    },
    name: "WTimelineElement",
    computed: {
      width() {
        return this.$vuetify.breakpoint.mdAndUp ? 'calc(100% - 150px)' : '100%'
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
