<template>
  <prism-editor
    class="code-editor"
    v-if="value"
    :style="{ height: height, width: width }"
    :value="code"
    @input="updated"
    :highlight="highlighter"
    :line-numbers="lineNumbers"
    :readonly="readonly"
  />
</template>

<script>
  import { PrismEditor } from 'vue-prism-editor'
  import "vue-prism-editor/dist/prismeditor.min.css"
  import { highlight, languages } from "prismjs/components/prism-core"
  import "prismjs/components/prism-clike"
  import "prismjs/components/prism-javascript"
  import "prismjs/themes/prism-tomorrow.css"

  export default {
    name: "WCode",
    components: { PrismEditor },
    props: {
      value: { required: false, default: "" },
      lang: { type: String, default: "js" },
      height: { },
      width: { },
      lineNumbers: { type: Boolean, default: true },
      readonly: { type: Boolean, default: false }
    },
    data() {
      return {
        code: this.stringify(this.value)
      }
    },
    methods: {
      highlighter(code) {
        return highlight(code, languages[this.lang])
      },
      updated($event) {
        this.code = $event
        this.$emit('input', this.code)
      },
      stringify(value) {
        return ((typeof this.value === "object") ? JSON.stringify(this.value, null, '\t') : this.value);
      }
    },
    watch: {
      value: function() {
        this.code = this.stringify(this.value);
      }
    }
  }
</script>

<style lang='stylus'>
  .code-editor
    background: #2d2d2d
    color: #ccc
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace
    font-size: 12px
    line-height: 1.5
    padding: 15px 0px

    .prism-editor__line-numbers
      margin-right: 15px

    .prism-editor__textarea:focus
      outline: none
</style>
