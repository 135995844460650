<template>
   <v-tooltip top transition="none">
    <template #activator="{ on }">
      <span
        :class="[ 'nowrap', font ]"
        class="cursor-pointer"
        v-on="on"
      >
        <v-icon v-if="putIcon" :class="[font]" class="bottom_1px">{{ icon }}</v-icon>
        {{ $helpers.dates.complete(formattedDate) }}
      </span>
    </template>

    <div>
      {{ prefix }} {{ $helpers.dates.short(formattedDate, this.displayTime) }} {{ suffix }}
    </div>
  </v-tooltip>
</template>

<script>
  import dayjs from 'dayjs'

  export default {
    name: "WDate",
    props: {
      "suffix": { type: String, required: false, default: '' },
      "date": { required: true },
      "displayTime": { type: Boolean, default: false },
      "dateFormat": { type: String },
      "icon": { type: String, default: 'mdi-clock-outline' },
      "putIcon": { type: Boolean, default: true},
      "font": { default: 'f-14' }
    },
    computed: {
      formattedDate() {
        if (this.dateFormat) {
          this.date = dayjs(this.date, this.dateFormat, true)
        }

        if (dayjs(this.date).isValid() ) {
          return this.date
        }
      },
      prefix() {
        if (this.icon == 'mdi-update') {
          return this.$t('review_updated_at')
        }
      }
    }
  }
</script>
