import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, callReportParams) {
  const response = await new ApiCall().post("/api/wizville/call_reports", {
    voterUniqueId,
    call_report: callReportParams
  })

  return response.data
}

export async function update(voterUniqueId, callReportUniqueId, callReportParams) {
  const response = await new ApiCall().put(`/api/wizville/call_reports/${callReportUniqueId}`, {
    voterUniqueId,
    call_report: callReportParams
  });

  return response.data
}

export async function destroy(voterUniqueId, callReportUniqueId) {
  const response = await new ApiCall().delete(`/api/wizville/call_reports/${callReportUniqueId}`, {
    // Need to wrap params into a data object so that axios can send it in a DELETE request
    // https://github.com/axios/axios/issues/736
    data: {
      voterUniqueId
    }
  });

  return response.data
}
