<template>
  <div class="campaign-export">
    <ReviewsExport />
    <EmailingsExport v-if="currentAdmin" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ReviewsExport from "./ReviewsExport"
  import EmailingsExport from "./EmailingsExport"

  export default {
    name: "CampaignExport",
    components: {
      ReviewsExport,
      EmailingsExport
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters(['currentAdmin'])
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'
      
  .campaign-export
    padding-top: $filters-height
</style>
