import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, messageParams) {
  const response = await new ApiCall().post("/api/wizville/messages", {
    voterUniqueId,
    message: messageParams
  });

  return response.data
}

export async function destroy(voterUniqueId, messageId) {
  const response = await new ApiCall().delete(
    `/api/wizville/messages/${messageId}`,
    {
      // Need to wrap params into a data object so that axios can send it in a DELETE request
      // https://github.com/axios/axios/issues/736
      data: {
        voterUniqueId,
      },
    }
  )

  return response.data
}

export async function updateTrustvilleDisplay(voterUniqueId, messageId, trustvilleDisplay) {
  const response = await new ApiCall().post(
    `/api/wizville/messages/${messageId}/update_trustville_display`,
    {
      voterUniqueId,
      trustvilleDisplay
    }
  )

  return response.data
}