import WizClient from '@shared/http/wizClient'
import fileDownload from 'js-file-download'
import contentDisposition from 'content-disposition'

export async function downloadPlacesExport(params = {}) {
  const response = await WizClient.post('/google_places_exports', params)

  return response.data.data
}

export async function createLmBrand(params = {}) {
  const response = await WizClient.post('/lm_brands', params)

  return response.data
}

export async function createLmBrandFactory(params = {}) {
  const response = await WizClient.post('/lm_brand_factories', params)

  return response.data
}

export async function createLmBrandFactoryBrands(params = {}) {
  const response = await WizClient.post('/lm_brand_factories/create_lm_brand_factory_brands', params)

  return response.data
}

export async function updateLmBrandFactory(lmBrandId, params = {}) {
  const response = await WizClient.put(`/lm_brand_factories/${lmBrandId}`, params)

  return response.data
}

export async function seedLmBrandFactoryBrands(lmBrandId) {
  const response = await WizClient.post(`/lm_brand_factories/${lmBrandId}/seed_lm_brand_factory_brands`)

  return response.data
}

export async function resetAndSeedLmBrandFactoryBrands(lmBrandId) {
  const response = await WizClient.post(`/lm_brand_factories/${lmBrandId}/reset_and_seed_lm_brand_factory_brands`)

  return response.data
}

export async function updateLmBrandFactoryBrands(lmBrandId) {
  const response = await WizClient.post(`/lm_brand_factories/${lmBrandId}/update_lm_brand_factory_brands`)

  return response.data
}

export async function exportLmBrandFactoryBrands(lmBrandId) {
  const response = await WizClient.get(`/lm_brand_factories/${lmBrandId}/export_lm_brand_factory_brands`)

  return response.data
}

export async function createLmBrandsLmPlaces(lmBrandId) {
  const response = await WizClient.post(`/lm_brand_factories/${lmBrandId}/create_lm_brands_lm_places`)

  return response.data
}

export async function scrapLmPlaces(lmBrandId) {
  const response = await WizClient.post(`/lm_brand_factories/${lmBrandId}/scrap_lm_places`)

  return response.data
}

export async function refreshLocalizedLmBrandPlaces(lmBrandId, params = {}) {
  const response = await WizClient.post(`/lm_brands/${lmBrandId}/refresh_localized_lm_brand_places`, params)

  return response.data
}

export async function destroyLmBrandFactory(lmBrandId) {
  const response = await WizClient.delete(`/lm_brand_factories/${lmBrandId}`)

  return response.data
}

export async function updateLmBrand(lmBrandId, params = {}) {
  const response = await WizClient.put(`/lm_brands/${lmBrandId}`, params)

  return response.data
}

export async function refreshBrandPlaces(lmBrandId) {
  const response = await WizClient.post(`/lm_brands/${lmBrandId}/refresh`)

  return response.data
}

export async function deleteLmBrand(lmBrandId) {
  const response = await WizClient.delete(`/lm_brands/${lmBrandId}`)

  return response.data
}

export async function updateTopicSummaryMonths(lmBrandId) {
  const response = await WizClient.post(`/lm_brands/${lmBrandId}/update_topic_summary_months`)

  return response.data
}


export async function scrap(lmPlaceIds) {
  const response = await WizClient.post(`/lm_places/scrap`, { lmPlaceIds })

  return response.data
}

export async function downloadExport(lmBrandId) {
  const response = await WizClient.get(`/lm_brands/${lmBrandId}/export`, {
    responseType: 'blob',
  })

  let filename = contentDisposition.parse(
    response.headers['content-disposition']
  ).parameters.filename

  fileDownload(response.data, filename)

  return response
}
