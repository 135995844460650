<template>
  <div>
    <WTable
      v-if="vertical"
      :rows="items"
      :stripped="true"
      :paginated="false"
    >
        <template
          slot-scope="{row}"
        >
          <td>
            <slot name="item" :item="row"/>

            <WRepartitionHorizontalFrieze
              height="15px"
              :item-name="row.name"
              :nb-promoters="row.nbPromoters"
              :nb-detractors="row.nbDetractors"
              :nb-neutrals="row.nbNeutrals"
            >
              <template v-slot:item-name>
                <slot name="item-name" :item="row">
                  {{row.name}}
                </slot>
              </template>
              <template v-slot:item-score>
                <slot name="item-score" :item="row">
                  {{row.score}}
                </slot>
              </template>
            </WRepartitionHorizontalFrieze>
          </td>
        </template>
    </WTable>

    <table v-else class="table-stripped" >
      <tr>
        <td v-for="(item, idx) in items" :key="idx">
          <w-repartition-vertical-frieze
            :item-name="item.name"
            :item-score="item.score"
            :nb-promoters="item.nbPromoters"
            :nb-detractors="item.nbDetractors"
            :nb-neutrals="item.nbNeutrals"
          />
        </td>
      </tr>
    </table>
    <tfoot v-if="legend">
        <div style="overflow: hidden; text-align: center;">
          <template v-for="(legend, idx) in legends">
            <span :key="'color'+idx" class="color" :style="legendColor(legend.color)"></span>
            <span :key="'legend'+idx" style="display: inline-block;  vertical-align: middle; width: 60px; margin-right: 20px"> {{legend.name}}</span>
          </template>
        </div>
    </tfoot>
  </div>
</template>

<script>

export default {
  name: "WNpsRepartition",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    vertical: { type: Boolean, default: false },
    legend: { type: Boolean, default: false }
  },
   data() {
    return {
      legends: [{color: "#C26170", name: this.$t('detractors')}, {color: "#67B18C", name: this.$t('promoters')}, {color: "#C1BFC7", name: this.$t('neutrals')}],
    }
  },
  methods: {
    legendColor: function(color) {
      return "display: inline-block; vertical-align: middle; width: 60px; background-color:"+color+"; width: 18px; height: 8px; border-radius: 2px;"
    }
  }
}
</script>

<style lang='stylus'>

  .vertical-rankings
    width: 80px
    text-align: -webkit-center
    height: 100px

  .vertical-ranking
    width: 80%
    text-align: center

  .ver-item-name, .ver-item-score
    text-align: center

  table.table-stripped
      tr
        td:nth-child(even)
          background-color: #F4F5F7

</style>
