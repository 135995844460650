import BasedResult from "./basedResult"
import deepmerge from '@shared/helpers/deepmerge';
import WizClient from '@shared/http/wizClient'
import { Transaction } from '@api/transaction.js'

class BasedResolver {
  constructor(basedRequest, queryName, endPoint, options = {}) {
    this.basedRequest = basedRequest
    this.queryName = queryName
    this.options = deepmerge(basedRequest.options, options)
    this.query = basedRequest.query

    this.user = this.options.user
    this.scope = this.options.scope || "user"
    this.endPoint = endPoint
  }

  async resolve() {
    const transaction = new Transaction("based", this.queryName)
 
    transaction.start(this)
 
    try {
      const result = await this.getResult(this.endPoint)
      const basedResult = new BasedResult(
        this.query,
        this.options,
        result
      )
      transaction.ok(basedResult.result)
      return basedResult
    } catch (error) {
      transaction.ko(error)
      throw error
    }    
  }

  async getResult(endPoint) {
    let result = null

    if (endPoint === 'hash') {
      result = await this.toHash()
    } else if (endPoint === 'time_series') {
      result = await this.toTimeSeries(this.options)
    } else {
      throw `[${endPoint}] bad endpoint`
    }

    return result
  }

  async runQuery(queryFct) {
    const result = await queryFct()

    return result
  }

  async toHash(options = { }) {
    this.queryName = options.queryName || this.queryName

    const result = await this.runQuery(async () => {
      const result = await WizClient.post(`/api/based/to_hash?q=${encodeURIComponent(this.queryName)}`, {
        queryName: this.queryName,
        query: this.query,
        scope: this.scope,
        user: this.user
      })

      return (result.data)
    })

    return result
  }

  async toTimeSeries({ tickNumber, tickUnit, timeSerieParams }) {
    this.tickNumber = tickNumber
    this.tickUnit = tickUnit
    this.timeSerieParams = timeSerieParams

    return await this.runQuery(async () => {
      const result = await WizClient.post('/api/based/to_time_series', {
        query_name: this.queryName,
        query: this.query,
        scope: this.scope,
        user: this.user,
        tickNumber,
        tickUnit,
        timeSerieParams: timeSerieParams
      })

      return (result.data)
    })
  }

  async to_series(labels) {
    return await this.runQuery(async () => {
      const result = await WizClient.post('/api/based/to_series', {
        query_name: this.queryName,
        query: this.query,
        scope: this.scope,
        user: this.user,
        labels: labels
      })

      return (result.data)
    })
  }

  debug() {
    const frontendRequest = `const result = (await basedQuery${this.debugBody()}${this.debugEndpoint()});
    
    return result.result;`

    const authContext = (window.$store?.getters?.currentUser?.id ? `:auth_context => Contexts::User.new(User.find_by_mail("${window.$store?.getters?.currentUser?.mail}"))` : '')
    
    if (this.endPoint === "hash") {
      const backendRequest = `Based::Request.new(${authContext})${this.debugBody(true)}${this.debugEndpoint(true)}`

      return `${frontendRequest}
  
      /*
       ${backendRequest}
      */`
    } else {
      return frontendRequest
    }
  }

  debugEndpoint(backend = false) {
    if (this.endPoint === 'time_series') {
      if (backend) {
        return `.to_hash`
      } else {
        return `.resolve("sandbox",
        { 
          tickNumber: ${this.tickNumber},
          tickUnit: "${this.tickUnit}",
          timeSerieParams: ${JSON.stringify(this.timeSerieParams)}
        },
        "time_series")`
      }

    } else {
      return (backend == true ? ".to_hash" : '.resolve("sandbox")')
    }
  }


  debugBody(backend = false) {
    let request = ""

    if (this.query.select) {
      request += `.select(
        ${JSON.stringify(this.query.select)}
      )`
    }
    if (this.query.where) {
      request += `.where(
        ${JSON.stringify(this.query.where)}
      )`
    }

    if (this.query.group?.length > 0) {
      const groups = JSON.stringify(this.query.group)
      request += `.group(
        ${backend ? `*${groups}` : groups}
      )`
    }

    if (this.query.scoped?.length > 0) {
      const scoped = JSON.stringify(this.query.scoped)

      request += `.scoped(
        ${backend ? `*${scoped}` : scoped}
      )`
    }

    if (this.query.order?.length > 0) {
      const order = JSON.stringify(this.query.order)

      request += `.order(
        ${order}
      )`
    }

    if (this.query.limit) {
      request += `.limit(
        ${this.query.limit}
      )`
    }

    if (this.query.offset) {
      request += `.offset(
        ${this.query.offset}
      )`
    }

    return (backend ? request.replace('null', 'nil') : request)
  }
}

export default BasedResolver;