import Row from './row';
import "@theme/assets/pdfFonts/SourceSansPro-Regular"
import "@theme/assets/pdfFonts/SourceSansPro-Bold"
import "@theme/assets/pdfFonts/SourceSansPro-SemiBold"
import 'jspdf-autotable';
import imgUrlToBase64 from '@shared/helpers/img-url-to-base64';
import htmlToBase64 from '@shared/helpers/html-to-base64';
import svgToBase64 from '@shared/helpers/svg-to-base64';
import {
  applyTextStyle,
  computeImageDimensions,
  computeCursorXFromAlign,
  computeCursorYFromBaseline,
  computeShapeCursorXFromAlign,
  computeShapeCursorYFromBaseline,
  displayBackground,
  displayBorders,
  reduceText,
  setColor
} from './utils';

class Col {
  constructor({ pdf, parent, posX, posY,
      width, height, backgroundColor, borders,
      borderColor, marginLeft = 0, content} = {}) {
    this.pdf = pdf;
    this.doc = this.pdf.doc;
    this.parent = parent;
    this.content = content;
    const computedWidth = this.computeWidth(width);
    const computedMarginLeft = this.computeMarginLeft(marginLeft);
    this.marginLeft = computedMarginLeft;
    this.posX = posX + this.marginLeft;
    this.posY = posY;
    this.cursorY = 0;
    this.width = computedWidth;
    this.height = height;
    this.backgroundColor = backgroundColor;
    this.borders = borders;
    this.borderColor = borderColor;
    if (this.height) {
      this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
    }
  }

  async addRow({ height, backgroundColor, borders, borderColor, marginTop = 0, marginLeft = 0 } = {}, content) {
    const row = new Row({
      pdf: this.pdf, parent: this, posX: this.posX, posY: this.posY + this.cursorY,
      height, backgroundColor, borders, borderColor, marginTop, content
    });

    row.displayBackground();

    if (content) {
      await content(row);
    }

    row.height = row.height || row.maxColHeight;

    row.displayBorders();
    this.cursorY += row.height + marginTop;
  }

  async addTable(options) {
    const margin = options.margin || { left: 0 };

    margin.left += this.posX;
    this.width = this.width || this.parent.width;

    this.doc.autoTable({
      ...{
        startY: (options.startY || 0) + this.posY,
        tableWidth: this.width,
        margin: margin
      }, ...options
    })


    this.height = this.height || this.doc.lastAutoTable.finalY - this.posY;
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  addText(text, { color, font, fontSize, maxWidth, overflow = 'ellipsize', baseline = 'middle', align = 'left', yOffset = 0} = {}) {
    applyTextStyle(this.pdf, color, font, fontSize);
    const textMaxWidth = maxWidth || this.width;

    const displayedText = reduceText(this.pdf, text, textMaxWidth, overflow);
    const textDimension = this.doc.getTextDimensions(displayedText);

    const cursorX = computeCursorXFromAlign(
      align, this.posX, this.width
    );

    const cursorY = computeCursorYFromBaseline(
      baseline, this.posY + this.cursorY, this.height, textDimension.h
    );

    this.doc.text(
      displayedText,
      cursorX,
      cursorY + yOffset,
      { align: align, baseline: 'top' }
    );
    this.width = this.width || textDimension.w;
    this.height = this.height || textDimension.h;
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  async addHtmlElement(element, { width, height, xOffset = 0, yOffset = 0, align = 'left', baseline = 'middle', html2CanvasOptions = {}} = {} ) {
    try {
      const canvasOptions = {
        ...html2CanvasOptions,
        ...{ scale: html2CanvasOptions.scale || 1 }
      };
      const [base64, imageWidth, imageHeight] = await htmlToBase64(element, canvasOptions);
      const [displayedWidth, displayedHeight] = computeImageDimensions(width, height, this.width, this.height, imageWidth, imageHeight);

      const cursorX = computeCursorXFromAlign(
        align, this.posX, this.width, displayedWidth
      );

      const cursorY = computeCursorYFromBaseline(
        baseline, this.posY + this.cursorY, this.height, displayedHeight
      );

      this.doc.addImage(
        base64,
        'PNG',
        cursorX + xOffset,
        cursorY + yOffset,
        displayedWidth,
        displayedHeight
      );

      this.width = this.width || displayedWidth;
      this.height = this.height || displayedHeight;
      this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
    } catch(e) {
      console.log(e);
    }
  }

  async addRating(score, { baseline = 'middle', align = 'left', xOffset = 0, yOffset = 0, color = '#FFCA40', size } = {}) {
    const fullStarCount = Math.trunc(score)
    const halfStarCount = score - fullStarCount > 0 ? 1 : 0
    const emptyStarCount = 5 - fullStarCount - halfStarCount

    await this.addRow({ width: size*5, size }, async(row) => {
      for(let i=0; i< fullStarCount; i++) {
        await row.addCol({ width: size, size }, async(col) => {
          await col.addIcon('mdiStar', { width: size, height: size, color: color })
        })
      }
      for(let i=0; i< halfStarCount; i++) {
        await row.addCol({ width: size, size }, async(col) => {
          await col.addIcon('mdiStarHalfFull', { width: size, height: size, color: color })
        })
      }
      for(let i=0; i< emptyStarCount; i++) {
        await row.addCol({width: size, size }, async(col) => {
          await col.addIcon('mdiStarOutline', { width: size, height: size, color: '#e0e0e0' })
        })
      }
    })
    this.width = this.width || size*5
    this.height = this.height || size
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  addCircle({ radius = 10, baseline = 'middle', align = 'left', xOffset = 0, yOffset = 0, color } = {}) {
    const circleColor = color || this.backgroundColor || this.parent.backgroundColor || this.pdf.defaultTextColor;
    setColor(this.pdf, circleColor, 'fill');

    const cursorX = computeShapeCursorXFromAlign(
      align, this.posX, this.width, radius * 2
    );

    const cursorY = computeShapeCursorYFromBaseline(
      baseline, this.posY + this.cursorY, this.height, radius * 2
    );

    this.doc.circle(cursorX + xOffset, cursorY + yOffset, radius, 'F');

    this.width = this.width || radius * 2;
    this.height = this.height || radius * 2;
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  drawRectangle({ x, y, width, height, radius = 0, color } = {}) {
    setColor(this.pdf, color, 'fill');
    this.doc.roundedRect(isNaN(x) ? 0 : x, y, isNaN(width) ? 0 : width, height, radius, radius, 'F');
  }

  addRectangle({ width, height, radius = 0, baseline = 'middle', align = 'left', xOffset = 0, yOffset = 0, color } = {}) {
    const rectangleColor = color || this.backgroundColor || this.parent.backgroundColor || this.pdf.defaultTextColor;

    const cursorX = computeCursorXFromAlign(
      align, this.posX, this.width, width
    );

    const cursorY = computeCursorYFromBaseline(
      baseline, this.posY + this.cursorY, this.height, height
    );

    this.drawRectangle({ x: cursorX + xOffset, y: cursorY + yOffset, width, height, radius, color: rectangleColor });

    this.width = this.width || width;
    this.height = this.height || height;
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  addRepartitionBar({ width, height, promoterCount, neutralCount, detractorCount, baseline = 'middle', align = 'left', xOffset = 0, yOffset = 0, color } = {}) {
    const cursorX = computeCursorXFromAlign(
      align, this.posX, this.width, width
    );

    const cursorY = computeCursorYFromBaseline(
      baseline, this.posY + this.cursorY, this.height, height
    );

    const totalCount = promoterCount + neutralCount + detractorCount;
    const promoterWidth = promoterCount * width / totalCount;
    const neutralWidth = neutralCount * width / totalCount;
    const detractorWidth = detractorCount * width / totalCount;

    this.drawRectangle({
      x: cursorX + xOffset,
      y: cursorY + yOffset,
      width: detractorWidth,
      height,
      color: '#c26170'
    });

    this.drawRectangle({
      x: cursorX + xOffset + detractorWidth,
      y: cursorY + yOffset,
      width: neutralWidth,
      height,
      color: '#c1bfc7'
    });

    this.drawRectangle({
      x: cursorX + xOffset + detractorWidth + neutralWidth,
      y: cursorY + yOffset,
      width: promoterWidth,
      height,
      color: '#67b18c'
    });

    this.width = this.width || width;
    this.height = this.height || height;
    this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
  }

  async addImage(url, { width, height, xOffset = 0, yOffset = 0, align = 'left', baseline = 'middle' } = {}) {
    try {
      const [base64, imageWidth, imageHeight] = await imgUrlToBase64(url);
      const [displayedWidth, displayedHeight] = computeImageDimensions(width, height, this.width, this.height, imageWidth, imageHeight);

      const cursorX = computeCursorXFromAlign(
        align, this.posX, this.width, displayedWidth
      );

      const cursorY = computeCursorYFromBaseline(
        baseline, this.posY + this.cursorY, this.height, displayedHeight
      );

      this.doc.addImage(
        base64,
        'PNG',
        cursorX + xOffset,
        cursorY + yOffset,
        displayedWidth,
        displayedHeight
      );

      this.width = this.width || displayedWidth;
      this.height = this.height || displayedHeight;
      this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
    } catch(e) {
      console.log(e);
    }
  }

  async addIcon(name, { width, height, xOffset = 0, yOffset = 0, color, align = 'left', baseline = 'middle' } = {}) {
    try {
      const [base64, imageWidth, imageHeight] = await this.pdf.iconToBase64Exporter.iconToBase64(name, color || this.defaultTextColor);
      const [displayedWidth, displayedHeight] = computeImageDimensions(width, height, this.width, this.height, imageWidth, imageHeight);

      const cursorX = computeCursorXFromAlign(
        align, this.posX, this.width, displayedWidth
      );

      const cursorY = computeCursorYFromBaseline(
        baseline, this.posY + this.cursorY, this.height, displayedHeight
      );

      this.doc.addImage(
        base64,
        'PNG',
        cursorX + xOffset,
        cursorY + yOffset,
        displayedWidth,
        displayedHeight
      );

      this.width = this.width || displayedWidth;
      this.height = this.height || displayedHeight;
      this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
    } catch(e) {
      console.log(e);
    }
  }

  async addChart(chart, { width, xOffset = 0, yOffset = 0, align = 'left', baseline = 'middle' } = {}) {
    const exportWidth = width;
    const exportHeight = exportWidth * chart.chartHeight / chart.chartWidth;
    try {
      const svgData = chart.getSVG({
        chart:{
          width: exportWidth,
          height: exportHeight
        }
      });
      const [base64, imageWidth, imageHeight] = await svgToBase64(svgData, { width: exportWidth, height: exportHeight });
      const [displayedWidth, displayedHeight] = computeImageDimensions(exportWidth, exportHeight, this.width, this.height, imageWidth, imageHeight);

      const cursorX = computeCursorXFromAlign(
        align, this.posX, this.width, displayedWidth
      );

      const cursorY = computeCursorYFromBaseline(
        baseline, this.posY + this.cursorY, this.height, displayedHeight
      );

      this.doc.addImage(
        base64,
        'PNG',
        cursorX + xOffset,
        cursorY + yOffset,
        displayedWidth,
        displayedHeight
      );

      this.width = this.width || displayedWidth;
      this.height = this.height || displayedHeight;
      this.parent.maxColHeight = Math.max(this.parent.maxColHeight, this.height);
    } catch(e) {
      console.log(e);
    }
  }

  displayBackground() {
    if (this.backgroundColor) {
      displayBackground(this.pdf, this.backgroundColor, this.posX, this.posY, this.width, this.height);
    }
  }

  displayBorders() {
    if (this.borders) {
      displayBorders(this.pdf, this.borders, this.borderColor, this.posX, this.posY, this.width, this.height);
    }
  }

  computeWidth(width) {
    if (width) {
      if (typeof(width) === 'string') {
        return this.parent.width/12*parseInt(width);
      } else {
        return width;
      }
    }
    return this.content ? null : this.parent.width;
  }

  computeMarginLeft(marginLeft) {
    if (typeof(marginLeft) === 'string') {
      return this.parent.width/12*parseInt(marginLeft);
    } else {
      return marginLeft;
    }
  }
}

export default Col;
