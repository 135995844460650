<template>
  <div>
    <v-skeleton-loader 
      v-if="loading"
      type="table-thead, table-tbody, table-tfoot" 
    />
    <v-data-table
      v-else
      class="f-13 ranking-table v-datatable-with-first-column-fixed"
      :headers="responsiveHeaders"
      :items="items"
      :loading-text="$t('please_wait')"
      :no-data-text="$t('no_data_label')"
      :items-per-page="fullScreenMode ? 1000 : 100"
      :fixedHeader="!fullScreenMode"
      :hide-default-footer="fullScreenMode || $vuetify.breakpoint.smAndDown"
      :hide-default-header="true"
      :height="fullScreenMode ? 'auto' : 'calc(100vh - 200px)'"
      :item-class="itemRowBackground"
      mobileBreakpoint="0"
    >
      <template #header="{ props }">
        <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
          <th
            style="min-height: 49px; border-bottom: thin solid rgba(0, 0, 0, 0.12); position: sticky; top: 0px; z-index: 3;"
            v-for="header of props.headers"
            :key="header.value"
            class="text-align-left pa-3 pointer c-dark bg-white"
            :class="header.cellClass"
            @click="sortColumn(props.options, header, rankingRelatedColumn)"
          >
            <div :class="{ 'c-accent': sorted(props.options, header, rankingRelatedColumn) }" class="nowrap fs-12">
              {{ header.text }}
            </div>
            <div
              v-if="header.subtext"
              class="c-textgrey f-10 lh-11"
            >
              <span :class="{ 'c-accent': sorted(props.options, header, rankingRelatedColumn) }">
                {{ header.subtext }}
              </span>
            </div>
          </th>
        </tr>
      </template>

      <template v-slot:item.rank="{ value }">
        <WRank :rank="value" :nrTooltipText="$t('tooltip_need_more_feedbacks_to_be_ranked', { min_number_of_feedback: minimalNumberOfFeedbackForRanking })" />
      </template>

      <template v-for="header in headersWithSlotName" v-slot:[header.slotName]="{value, item}">
        <div v-if="value === $LOADING">
          <span style="filter: blur(4px);">load</span>
        </div>
        <template v-else-if="header.displayAs === 'rate'">
          {{ value }}
        </template>
        <template v-else-if="header.displayAs === 'hour'">
          {{ $options.filters.prettyHours(value) }}
        </template>
        <template v-else-if="header.displayAs === 'score'">
          <div
            class="d-flex flex-column flex-shrink-0 align-self-center"
          >
            <div v-if="item[header.nbReviewValue] !== null" class="d-flex align-center f-14">
              <v-rating
                class="w-rating"
                background-color="grey lighten-2"
                readonly
                :value="scoreForStars(header, item)"
                half-increments
                length="5"
                size="20"
                :color="$colors.gold"
              ></v-rating>
              <span class="ml-1 f-13 c-darkergrey">{{ item[header.value] }}</span>
            </div>
            <div class="d-flex flex-row" v-if="item[header.nbReviewValue] !== null">
              <span class="ml-1 sub-text f-12 c-lightgrey">{{ translateNbReview(item[header.nbReviewValue] || 0) }}</span>
            </div>
          </div>
        </template>
        <template v-else-if="header.displayAs === 'repartition'">
          <div
            class="d-flex flex-column flex-shrink-0 align-self-center"
          >
            <WRepartitionHorizontalFrieze
              height="15px"
              :nb-promoters="isNaN(item[header.nbPromoters]) ? 0 : item[header.nbPromoters]"
              :nb-detractors="isNaN(item[header.nbDetractors]) ? 0 : item[header.nbDetractors]"
              :nb-neutrals="isNaN(item[header.nbNeutrals]) ? 0 : item[header.nbNeutrals]"
              :item-score="!header.hideScore && typeof item[header.value] === 'number' ? item[header.value] : null"
              :decimals="header.presenter === 'round' ? 0 : 2"
            />
            <span
              v-if="!header.hideNbReview"
              class="sub-text c-lightgrey f-12"
            >
              {{ translateNbReview((isNaN(item[header.nbPromoters]) ? 0 : item[header.nbPromoters]) + (isNaN(item[header.nbDetractors]) ? 0 : item[header.nbDetractors]) + (isNaN(item[header.nbNeutrals]) ? 0 : item[header.nbNeutrals])) }}
            </span>
          </div>
        </template>

        <template v-else-if="header.displayAs === 'url'">
          <a :href="item.placeUrl" target="_blank">
            <v-icon>mdi-open-in-new</v-icon>
          </a>
        </template>

        <template v-else-if="header.displayAs === 'ranked_item'">
          <div class="d-flex align-center" style="min-width: 120px">
            <div v-if="$vuetify.breakpoint.smAndDown">
              <WRank size="small" :rank="item.rank" :nrTooltipText="$t('tooltip_need_more_feedbacks_to_be_ranked', { min_number_of_feedback: minimalNumberOfFeedbackForRanking })" class="pr-3" />
            </div>

            <div class="f-13 lh-13 py-1" :class="{ 'nowrap': item[itemRankedColumn].length <= 15, 'f-12': $vuetify.breakpoint.smAndDown }">
              {{ item[itemRankedColumn] }}

              <span class="f-8 c-grey" v-if="itemRankedColumn === 'placeName' && item.placeCodes && item.placeCodes != ''"><br/>{{ item.placeCodes }}</span>
            </div>
          </div>
        </template>

        <template v-else>
            {{ value }}
        </template>
      </template>

      <template v-slot:footer.prepend>
        <RankingOptions
          v-if="!rankingPlacesVisibilityRestricted"
          :key="displayedScope"
          :scope="displayedScope"
          @scopeChanged="updateSelectedScope"
        />
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import RankingOptions from "./RankingOptions"
  import _concat from "lodash/concat"
  import { mapGetters } from 'vuex'

  export default {
    name: "RankingTable",
    props: {
      headers: { type: Array, required: true },
      items: { type: Array, required: true },
      loading: { type: Boolean, required: false, default: true },
      scope: { type: String, required: true },
      itemRankedColumn: { type: String, required: true },
      rankingRelatedColumn: { type: Object, required: true },
      campaign: { required: false }
    },
    data() {
      return {
        selectedScope: null
      }
    },
    components: {
      RankingOptions
    },
    computed: {
      ...mapGetters([
        'minimalNumberOfFeedbackForRanking',
        'dashboardAvgScale',
        'rankingPlacesVisibilityRestricted',
        'networkRankingPlaceIds',
        'fullScreenMode'
      ]),
      avgScoreScale() {
        return this.campaign?.avgScoreScale || this.dashboardAvgScale
      },
      headersWithSlotName() {
        return this.headers.map(header => {
          return { ...header, ...{ slotName: `item.${header.value}`} }
        })
      },
      displayedScope() {
        return this.selectedScope || this.scope
      },
      responsiveHeaders() {
        if (!this.$vuetify.breakpoint.smAndDown) {
          return _concat([{ text: '', value: 'rank', width: '10px', align: 'center' }], this.headers)
        } else {
          return this.headers
        }
      }
    },
    methods: {
      sorted(options, header, rankingRelatedColumn) {
        if (options.sortBy[0]) {
          return options.sortBy[0] === header.value
        } else {
          return rankingRelatedColumn?.score === header.value
        }
      },
      sortColumn(options, header, rankingRelatedColumn) {
        if (rankingRelatedColumn?.score === header.value) {
          options.sortBy = []
          options.sortDesc = []
        } else if (options.sortBy[0] === header.value) {
          options.sortDesc = [true]
        } else {
          options.sortBy = [header.value]
          options.sortDesc = [true]
        }
      },
      updateSelectedScope(scope) {
        this.$emit('scopeChanged', scope)
      },
      _concat,
      itemRowBackground(item) {
        return (item.active ? "active" : "")
      },
      scoreForStars(header, item) {
        if (item[header.value] === null) {
          return 0
        }

        const score = item[header.value]
        const avgScoreScale = header?.avgScoreScale || this.avgScoreScale
        const displayedsScore = avgScoreScale.max === 5 ? score : score / 2

        return Math.round(displayedsScore * 2) / 2;
      },
      translateNbReview(nbReview) {
        return (nbReview ? this.$t('nbReview_without_google_logo', { nbReview: nbReview?.toLocaleString() }) : "-")
      }
    }
  }
</script>

<style lang="stylus">
  .ranking-table
    .v-data-footer
      padding: 10px 5px
      margin-right: 0px !important

    .v-data-footer__select
      display: none
    .v-data-footer__pagination
      margin-left: auto !important
</style>
