import ApiCall from '@api/apiCall.js'

function lrm_base_path(platform) {
  if (['gmb'].includes(platform)) {
    return ('/api/wizville/lrm_' + platform)
  } else {
    throw new Error(`Bad platform: ${platform}`);
  }
}

async function editLrmBrandPreferences(lrmPreferences, reset, platform) {
  const response = await new ApiCall().post(
    `${lrm_base_path(platform)}/edit_lrm_brand_preferences`, { lrm_brand_preferences: lrmPreferences, reset: reset }
  )

  return response.data
}

async function setNewLmComparedPlaces(platform) {
  const response = await new ApiCall().post(
    `${lrm_base_path(platform)}/set_new_lm_compared_places`
  )

  return response.data
}

async function setLmComparedPlaces(platform) {
  const response = await new ApiCall().post(
    `${lrm_base_path(platform)}/set_lm_compared_places`
  )

  return response.data
}

async function resetLmComparedPlaces(platform) {
  const response = await new ApiCall().post(
    `${lrm_base_path(platform)}/reset_lm_compared_places`
  )

  return response.data
}

async function exportLmComparedPlaces(platform, placeNameSearch, mode) {
  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/export_lm_compared_places?mode=${mode}&place_name_search=${placeNameSearch}`
  )

  return response.data
}

async function exportLrmEligiblePlaces(platform) {
  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/export_lrm_eligible_places`
  )

  return response.data
}

async function getNearbyPlacesSample(platform, params = { searchKeywords, maxComparedRangeKm, nbComparedPlace, excludeNamesContain, excludeNamesDoNotContain, searchDeep }) {
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/get_nearby_places_sample?${encodeGetParams}`
  )
  return response.data
}

async function updateCostEstimation(platform, params = { enabledGroupIds, enabledCampaignIds, nbComparedPlace, history, searchDeep }) {
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/update_cost_estimation?${encodeGetParams}`
  )

  return response.data.cost
}


async function exportLmBrandReviews (platform, lmBrandIds, mode, { countryCode, dateBegin, dateEnd }) {
  const params = { platform: platform, lmBrandIds: lmBrandIds.join(","), mode: mode, countryCode: countryCode, dateBegin: dateBegin, dateEnd: dateEnd }
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/export_lm_brand_reviews?${encodeGetParams}`
  )

  return response.data
}


async function exportLmBrandCategory(platform, lmBrandCategoryIds, mode) {
  const params = { platform: platform, lmBrandCategoryIds: lmBrandCategoryIds, mode: mode }
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/export_lm_brand_category?${encodeGetParams}`
  )

  return response.data
}

async function repairLmBrandReviews (platform, lmBrandIds) {
  const params = { platform: platform, lmBrandIds: lmBrandIds }
  const encodeGetParams = Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&")

  const response = await new ApiCall().get(
    `${lrm_base_path(platform)}/repair_lm_brand_reviews?${encodeGetParams}`
  )

  return response.data
}

async function setLmComparedPlace(platform, placeId, lmPlaceId, assigned) {
  const response = await new ApiCall().post(
    `${lrm_base_path(platform)}/set_lm_compared_place`,
    { place_id: placeId, lm_place_id: lmPlaceId, assigned }
  )
}

export {
  editLrmBrandPreferences,
  setLmComparedPlaces,
  setNewLmComparedPlaces,
  resetLmComparedPlaces,
  exportLmComparedPlaces,
  exportLrmEligiblePlaces,
  getNearbyPlacesSample,
  updateCostEstimation,
  exportLmBrandReviews,
  setLmComparedPlace,
  repairLmBrandReviews,
  exportLmBrandCategory
}