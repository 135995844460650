export function serializeCurrentUser() {
  const currentUser = $store.getters?.currentUser
  
  if (currentUser) {
    return {
      mail: currentUser.mail,
      brand_name: currentUser.brandName,
      locale: currentUser.locale,
      time_zone: currentUser.timeZone,
      app_version: currentUser.appVersion,
      job: currentUser.rightPreference?.job
    }
  }
}

export function serializeCurrentDashboard() {
  const currentDashboard = $store.getters?.currentDashboard
  
  if (currentDashboard) {
    return {
      type: currentDashboard.type,
      minimal_number_of_feedback_for_ranking: currentDashboard.minimalNumberOfFeedbackForRanking,
      ranking_related_indicator: currentDashboard.rankingRelatedIndicator,
      has_nps_question: currentDashboard.hasNpsQuestion,
      filters: $store.getters.dashboardFilters
    }
  }
}

export function serializeCurrentVoter() {
  const currentVoter = $store.getters?.currentVoter
  
  if (currentVoter) {
    return {
      complete: currentVoter.complete,
      avg_score: currentVoter.avgScore,
      nps: currentVoter.nps,
      source: currentVoter.source,
      insatisfaction_status: currentVoter.insatisfactionStatus,
      processing_status: currentVoter.processingStatus,
      first_treatment_action: currentVoter.first_treatment_action,
      trustville_status: currentVoter.trustvilleStatus,
      diffused_on_trustville: currentVoter.diffusedOnTrustville
    }
  } 
}