<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    min-width="305px"
    min-height="500px"
    class="date-time-picker-field"
    v-bind="menuProps"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="dateTimeFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="displayedDateTime"
        v-on="on"
        readonly
        prepend-inner-icon="mdi-calendar"
        background-color="#eef1f5"
        hide-details="auto"
        dense
        filled
      />
    </template>

    <v-card class="date-time-picker-menu-content">
      <v-card-text class="px-0 py-0">
        <v-tabs
          vertical
          v-model="activeTab"
        >
          <v-tab class="calendar" key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab class="timer" key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-time-three-outline</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              :min="minDateTime"
              :max="maxDateTime"
              @input="showTimePicker"
              width="250px"
              show-adjacent-months
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="time"
              v-bind="timePickerProps"
              format="24hr"
              :min="minTime"
              :max="maxTime"
              width="250px"
              @click:minute="minuteClicked"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm:ss'
const DISPLAYED_DATE_FORMAT = 'D MMM YYYY'
const DISPLAYED_TIME_FORMAT = 'HH:mm'
const DISPLAYED_DATE_TIME_FORMAT = `${DISPLAYED_DATE_FORMAT} ${DISPLAYED_TIME_FORMAT}`
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export default {
  name: 'WDateTimePickerField',
  model: {
    prop: 'dateTime',
    event: 'input'
  },
  props: {
    dateTime: {
      type: [Date, String]
    },
    initDateTime: {
      type: [Date, String]
    },
    minDateTime: {
      type: String
    },
    maxDateTime: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    locale: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    menuProps: {
      type: Object
    },
    dateTimeFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data() {
    return {
      open: false,
      activeTab: 0,
      date: null,
      time: null,
      selectedDatetime: null
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    minTime() {
      return moment(this.selectedDatetime).isSame(this.minDateTime, 'day') ?
        moment(this.minDateTime).format(DISPLAYED_TIME_FORMAT) :
        undefined
    },
    maxTime() {
      return moment(this.selectedDatetime).isSame(this.maxDateTime, 'day') ?
        moment(this.maxDateTime).format(DISPLAYED_TIME_FORMAT) :
        undefined
    },
    displayedDateTime() {
      return this.selectedDatetime ? moment(this.selectedDatetime).format(DISPLAYED_DATE_TIME_FORMAT) : ''
    },
    dateSelected() {
      return !this.date
    }
  },
  methods: {
    updateSelectedDateTime() {
      if (this.date && this.time) {
        let dateTimeString = this.date + ' ' + this.time
        if (this.time.length === 5) {
          dateTimeString += ':00'
        }
        this.selectedDatetime = moment(dateTimeString).format(DATE_TIME_FORMAT)
      } else {
        this.selectedDatetime = null
      }
    },
    init() {
      const initDate = this.initDateTime || moment().format()

      this.date = moment(initDate).format(DATE_FORMAT)
      this.time = moment(initDate).format(TIME_FORMAT)
      this.updateSelectedDateTime()
      this.$emit('input', this.selectedDatetime)
    },
    minuteClicked() {
      this.reset()
      this.$emit('input', this.selectedDatetime)
    },
    clear() {
      this.reset()
      this.init()
    },
    reset() {
      this.open = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    }
  },
  watch: {
    initDateTime: function() {
      this.init()
    },
    date: function() {
      this.updateSelectedDateTime()
    },
    time: function(newValue, oldValue) {
      this.updateSelectedDateTime()
    },
    open: function(value) {
      this.$emit('opened', value)
    }
  }
}
</script>

<style lang='stylus'>
  .date-time-picker-menu-content

    .v-tab
      justify-content: start
    .v-slide-group__wrapper
      width: 55px
    .v-picker__body
      height: 260px
    .v-date-picker-table
      height: 200px
    .v-picker__title
      height: 70px
    .v-date-picker-title__date
      font-size: 20px
    .v-time-picker-title__time
      padding-top: 4px
      .v-picker__title__btn, span
        height: 30px
        font-size: 50px
  .v-input__icon--prepend-inner
    .v-icon
      font-size: 20px
</style>
