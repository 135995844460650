<template>
  <div class="w-date-picker">
    <v-text-field
      v-model="date"
      :label="label"
      prepend-icon="mdi-calendar"
      @click:prepend="toggleDatePicker"
      :placeholder="placeholder"
      hide-details
      outlined
      dense
      class="pa-0 ma-0"
    >
    </v-text-field>

    <v-date-picker
      class="w-date-picker__calendar"
      v-if="isValidDate"
      v-show="activeDatePickers[pickerId]"
      v-model="date"
      no-title
      scrollable
      @change="toggleDatePicker"
      :max="max"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "WDatePicker",
    props: {
      value: { required: false },
      placeholder: { 
        type: String,
        default: "YYYY-MM-DD",
        required: false
      },
      label: {
        type: String,
        default: "",
        required: false
      },
      max: {
        type: String,
        default: (new Date).toISOString().split('T')[0],
        required: false
      }
    },
    data() {
      return {
        pickerId: this.$helpers.string.random()
      }
    },
    computed: {
      ...mapGetters([
        'activeDatePickers'
      ]),
      isValidDate() {
        const regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
        return !this.date || regex.test(this.date)
      },
      date: {
        get() {
          return this.value
        },
        set(newDate) {
          this.$emit('input', newDate)
        }
      }
    },
    methods: {
      toggleDatePicker() {
        this.$store.dispatch("toggleDatePicker", this.pickerId)
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-date-picker
    position: relative

    .w-date-picker__calendar
      z-index: 10000
      background-color: white
      position: absolute
      top: 60px
</style>