import WizClient from '@shared/http/wizClient'

export async function rescheduleStaledPlaces(params = {}) {
  const response = await WizClient.post('/local_insight_reporting/reschedule_staled_places', params)
  return response.data
}

export async function rescheduleStaledCompetitors(params = {}) {
  const response = await WizClient.post('/local_insight_reporting/reschedule_staled_competitors', params)
  return response.data
}

export async function regenerateReportings(params = {}) {
  const response = await WizClient.post('/local_insight_reporting/regenerate_reportings', params)
  return response.data
}

export async function sendReportings(params = {}) {
  const response = await WizClient.post('/local_insight_reporting/send_reportings', params)
  return response.data
}
