export function safeDiff(a, b, round = 2) {
  if (!a || !b) {
    return null
  } else {
    return (b - a).toFixed(round)
  }
}

export function avg(arr, attrName = null) {
  return (arr.length > 0 ? arr.reduce((total, data) => total + data[attrName], 0) / arr.length : null)
}