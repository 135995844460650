import { mapGetters } from 'vuex'
import { sqlDate } from '@vue/plugins/helpers/dates'

export default {
  props: {
    campaign: { required: false }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest',
      'sqlDateBegin',
      'sqlDateEnd',
      'placeIds'
    ]),
    indicatorAlias() {
      return 'insatisfactionsAvgProcessingTime'
    }
  },
  methods: {
    rankFor(requestDatas) {
      if (requestDatas && this.isMonoPlace) {
        const rank = requestDatas
          .filter(place => place.insatisfactionsAvgProcessingTime != -1)
          .findIndex((place) => {
            return place.placeCampaignsPlaceId == this.placeIds[0]
          })
        // findIndex return -1 when not match found
        return rank != -1 ? rank + 1 : null
      } else {
        return null
      }
    },
    avgProcessingTimeRequest({ dateBegin, dateEnd } = { }) {
      dateBegin = dateBegin || this.sqlDateBegin
      dateEnd = dateEnd || this.sqlDateEnd
      this.loading = true

      let request = this.dashboardFilterRequest.select({
          voters: [this.avgProcessingTimeColumn({ dateBegin, dateEnd })]
        }).where({
          "place_campaigns_campaign_id": this.campaign.id
        })
        .dateBetween(
          sqlDate(dateBegin),
          sqlDate(dateEnd, '23:59:59')
        )

      this.loading = false
      return request
      // to take into account processing insatisfaction create before the period and in progress we can replace with the code below
      // we removed it to stay iso with the ranking table
      // .dateBetween(
      //     dayjs(this.sqlDateBegin).subtract(1, "year").format(`YYYY-MM-DD 00:00:00 Z`),
      //     dayjs(this.sqlDateEnd).add(1, "year").format(`YYYY-MM-DD 00:00:00 Z`)
      //   )
      
    },
    avgProcessingTimeColumn({ dateBegin, dateEnd } = { }) {
      dateBegin = dateBegin || this.sqlDateBegin
      dateEnd = dateEnd || this.sqlDateEnd

      return {
        insatisfactions_avg_processing_time: {
          date_begin: dateBegin,
          date_end: dateEnd,
          as: this.indicatorAlias
        }
      }
    },
  },
}
