<template>
  <w-autocomplete
    v-model="userId"
    label="Se connecter en tant que"
    :items="selectItems"
    :border="false"
    backgroundColor="primary"
    color="white"
    theme="light"
    fixTopOffset
    outlined
    rounded
    solo
    :loading="isLoading"
    :search-input.sync="search"
  >
    <template #more>
      <AutologinAdvancedSearch
        @advancedSearchSelectedBrandsChanged="advancedSearchSelectedBrandsChanged"
        @advancedSearchSelectedUserGroupsChanged="advancedSearchSelectedUserGroupsChanged"
        @advancedSearchSelectedUserAttributesChanged="advancedSearchSelectedUserAttributesChanged"
        @toggleLoading="toggleLoading"
        @advancedSearchExport="advancedSearchExport"
        />
    </template>

  </w-autocomplete>
</template>

<script>
  import deepmerge from '@shared/helpers/deepmerge';
  import _debounce from "lodash/debounce"
  import AutologinAdvancedSearch from '@admin/layouts/navigation/AutologinAdvancedSearch'
  import exportToExcel from '@shared/helpers/export-to-excel.js'

  export default {
    name: 'AppBar',
    components: { AutologinAdvancedSearch },
    data: () => ({
      search: "",
      users: [],
      nonAdminUsers: [],
      adminUsers: [],
      isLoading: false,
      userId: null,
      selectedBrands: [],
      selectedUsersGroups: [],
      selectedUserAttributes: []
    }),
    computed: {
      selectItems() {
        const choices = []

        if (this.users) {
          for (let [brandName, brandUsers] of Object.entries(this.users)) {
            choices.push({ header: brandName.toUpperCase()})

            Object.values(brandUsers).forEach((user) => {
              choices.push({ value: user.id, text: user.login, group: brandName })
            })
          }
        }

        return choices
      }
    },
    watch: {
      search(val) {
        this.refreshUsersIfPossible()
      },
      userId() {
        if (this.userId) {
          this.logAs()
        }
      }
    },
    methods: {
      async logAs() {
        const sessionToken = await this.$api.wizville.sessions.logAs(this.userId)

        if (sessionToken) {
          if (window.location.host.match(/.*admin.*/) || window.location.host.match(/^localhost/)) {
            let host = window.location.host.replace("admin", "app")

            if (host.match(/^localhost/)) {
              host = 'app.wizville.' + host
            }

            window.open(`${window.location.protocol}//${host}?utok=${sessionToken}`, '_blank')
          } else {
            if (window.location.pathname.match(/.*[0-9].*/)) {
              window.location = `${window.location.protocol}//${window.location.host}?utok=${sessionToken}`
            } else {
              window.location = `${window.location.protocol}//${window.location.host}${window.location.pathname}?utok=${sessionToken}`
            }
          }
        }
      },
      refreshUsers: _debounce(async function() {
        this.toggleLoading(true)
        const requestWhereClauses = {}
        const nonAdminRequestWhereClauses = {}

        if (this.selectedBrands.length > 0) requestWhereClauses['brand_id'] = { 'in': this.selectedBrands }
        if (this.selectedUsersGroups.length > 0) requestWhereClauses['user_group_id'] = { 'in': this.selectedUsersGroups }
        if (this.selectedUserAttributes.length > 0) nonAdminRequestWhereClauses['with_user_attributes'] = { enabled: this.selectedUserAttributes }

        const usersRequest = this.$adminBasedRequest().select({
          user_user_groups: [
            { MAX_users_login: { as: "login" } },
            { MAX_users_id: { as: "id" } },
            { MAX_user_groups_name: { as: "user_group_name" } },
            { MAX_brand_name: { as: "brand_name" } }
          ]
        }).where({
          brand_group_user_mail_login: { 'icontain': this.search },
          ...requestWhereClauses,
          ...nonAdminRequestWhereClauses
        }).group([
          { brand_and_group_name: { mod: "UPPER" } },
          "users_id"
        ]).order(
          ["MAX_brand_and_group_name", "asc"],
          ["MAX_users_mail", "asc"]
        )

        const users = await this.$resolve(usersRequest)

        const superAdminsRequest = this.$adminBasedRequest().select({
          super_admins: [
            { MAX_login: { as: "login" } },
            { MAX_id: { as: "id" } }
          ]
        }).where({
          login: { 'icontain': this.search },
          ...requestWhereClauses
        }).group(
          [ "group_name", "id" ]
        )

        const superAdmins = await this.$resolve(superAdminsRequest)

        this.nonAdminUsers = users.data
        this.adminUsers = superAdmins.data
        this.users = deepmerge(superAdmins.data, users.data)
        this.toggleLoading(false)
      }, 275, { leading: true }),
      refreshUsersIfPossible() {
        if ( (this.search && this.search.length > 2)
          || (this.selectedBrands && this.selectedBrands.length > 0)
          || (this.selectedUsersGroups && this.selectedUsersGroups.length > 0)
        ) {
          this.refreshUsers()
        } else {
          this.clearUsers()
        }
      },
      advancedSearchSelectedBrandsChanged(selectedBrands) {
        this.selectedBrands = selectedBrands
        this.refreshUsersIfPossible()
      },
      advancedSearchSelectedUserGroupsChanged(selectedUsersGroups) {
        this.selectedUsersGroups = selectedUsersGroups
        this.refreshUsersIfPossible()
      },
      advancedSearchSelectedUserAttributesChanged(selectedUserAttributes) {
        this.selectedUserAttributes = selectedUserAttributes
        this.refreshUsersIfPossible()
      },
      toggleLoading(value){
        this.isLoading = value
      },
      clearUsers(){
        this.users = []
      },
      async advancedSearchExport() {
        try {
          this.$store.dispatch("notifyInfo", { message: "Export en cours" })
          const exportData = []
          Object.values(this.nonAdminUsers).forEach((grouped_users) => {
            Object.values(grouped_users).forEach((user) => {
              exportData.push([user.id, user.brandName, user.userGroupName, user.login])
            })
          })

          // Filename: "users_search", sheetName: "users_search"
          exportToExcel('users_search', 'users_search', exportData, ["User ID", "Compte entreprise", "Groupe utilisateur", "Email"])
          this.$store.dispatch("notifySuccess", { message: "L'export a réussi" })
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: `L'export a échoué : ${e.message}`,
            timeout: 5000,
          })
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
