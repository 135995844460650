<template>
  <div class="w-table">
    <table :class="{ stripped, faded, shadow }">
      <thead class="bordered">
        <tr>
          <slot name="columns">
            <th
              v-for="(column, index) in columns"
              :key="index"
              :class="{ sortable: column.sortable, 'no-padding': column.noPadding, [column.class]: true }"
              @click="sort(column)"
            >
              <span
                :style="'justify-content: ' + _get(column, 'justify') || 'flex-start'"
                class="column-title fs-11"
              >
                <div
                  v-if="column.sortable"
                  class="sort-arrows"
                >
                  <WIcon
                    v-if="!(currentSort === column.prop && currentSortDir === 'desc')"
                    class="sort-arrow"
                    icon="triangle-down"
                    :icon-style="{'font-size': '6px'}"
                    :flip-vertically="true"
                  />
                  <WIcon
                    v-if="!(currentSort === column.prop && currentSortDir === 'asc')"
                    class="sort-arrow"
                    icon="triangle-down"
                    :icon-style="{'font-size': '6px'}"
                  />
                </div>

                <WIcon
                  v-if="column.icon"
                  class="header-icon"
                  :class="column.icon.class"
                  :icon="column.icon.name"
                />
                {{ column.label }}
              </span>
            </th>
          </slot>
        </tr>
      </thead>
      <tbody v-if="isInitializing">
        <tr
          v-for="_ in perPage"
          :key="'sample_' + _"
          class="loading"
        >
          <td
            v-for="(column, index) in columns"
            :key="index"
            :align="_get(columns[index], 'align') || 'left'"
            :style="getTdStyle(columns[index])"
          >
            {{ column.sample }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(line, index) in sortedRows">
          <v-tooltip top transition="none">
            <template #activator="{ on }">
              <tr
                v-on="line.tooltip ? on : {}"
                :class="{
                  loading: isLoading,
                  loaded: !isLoading,
                  clickable: line.click,
                  line: true,
                }"
                @click="line.click && line.click()"
              >
                <slot :row="line">
                  <td
                    v-for="(value, key, i) in line"
                    class="text-f4"
                    :align="_get(columns[i], 'align') || 'left'"
                    :style="getTdStyle(columns[i])"
                  >
                    {{ value }}
                  </td>
                </slot>
              </tr>
            </template>
            <span v-if="line.tooltip" class="f-12">
              {{ line.tooltip }}
            </span>
          </v-tooltip>
        </template>
      </tbody>
    </table>

    <div
      v-if="paginated && totalPage"
      class="pagination"
    >
      <span
        class="paging"
        :disabled="currentPage == 1"
        @click="changePageHandler(currentPage - 1)"
      >
        &lt;
      </span>
      <span
        class="paging"
        v-for="prevPage in prevPages"
        :key="prevPage.pageNumber"
        :text="prevPage.pageNumber.toString()"
        @click="changePageHandler(prevPage.pageNumber)"
      >
       {{ prevPage.pageNumber.toString() }}
      </span>
      <span>
        {{ currentPage.toString() }}
      </span>
      <span
        class="paging"
        v-for="nextPage in nextPages"
        :key="nextPage.pageNumber"
        @click="changePageHandler(nextPage.pageNumber)"
      >
        {{ nextPage.pageNumber.toString() }}
      </span>
      <span class="paging" @click="changePageHandler(currentPage + 1)">
        &gt;
      </span>
    </div>
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import _orderBy from 'lodash/orderBy'

  export default {
    props: {
      bordered: { type: Boolean, default: false },
      changeOrderHandler: { type: Function, default: (currentSort, currentSortDir) => {} },
      changePageHandler: { type: Function, default: (pageNumber) => {} },
      columns: { type: Array, default: () => [] },
      currentPage: { type: Number, default: 1 },
      faded: { type: Boolean, default: false },
      loadStatus: { type: String, default: 'ready' },
      paginated: { type: Boolean, default: false },
      perPage: { type: Number, default: null },
      rangePage: { type: Number, default: 10 },
      rows: { type: Array, default: () => [] },
      shadow: { type: Boolean, default: true },
      stripped: { type: Boolean, default: false },
      totalPage: { type: Number, default: 0 },
    },
    data() {
      return {
        currentSort: '',
        currentSortDir: '',
      }
    },
    computed:{
      isLoading() {
        return (this.loadStatus === "loading")
      },
      isInitializing() {
        return (this.loadStatus === "init")
      },
      sortedRows() {
        const { rows, currentSort, currentSortDir } = this

        if (this.paginated) {
          return rows
        } else {
          return _orderBy(rows, currentSort, currentSortDir)
        }
      },
      prevPages() {
        let startPage = this.currentPage - this.rangePage
        let pages = []

        if (startPage < 1) {
          startPage = 1
        }

        for (let page = startPage; page !== this.currentPage; page++) {
          pages.push({ pageNumber: page })
        }

        return (pages)
      },
      nextPages() {
        let endPage = this.currentPage + this.rangePage
        let pages = []

        if (endPage > this.totalPage) {
          endPage = this.totalPage
        }

        for (let page = this.currentPage + 1; page <= endPage; page++) {
          pages.push({ pageNumber: page })
        }

        return (pages)
      }
    },
    methods: {
      _get,
      _orderBy,
      getTdStyle(columnOptions){
        const align = _get(columnOptions, 'align') || 'left'
        const sortable = _get(columnOptions, 'sortable') || false

        if (align === "left" && sortable === true) {
          return {
            'padding-left': '1.4rem'
          }
        }

        return {}
      },
      sort(column) {
        if (column.sortable) {
          this.currentSort = column.prop
          if (this.currentSortDir === 'asc') {
            this.currentSortDir = 'desc'
          } else {
            this.currentSortDir = 'asc'
          }

          if (this.paginated) {
            this.changeOrderHandler(this.currentSort, this.currentSortDir)
          }
        }
      }
    }
  }
</script>

<style lang="stylus">
  .w-table
    .paging
      color: #1E547B
      cursor: pointer

    .pagination
      margin-top: 10px

    table
      width: 100%
      border-spacing: 0

      &.shadow
        box-shadow: 0px 1px 6px #DEDEDE

      &.stripped
        tbody
          & > tr:nth-of-type(even)
            background-color: #F4F5F7

      &.faded
        tr
          opacity: .5

    thead
      background-color: #1E5478
      color: white
      text-transform: uppercase
      &.bordered
        border-left: 5px solid #1E5478

      th
        height: 1.875rem
        text-align: left
        padding: 0 0.75rem
        vertical-align: middle
        white-space: nowrap

        &.no-padding
          padding: 0

        &.sortable
          cursor: pointer

        .column-title
          color: white
          font-weight: normal
          display: flex

          .sort-arrows
            display: flex
            margin-right: 2px
            flex-direction: column
            justify-content: space-evenly

            .sort-arrow
              height: 6px

          .header-icon
            margin: 0 0.188rem

          & >>> .wiz-icon
            align-self: center
            padding-bottom: 0px

    tbody
      tr
        height: 2.5rem
        background-color: white
        text-align: left
        transition: background-color 350ms

        &.loading
          td
            filter: blur(2px)

        &.loaded
          &:hover,
          &:focus
            background-color: #F4F5F7 !important

        &.clickable
          cursor: pointer

        td
          vertical-align: middle
          padding: 0.938rem 0.563rem 0.813rem 0.563rem

          &.with-meta-link
            padding: 0rem

            .meta-link a
              display: block
              padding: 0.938rem 0.563rem 0.813rem 0.563rem
</style>
