import WizClient from "@shared/http/wizClient"

async function shorten(url) {
  const response = await WizClient.post("/labo/shorten", {
    url: url
  })
  return response
}


async function proxyDownload(url) {
  const response = await WizClient.post("/labo/proxy_download", {
    url: url
  })
  return response
}

async function hostFile(file) {
  let formData = new FormData();
  formData.append('file', file);

  const response = await WizClient.post("/labo/host_file", formData)

  return response
}

export { shorten, proxyDownload, hostFile }




