import WizClient from '@shared/http/wizClient'

async function confirmCompetitiveReport(token) {
  const response = await WizClient.put('/api/local_monitor/users/confirm_competitive_report',
    { token }
  )

  return response.data
}

export {
  confirmCompetitiveReport
}
