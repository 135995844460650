<template>
  <v-navigation-drawer
    v-resize="onResize"
    :value="drawer"
    :permanent="$vuetify.breakpoint.smAndUp"
    :expand-on-hover="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
    :mini-variant.sync="mini"
    clipped
    dense
    flat
    app
    :height="windowHeight"
    class="sidebar dashboard-sidebar"
    v-if="!$inIframe"
    :class="{ 'bg-palegrey': !$vuetify.breakpoint.xsOnly }"
    @input="setDrawer"
  >
    <v-hover v-slot="{ hover }" >
      <v-layout class="d-flex flex-column" fill-height>
        <header-menu
          v-if="$vuetify.breakpoint.xsOnly"
          @closeMenuClicked="closeMenu"
        ></header-menu>

        <div class="d-flex flex-column flex-section">
          <top-menu
            v-if="$vuetify.breakpoint.smAndUp"
            :hovered="hover"
            @toogleMiniClicked="mini = !mini"
            :title="$t('dashboard_sidebar_settings')"
            icon="mdi-cog"
          ></top-menu>

          <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

          <div class="overflow-section flex-grow-1">
            <campaign-settings-menu></campaign-settings-menu>

            <company-menu v-if="$vuetify.breakpoint.xsOnly && currentUser.isAdmin"></company-menu>
          </div>
        </div>
          <div class="overflow-section flex-grow-1 d-flex flex-column justify-end" style='flex-direction: column;'>
            <router-link
              v-if="campaignNetImpactScorePreferenceGroup"
              :to="{ name: 'Dashboard', params: { dashboardId: campaignNetImpactScorePreferenceGroup, dashboardType: 'mono' } }"
              >
            <bottom-menu
              :hovered="hover"
              @toogleMiniClicked="mini = !mini"
              :title="$t('dashboard_sidebar_dashboard')"
              icon="mdi-chart-timeline-variant"
            ></bottom-menu>
          </router-link>
        </div>
        <div class="mt-auto">
          <WFooter v-if="!mini"/>
          <footer-menu v-if="$vuetify.breakpoint.xsOnly" ></footer-menu>
        </div>
      </v-layout>
    </v-hover>
  </v-navigation-drawer>

</template>

<script>
  import { mapGetters } from "vuex"
  import HeaderMenu from "./shared/HeaderMenu"
  import TopMenu from "./shared/TopMenu"
  import BottomMenu from "./shared/BottomMenu"
  import CampaignSettingsMenu from "./shared/CampaignSettingsMenu"
  import CompanyMenu from "./shared/CompanyMenu"
  import FooterMenu from "./shared/FooterMenu"

  export default {
    name: "CampaignPreferencesSideBar",
    components: {
      HeaderMenu,
      TopMenu,
      BottomMenu,
      CampaignSettingsMenu,
      CompanyMenu,
      FooterMenu,
    },
    data() {
      return {
        drawer: false,
        windowHeight: window.innerHeight
      }
    },
    computed: {
      ...mapGetters(
        [
          'sidebarShowed',
          'currentDashboard',
          'currentDashboardType',
          'currentCampaignPreferencesCampaignId',
          'currentUser',
          'sidebarMinimized'
        ]
      ),
      mini: {
        get() {
          return this.sidebarMinimized
        },
        set(value) {
          this.$store.dispatch('updateSidebarMinimized', value)
        }
      }
    },
    asyncComputed: {
      async campaignNetImpactScorePreferenceGroup() {
        const request = this.$basedRequest().select({
          campaign_dashboards: [
            { "dashboard_id": { as: "dashboard_id" } }
          ]
        }).where({
          campaign_id: this.currentCampaignPreferencesCampaignId
        })

        const response = (await this.$resolve(request)).first()

        return response.dashboardId
      }
    },
    watch: {
      sidebarShowed: function() {
        this.drawer = this.sidebarShowed;
      },
    },
    methods: {
      closeMenu() {
        this.$store.dispatch('updateShowSidebar', false);
      },
      setDrawer(e) {
        this.$store.dispatch('updateShowSidebar', e);
      },
      onResize () {
        this.windowHeight = window.innerHeight
      },
    },
    mounted() {
      if(this.$vuetify.breakpoint.smOnly ||
        this.$vuetify.breakpoint.mdOnly
      ) {
        this.$store.dispatch('updateSidebarMinimized', true)
      }
    }
  }
</script>

<style lang="stylus">
  .dashboard-sidebar
    .v-navigation-drawer__content
      overflow-y: hidden !important

    .layout
      height: 100%

    .overflow-section
      overflow-y: auto !important

    .flex-section
      flex: 0 1 auto
      min-height: 0px

    .dashboard-menu
      max-height: 46px

    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px
    .sidebar
      max-width: 400px
</style>

