<template>
  <v-navigation-drawer
    v-resize="onResize"
    :value="drawer"
    :mini-variant.sync="mini"
    clipped
    dense
    flat
    app
    :height="windowHeight"
    class="sidebar dashboards-sidebar"
    v-if="!$inIframe"
    :class="{ 'bg-palegrey': !$vuetify.breakpoint.xsOnly }"
    @input="setDrawer"
  >
    <v-hover v-slot="{ hover }" >
      <v-layout class="d-flex flex-column" fill-height>
        <header-menu
          v-if="$vuetify.breakpoint.xsOnly"
          @closeMenuClicked="closeMenu"
        ></header-menu>
        <div class="d-flex flex-column flex-section">
          <v-slide-x-transition leave-absolute v-if="$vuetify.breakpoint.xsOnly && showDashboardList">
            <dashboards-list @hideDashboardList="showDashboardList = false"></dashboards-list>
          </v-slide-x-transition>

          <v-slide-x-reverse-transition leave-absolute>
            <v-list v-if="!showDashboardList" class="pa-0 d-flex flex-column flex-section">

              <top-menu
                v-if="$vuetify.breakpoint.smAndUp"
                :hovered="hover"
                @toogleMiniClicked="mini = !mini"
                :title="$t('dashboard_sidebar_dashboard')"
                icon="mdi-chart-timeline-variant"
              ></top-menu>

              <div class="overflow-section flex-grow-1">
                <dashboard-menus @showDashboardList="showDashboardList = true"></dashboard-menus>

                <company-menu v-if="$vuetify.breakpoint.xsOnly && currentUser.isAdmin"></company-menu>
              </div>
            </v-list>
          </v-slide-x-reverse-transition>
        </div>
        <div class="mt-auto">
          <WFooter v-if="!mini"/>
          <footer-menu v-if="$vuetify.breakpoint.xsOnly" ></footer-menu>
        </div>
      </v-layout>
    </v-hover>
  </v-navigation-drawer>

</template>

<script>
  import { mapGetters } from "vuex"
  import HeaderMenu from "./shared/HeaderMenu"
  import TopMenu from "./shared/TopMenu"
  import DashboardMenus from "./shared/DashboardMenus"
  import DashboardsList from "./shared/DashboardsList"
  import CompanyMenu from "./shared/CompanyMenu"
  import FooterMenu from "./shared/FooterMenu"

  export default {
    name: "DashboardsSideBar",
    components: {
      HeaderMenu,
      TopMenu,
      DashboardMenus,
      DashboardsList,
      CompanyMenu,
      FooterMenu,
    },
    data() {
      return {
        showDashboardList: false,
        drawer: false,
        windowHeight: window.innerHeight
      }
    },
    computed: {
      ...mapGetters(
        [
          'sidebarShowed',
          'currentDashboard',
          'currentUser',
          'sidebarMinimized'
        ]
      ),
      mini: {
        get() {
          return this.sidebarMinimized
        },
        set(value) {
          this.$store.dispatch('updateSidebarMinimized', value)
        }
      }
    },
    mounted() {
      if(this.$vuetify.breakpoint.smOnly ||
        this.$vuetify.breakpoint.mdOnly
      ) {
        this.$store.dispatch('updateSidebarMinimized', true)
      }
    },
    watch: {
      sidebarShowed: function() {
        this.drawer = this.sidebarShowed;
      },
      $route() {
        this.showDashboardList = false;
      }
    },
    methods: {
      closeMenu() {
        this.$store.dispatch('updateShowSidebar', false);
      },
      setDrawer(e) {
        this.$store.dispatch('updateShowSidebar', e);
        this.showDashboardList = false;
      },
      onResize () {
        this.windowHeight = window.innerHeight
        if (this.$vuetify.breakpoint.smAndUp) {
          this.$store.dispatch('updateShowSidebar', false);
        }
      },
    },
  }
</script>

<style lang="stylus">
  .dashboards-sidebar
    .v-navigation-drawer__content
      overflow-y: hidden !important

    .layout
      height: 100%

    .overflow-section
      overflow-y: auto !important

    .flex-section
      flex: 0 1 auto
      min-height: 0px

    .dashboard-menu
      max-height: 46px

    .v-list-item__icon:first-child
      margin-right: 4px !important

    .v-list-item__icon
      .v-icon
        margin-top: 0px !important
        margin-left: 3px
        font-size: 15px
    .sidebar
      max-width: 400px
  </style>

