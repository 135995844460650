import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

function parseHTML(html) {
  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe)
  iframe.contentWindow.document.open()
  iframe.contentWindow.document.write(html)
  iframe.contentWindow.document.close()
  return iframe.contentWindow.document.body
}

export default async function htmlToPdf(html, filename, html2CanvasOptions = {}) {
  const element = (typeof(html) === 'string' ? parseHTML(html) : html)

  const A4_HEIGHT = 841.89;
  const A4_WIDTH = 595.28;

  const WIDTH_MARGIN = 0;
  const HEIGHT_MARGIN = 0;

  /** convert html to canvas
   *  note that html2canvas returns a Promise */
  const body = element;
  const baseOptions = {
    useCORS: true,
    allowTaint: true,
    scale: 1.0
  }

  const canvas = await html2canvas(element, { ...baseOptions, ...html2CanvasOptions })
  /** calculate the imgWidth, imgHeight to print on PDF 
   *  so it can scale in equal proportions*/
  const canvasWidth = canvas.width;
  const canvasHeight =  canvas.height;

  const imgWidth = A4_WIDTH - 2 * WIDTH_MARGIN;    // max image width
  const imgHeight = (imgWidth / canvasWidth) * canvasHeight; // scale height in equal proportion

  const pdf = new jsPDF('p', 'px', [A4_WIDTH, imgHeight + (HEIGHT_MARGIN * 2)]);  // orientation, unit, format

  /** print pageImg on to pdf */
  const pageImg = canvas.toDataURL('image/png', 1.0);  
  const usedHeight = HEIGHT_MARGIN;

  pdf.addImage(
    pageImg,      // img DataUrl
    'PNG',
    WIDTH_MARGIN, // x - position against the left edge of the page
    usedHeight,   // y - position against the upper edge of the page
    imgWidth,
    imgHeight,
  );

  // /** save the pdf */
  return pdf.save(filename)
}