import ApiCall from '@api/apiCall.js'

export async function create(voterUniqueId, params) {
  const response = await new ApiCall().post("/api/wizville/voter_transfers", {
    voterUniqueId,
    insatisfaction: params
  });

  return response.data
}
