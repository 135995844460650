<template>
  <span class="wiz-source-icon d-inline-block">
    <w-icon v-if="iconData.type === 'w-icon'" :icon='iconData.icon' :className="this.classNames || this.iconData.class" :autoColor="autoColor" />
    <v-icon v-if="iconData.type === 'v-icon'" :class="this.classNames || this.iconData.class">{{ iconData.icon }}</v-icon>
  </span>
</template>

<script>
  export default {
    name: "WSourceIcon",
    props: {
      source: { required: true },
      classNames: {
        type: String,
        required: false,
        default: null
      },
      autoColor: {
        type: Boolean,
        required: false,
        default: true
      },
      iconSize: {
        type: String,
        required: false,
        default: 'f-14'
      }
    },
    methods: {
      iconMap() {
        return {
          facebook: { type: 'v-icon', icon: `mdi-facebook ${this.autoColor ? "c-facebook-blue" : ""}`, class: this.iconSize },
          google: { type: 'v-icon', icon: `mdi-google ${this.autoColor ? "c-google-blue" : ""}`, class: this.iconSize },
          pages_jaunes: { type: 'w-icon', icon: 'pages_jaunes', class: this.iconSize },
          bazaar_voice: { type: 'w-icon', icon: 'bazaar_voice', class: this.iconSize },
          trip_advisor: { type: 'w-icon', icon: 'trip_advisor', class: this.iconSize },
          ubereats: { type: 'w-icon', icon: 'uber-eats', class: this.iconSize },
          deliveroo: { type: 'w-icon', icon: 'deliveroo', class: this.iconSize },
          skeepers: { type: 'w-icon', icon: 'skeepers', class: this.iconSize },
          normal: { type: 'w-icon', icon: 'wizville', class: this.iconSize }
        }
      }
    },
    computed: {
      iconData() {
        return this.iconMap()[this.source] || this.iconMap()["normal"]
      }
    }
  }
</script>

<style lang='stylus' scoped>
.wiz-source-icon
  vertical-align text-top
</style>
