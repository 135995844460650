<template>
  <div class="w-top-badge c-white fs-12 nowrap text-align-center" :class="[ bgColorClass ]">
    {{ label }}
  </div>
</template>

<script>
  export default{
    name: "WTopBadge",
    props: {
      value: { type: Number, required: true },
      bgColorClass: { type: String, required: false, default: 'bg-primary' }
    },
    computed: {
      label() {
        if (this.value <= 10) {
          return `${this.$t('flop')} 10%`
        } else if (this.value <= 20) {
          return `${this.$t('flop')} 20%`
        } else if (this.value <= 30) {
          return `${this.$t('flop')} 30%`
        } else if (this.value <= 40) {
          return `${this.$t('flop')} 40%`
        } else if (this.value <= 50) {
          return `${this.$t('flop')} 50%`
        } else if (this.value <= 60) {
          return `${this.$t('top')} 50%`
        } else if (this.value <= 70) {
          return `${this.$t('top')} 40%`
        } else if (this.value <= 80) {
          return `${this.$t('top')} 30%`
        } else if (this.value <= 90) {
          return `${this.$t('top')} 20%`
        } else if (this.value <= 100) {
          return `${this.$t('top')} 10%`
        } else {
          return this.value
        }
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .w-top-badge
    padding: 3px 3px
    border-radius: 3px
    width: 60px
    box-sizing: border-box
</style>