<template v-if="voter.voterScore || voter.voterScore == 0">
  <span class="w-avg-score">
    <span :class="['score', 'font-weight-bold', color]">{{ round1(voterScore) }}</span>
    <span v-if="!hideScale">{{ `/${scale}` }}</span>
  </span>
</template>

<script>
  const BAD_SCORE = "error--text"
  const MEDIUM_SCORE = "primary--text"
  const MEDIUM_GREY_SCORE = "c-lightgrey"
  const GOOD_SCORE = "success--text"

  export default{
    name: "WScore",
    props: {
      "voterScore": { required: true },
      "insatisfactionStatus": { required: false, default: null },
      "scale": { required: false, default: 10 },
      "hideScale": { required: false, default: false }
    },
    computed: {
      color() {
        if (this.scale === 10) {
          return this.onScaleTen
        } else if (this.scale === 5) {
          return this.onScaleFive
        } else {
          return "primary--text"
        }
      },
      roundedScore() {
        return Math.round(this.voterScore)
      },
      fixedToOneNumber() {
        return this.voterScore.toFixed(1)
      },
      onScaleTen() {
        if (this.fixedToOneNumber <= 6.4)
          return BAD_SCORE
        else if (this.fixedToOneNumber >= 6.5 && this.fixedToOneNumber <= 8.4)
          return MEDIUM_GREY_SCORE
        else
          return GOOD_SCORE
      },
      onScaleFive() {
        if (this.fixedToOneNumber <= 3.4)
          return BAD_SCORE
        else if (this.fixedToOneNumber >= 3.5 && this.fixedToOneNumber <= 4.4)
          return MEDIUM_GREY_SCORE
        else
          return GOOD_SCORE
      }
    },
    methods: {
      round1(value) {
        if (!value && value != 0) return ''

        return value.toFixed(1) == parseInt(value)
          ? parseInt(value)
          : value.toFixed(1)
      },
    }
  }
</script>

<style lang='stylus' scoped>
  .w-avg-score
    white-space: nowrap

    .score
      font-size: 15px
</style>