import Col from './col';
import {
  displayBackground,
  displayBorders
} from './utils';

class Row {
  constructor({ pdf, parent, posX, posY, height, backgroundColor, borders, borderColor, marginTop = 0, marginLeft = 0, content } = {}) {
    this.pdf = pdf;
    this.parent = parent;
    this.posX = posX + marginLeft;
    this.posY = posY + marginTop;
    this.cursorX = 0;
    this.width = parent === this.pdf ? pdf.currentPage.getWidth() : parent.width;
    this.height = height;
    this.borders = borders;
    this.borderColor = borderColor;
    this.marginTop = marginTop;
    this.marginLeft = marginLeft;
    this.backgroundColor = backgroundColor;
    this.maxColHeight = 0;
    this.content = content;
  }

  async addCol({ width, height, backgroundColor, borders, borderColor, marginLeft = 0 } = {}, content) {
    const col = new Col({
      pdf: this.pdf,
      parent: this,
      posX: this.posX + this.cursorX,
      posY: this.posY,
      width: width,
      height: height,
      backgroundColor: backgroundColor,
      borders: borders,
      borderColor: borderColor,
      marginLeft: marginLeft,
      content: content
    })

    col.displayBackground();

    if (content) {
      await content(col);
    }

    col.displayBorders();
    this.cursorX += col.width + col.marginLeft;
  }

  displayBackground() {
    if (this.backgroundColor) {
      displayBackground(this.pdf, this.backgroundColor, this.posX, this.posY, this.width, this.height);
    }
  }

  displayBorders() {
    if (this.borders) {
      displayBorders(this.pdf, this.borders, this.borderColor, this.posX, this.posY, this.width, this.height);
    }
  }
}

export default Row;
