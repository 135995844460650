

<template>
  <i class="w-feeling-icon" :style="this.iconStyle">
    <w-icon :icon="icon" />
  </i>
</template>

<script>
  export default {
    props: [ 'feeling', 'small', 'outlined', 'size' ],
    name: "WFeelingIcon",
    computed: {
      icon() {
        if (this.feeling === 0) {
          return `smiley-happy${this.outlined === false ? '' : '-outline'}`
        } else if (this.feeling === 1) {
          return `smiley-neutral${this.outlined === false ? '' : '-outline'}`
        } else if (this.feeling === 2) {
          return `smiley-angry${this.outlined === false ? '' : '-outline'}`
        }
      },
      iconStyle() {
        if (this.small) {
          return {
            fontSize: "14px",
            lineHeight: "14px"
          }
        } else if (this.size) {
          return {
            fontSize: this.size,
            lineHeight: this.size
          }
        } else {
          return {
            fontSize: "16px",
            lineHeight: "16px"
          }
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
</style>
