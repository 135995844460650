import ApiCall from '@api/apiCall.js'

async function userSignIn(email, password) {
  try {
    const response = await new ApiCall().post(
      '/api/wizville/session/user_sign_in',
      { email, password }
    )

    if (response.status === 'ko') {
      return { error: response.message }
    } else {
      localStorage.wizvilleSessionToken = response.data.sessionToken
      return response.data.user
    }
  } catch (error) {
    return { error: error.response?.data }
  }
}

async function ssoUserSignIn(access_token) {
  try {
    const response = await new ApiCall().post(
      '/api/wizville/session/sso_user_sign_in',
      { access_token }
    )
    if (response.status === 'ko') {
      return { error: response.message }
    } else {
      localStorage.wizvilleSessionToken = response.data.sessionToken
      return response.data.user
    }
  } catch (error) {
    return { error: error.response }
  }
}

async function adminSignIn(email, password, channel = 'basic') {
  try {
    const response = await new ApiCall().post('/api/wizville/session/admin_sign_in',
      {
        email,
        password,
        channel
      }
    )

    if (response.status === 'ko') {
      return false
    } else {
      localStorage.wizvilleSessionToken = response.data.sessionToken
      return true
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

async function logAs(userId) {
  const response = await new ApiCall().post('/api/wizville/session/log_as', {
    userId,
  })

  return response.data.sessionToken
}

export {
  ssoUserSignIn,
  userSignIn,
  adminSignIn,
  logAs,
}
