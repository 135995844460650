<template>
  <div>
    <div class="w-rating-text d-flex align-center f-14">
      <v-tooltip
        top
        max-width="300"
        :disabled="!displayTooltip"
      >
        <template #activator="{ on }">
          <span
            v-on="on"
          >
            <span class="ml-1 f-13 c-darkergrey">{{ value.toFixed(1) }}</span>
          </span>
        </template>
        <slot name="tooltipContent">
          <span v-if="displayTooltip">
            {{ tooltipContent }}
          </span>
        </slot>
      </v-tooltip>
    </div>
    <slot name="ratingDetails">
    </slot>
  </div>
</template>

<script>
  export default {
    name: "WRatingWithStars",
    props: {
      value: { type: Number, required: true },
      onFive: { type: Boolean, required: false, default: true },
      tooltipContent: { type: String, required: false, default: ''}
    },
    computed: {
      displayTooltip() {
        return !!this.tooltipContent.length || !!this.$slots.tooltipContent
      },
      scoreForStars() {
        if (this.value === null) {
          return 0
        }

        const displayedScore = this.onFive ? this.value : this.value / 2

        return Math.round(displayedScore * 2) / 2
      }
    }
  }

</script>
