<template>
  <div class="w-tree-view">
    <div v-for="(value, attr, idx) in tree" :key="`1_${idx}`">
      <div v-if="attr == 'keys'">
        <div v-for="key, keyIdx of value" :key="`2_${keyIdx}`">
          <slot name="leaf" :leaf="key" :deep="0">
          </slot>
        </div>
      </div>

      <div v-else>
        <div class="level">
          <hr class="mb-2 mt-3" v-if="idx > 1" />

          <span class="lh-30 fs-18 c-primary pointer" @click="toggleGroup($event, value, attr)">
            {{ attr }} <v-icon small>{{ expandedGroups[attr] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </span>
        </div>

        <div v-if="expandedGroups[attr]" class="pb-1">
          <div v-for="key, keyIdx of (value.keys || [])" :key="`3_${keyIdx}`">
            <slot name="leaf" :leaf="key" :deep="1" :idx="keyIdx">
            </slot>
          </div>
        </div>

        <div v-for="(value1, attr1, idx) in value" v-if="expandedGroups[attr]" class="pb-2" :key="`4_${idx}`">
          <div v-if="attr1 !== 'keys'">
            <div class="level">
              <span class="lh-30 fs-16 c-primary pointer" @click="toggleGroup($event, value1, `${attr}_${attr1}`)">
                <v-icon class="pr-1" small>{{ expandedGroups[`${attr}_${attr1}`] ? 'mdi-folder-outline' : 'mdi-folder' }}</v-icon>{{ attr1 }} <v-icon small>{{ expandedGroups[`${attr}_${attr1}`] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </span>
            </div>

            <div v-if="expandedGroups[`${attr}_${attr1}`]" class="pb-1">
              <div v-for="key, keyIdx of (value1.keys || [])" :key="`5_${keyIdx}`">
                <slot name="leaf" :leaf="key" :deep="2" :idx="keyIdx">
                </slot>
              </div>
            </div>

            <div v-for="(value2, attr2, idx) in value1" v-if="expandedGroups[`${attr}_${attr1}`]" :key="`6_${idx}`">
              <div v-if="attr2 !== 'keys'">
                <div class="level">
                  <span class="lh-30 fs-16 c-primary pointer" @click="toggleGroup($event, value2, `${attr}_${attr1}_${attr2}`)">
                    <v-icon class="pr-1" small>{{ expandedGroups[`${attr}_${attr1}_${attr2}`] ? 'mdi-arrow-bottom-right-thick' : 'mdi-arrow-bottom-right-thick' }}</v-icon> {{ attr2 }} <v-icon small>{{ expandedGroups[`${attr}_${attr1}_${attr2}`] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </span>
                </div>

                <div v-if="expandedGroups[`${attr}_${attr1}_${attr2}`]" class="pb-1">
                  <div v-for="key, keyIdx of (value2.keys || [])" :key="`14_${keyIdx}`">
                    <slot name="leaf" :leaf="key" :deep="3" :idx="keyIdx">
                    </slot>
                  </div>
                </div>

                <div v-for="(value3, attr3, idx) in value2" v-if="expandedGroups[`${attr}_${attr1}_${attr2}`]" :key="`8_${idx}`">
                  <div v-if="attr3 !== 'keys'">
                    <div class="level">
                      <span class="lh-30 fs-16 c-primary pointer" @click="toggleGroup($event, value2, `${attr}_${attr1}_${attr2}_${attr3}`)">
                        <v-icon class="pr-1" small>{{ expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}`] ? 'mdi-subdirectory-arrow-right' : 'mdi-arrow-right' }}</v-icon> {{ attr3 }} <v-icon small>{{ expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}`] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </span>
                    </div>

                    <div v-if="expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}`]"  class="pb-1">
                      <div v-for="key, keyIdx of (value3.keys || [])" :key="`14_${keyIdx}`">
                        <slot name="leaf" :leaf="key" :deep="3" :idx="keyIdx">
                        </slot>
                      </div>
                    </div>

                    <div v-for="(value4, attr4, idx) in value3" v-if="expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}`]" :key="`9_${idx}`">
                      <div v-if="attr4 !== 'keys'">
                        <div class="level">
                          <span class="lh-30 fs-16 c-primary pointer" @click="toggleGroup($event, value2, `${attr}_${attr1}_${attr2}_${attr3}_${attr4}`)">
                            <v-icon class="pr-1" small>{{ expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}_${attr4}`] ? 'mdi-circle-small' : 'mdi-circle-small' }}</v-icon> {{ attr4 }} <v-icon small>{{ expandedGroups[`${attr}_${attr1}_${attr2}_${attr3}_${attr4}`] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </span>
                        </div>

                        <div v-for="key, keyIdx of (value4.keys || [])" :key="`12_${keyIdx}`">
                          <slot name="leaf" :leaf="key" :deep="5" :idx="keyIdx">
                          </slot>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WTreeView",
    props: {
      level: {
        type: Array,
        required: true,
      },
      deep: {
        type: Number,
        required: false,
        default: 0,
      },
      expandDeep: {
        type: Number,
        required: false,
        default: 1,
      },
    },
    data() {
      return {
        expandedGroups: this.level.filter(key => key.path).reduce((h, key) => {
          let path = ""
          for (let i = 0; i < this.expandDeep; i++) {
            if (key.path[i]) {
              path = (path === "" ? key.path[i] : `${path}_${key.path[i]}`)
              h[path] = true
            }
          }

          return h
        }, {})
      }
    },
    computed: {
      tree() {
        const tree = { keys: [] }

        for (const key of this.level) {
          const path = key.path
          const node = (path ? this.resolvePath(tree, path) : tree)

          node.keys.push(key)
        }

        return tree
      }
    },
    methods: {
      toggleGroup(event, node, path) {
        if (event.metaKey) {
          let value = undefined

          for (const attr of Object.keys(node)) {
            if (attr !== 'keys') {
              const key = `${path}_${attr}`

              if (value === undefined) {
                value = !(this.expandedGroups[key] === true)
              }

              this.$set(this.expandedGroups, key, value)
            }
          }
        } else {
          this.$set(this.expandedGroups, path, !(this.expandedGroups[path] === true))
        }
      },
      resolvePath(tree, path) {
        let node = tree

        for (const level of path) {
          node[level] ||= { keys: [] }
          node = node[level]
        }

        return node
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-tree-view
    .level
      position: relative
      z-index: 1
</style>
