<template>
  <div>
    <v-progress-circular
      :rotate="360"
      :size="this.size"
      :width="this.width"
      :value="valueToPercent()"
      color="#2db3ab"
      class="w-stats-product-recommendation-rate-widget"
    >
      <div class="d-flex flex-column align-center">
        <span
          v-if="valueToPercent() < 1 && this.positiveValueOnly"
          class="f-30">{{ "<1%" }}
        </span>
        <span v-else
          class="c-primary f-30">
          {{ formattedValue() }}
        </span>

        <w-color-sign
          :value="evolutionToPercent()"
          :decimals="decimals"
          v-if="evolutionToPercent()"
        />
      </div>

    </v-progress-circular>
    <div
      v-if="this.nbReview != null"
      class="ml-1 sub-text f-12 c-lightgrey"
      v-html="translateNbReview()"
    >
    </div>
  </div>
</template>

<script>
  export default {
    name: "WRatingCircularWithEvol",
    props: {
      value: { type: Number, required: true },
      evolution: { type: Number, required: true },
      nbReview: { type: Number, required: false, default: null},
      decimals: { type: Number, required: false, default: 0 },
      size: { type: Number, required: false, default: 80 },
      width: { type: Number, required: false, default: 5 },
      positiveValueOnly: { type: Boolean, required: false, default: true }
    },
    methods: {
      translateNbReview() {
        return this.$t('nbReview_without_google_logo', {
          nbReview: this.nbReview?.toLocaleString()
        })
      },
      valueToPercent() {
        return (this.value * 100).toFixed(this.decimals)
      },
      evolutionToPercent() {
        return (this.evolution * 100).toFixed(this.decimals)
      },
      formattedValue() {
        return this.valueToPercent()
      }
    }
  }

</script>
