import WizClient from "@shared/http/wizClient"

async function load_profile(file) {
  let formData = new FormData();
  formData.append('file', file);

  const response = await WizClient.post("/open_ai/load_profile", formData)

  return response
}

async function run_verbatim_annotations(profile, verbatim_annotations, ignore_new) {
  const response = await WizClient.post("/open_ai/run_verbatim_annotations", {
    verbatim_annotations,
    ignore_new,
    profile
  })

  return response
}

async function verbatim_annotations_stats(profile, verbatim_annotations) {
  const response = await WizClient.post("/open_ai/verbatim_annotations_stats", {
    verbatim_annotations,
    profile
  })

  return response
}

async function generate_scenario(campaignId, taxonomy, options) {
  const response = await WizClient.post("/open_ai/generate_scenario", {
    campaignId,
    taxonomy,
    options
  })

  return response
}

export { run_verbatim_annotations, load_profile, verbatim_annotations_stats, generate_scenario }
