// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyConfirm from 'vuetify-confirm'
import WComponents from '@theme/components'

import { en, fr } from 'vuetify/es5/locale'

Vue.use(Vuetify)

const colors = {
  primary: '#1E547B',
  secondary: '#2DB3AF',
  accent: '#087CA7',
  error: '#C86868',
  success: '#65c095',
  info: '#2db3ab',
  warning: '#ffd333',
  lightgrey: '#8A8A8A',
  gold: '#FFCA40',
  borderblue: '#5659BE',
  violet: '#6771C0',
  red: '#D34E6E',
  score100: '#5CC1A8',
  score90: '#8BCF72',
  score80: 'ACD55E',
  score70: 'CACD49',
  score60: 'E7CA44',
  score50: 'E5B944',
  score40: 'E7993C',
  score30: 'DB7233',
  score20: 'D1552C',
  score10: 'C33126',
  score0: 'C33126'

}


Vue.prototype.$colors = colors

const opts = {
  iconfont: 'mdi',
  lang: {
    locales: { fr, en },
    current: 'fr',
  },
  theme: {
    themes: {
      light: colors,
    },
  },
  options: { 
    themeCache: {
      get: key => localStorage.getItem(key),
      set: (key, value) => localStorage.setItem(key, value),
    }
  }
}

const vuetify = new Vuetify(opts)
Vue.use(VuetifyConfirm, { vuetify })

for (const componentName in WComponents) {
  Vue.component(componentName, WComponents[componentName])
}

require('@theme/wizville.styl')

export default new Vuetify(opts)
