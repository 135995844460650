<template>
  <div class="w-group-table">
    <v-data-table
      class="v-datatable-no-group-color"
      :headers="headers"
      :items="items"
      :groupBy=groupBy
      :options.sync="options"
      :hideDefaultFooter="hideDefaultFooter"
      :disablePagination="disablePagination"
      :hideDefaultHeader="hideDefaultHeader"
      :fixedHeader="fixedHeader"
      :scrollable="scrollable"
      :height="height"
      :loading="loading"
      mobileBreakpoint="0"
    >
      <template v-slot:body="{ items, groupedItems, headers }">
        <tbody v-if="groupBy" ref="tBody">
          <template
            v-for="(groupItems, index) in groupedItems"
          >
            <tr class="group-row">
              <td v-for="(header, index) in headers" :key="index">
                <div class="d-flex align-center" :class="getAlignment(header)" :style="getWidthColumn(header)">
                  <span class="d-flex align-center justify-center" :style="{ width: '35px'}" v-if="displayZoomAction(index)">
                    <v-btn class="v-btn-square" :icon="true" color='primary'>
                      <v-icon small>mdi-eye-outline</v-icon>
                    </v-btn>
                  </span>
                  <span v-if="displayDropdownAction(index)" :style="{ width: '35px'}">
                    <v-btn icon small @click="toggleGroup(groupItems.name, isGroupOpen(groupItems.name))" style="position: relative; bottom: 1px" :ripple="false">
                      <v-icon small v-if="!isGroupOpen(groupItems.name)">mdi-chevron-down</v-icon>
                      <v-icon small v-else>mdi-chevron-up</v-icon>
                    </v-btn>
                  </span>
                  <slot :groupItems="groupItems" :groupBy="groupItems.name" :header="header" :name="headerGroupColumnName(header)">
                    <div>
                      <span class="fs-13">{{ getNonSlotValue(groupItems, header) }}</span>
                    </div>
                  </slot>
                </div>
              </td>
            </tr>
            <draggable
              v-if="groupBy"
              :list="groupedDraggableList[groupItems.name]"
              :disabled="!draggable"
              tag="tr"
              class="wrapper"
              :group="{ name: 'draggableGroup' }"
              :handle="handleDragAndDropClass"
              @start="onStartCallback"
              @end="onEndCallback"
              :move="onMoveCallback"
            >
            <tr v-if="isGroupOpen(groupItems.name)" v-for="(item, index) in groupedDraggableList[groupItems.name]" class="item-row not-active">
              <td v-for="(header, index) in headers"
                :key="index"
                :style=getWidthColumn(header)
                class="handle-drag-and-drop px-4"
              >
                <div class="d-flex align-center" :class="getAlignment(header)" :style="getWidthColumn(header)">
                  <span v-if="isFirstColumn(index)" class="px-2" :style="{ width: '35px'}"></span>
                  <span class="d-flex justify-center" v-if="displayZoomAction(index)" :style="{ width: '35px'}">
                    <v-btn class="v-btn-square" :icon="true" color='primary'>
                      <v-icon small>mdi-eye-outline</v-icon>
                    </v-btn>
                  </span>
                  <slot :item="item" :name="itemColumnName(header)">
                    <div class="d-inline-block">
                      <span class="fs-13">{{ getNonSlotValue(item, header) }}</span>
                    </div>
                  </slot>
                </div>
              </td>
            </tr>
            </draggable>
          </template>
          <slot name="body.append">
          </slot>
        </tbody>
        <draggable
          v-else
          :list="draggableList"
          :disabled="!draggable"
          tag="tbody"
          :handle="handleDragAndDropClass"
          @start="onStartCallback"
          @end="onEndCallback"
          :move="onMoveCallback"
        >
          <template
            v-for="(item, index) in draggableList"
          >
            <tr>
              <td v-for="(header, index) in headers"
                :key="index"
                :style=getWidthColumn(header)
                class='handle-drag-and-drop px-4'
              >
                <div class="d-flex align-center">
                  <div :class="getAlignment(header)">
                    <slot :item="item" :header="header" :value="item[header.value]" :name="itemColumnName(header)">
                      <div class="d-flex align-center">
                        <span>{{ getNonSlotValue(item, header) }}</span>
                      </div>
                    </slot>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import _uniq from 'lodash/uniq'
  import draggable from "vuedraggable";
  import _orderBy from 'lodash/orderBy'

  export default {
    name: 'WDataTable',
    components: { draggable },
    props: {
      headers: { required: true },
      items: { required: false },
      groupBy: { type: String, required: false, default: null },
      draggable: { type: Boolean, required: false, default: false },
      dropdownAction: { type: Boolean, required: false, default: true },
      zoomAction: { type: Boolean, required: false, default: false },
      dragAndDropClass: { type: String, required: false },
      hideDefaultFooter: { type: Boolean, required: false, default: false },
      hideDefaultHeader: { type: Boolean, required: false, default: false },
      disablePagination: { type: Boolean, required: false, default: false },
      scrollable: { type: Boolean, required: false, default: false },
      fixedHeader: { type: Boolean, required: false, default: false},
      height: { type: String, required: false },
      loading: { type: Boolean, required: false, default: false },
      softReload: { type: Boolean, required: false, default: false },
    },
    data() {
      return {
        allowDrag: true,
        groupedDraggableList: [],
        initItems: [],
        draggableList: [],
        expandedItems: [],
        options: { sortBy: [], sortDesc: [] },
        sortBy: null,
        sortDesc: false,
        draggedItem: null,
        destinationItem: null
      }
    },
    computed: {
      handleDragAndDropClass() {
        return this.dragAndDropClass ? `.${this.dragAndDropClass}` : '.handle-drag-and-drop'
      },
      itemsGroupBy() {
        return this.items.reduce((accumulator, obj) => {
          if (!accumulator[obj[this.groupBy]]) {
              accumulator[obj[this.groupBy]] = []
          }
          accumulator[obj[this.groupBy]].push(obj)

          return accumulator
        }, {})
      },
      groupBys() {
        return _uniq(this.items.map(item => item[this.groupBy]))
      }
    },
    methods: {
      initDatas() {
        if (this.groupBy) {
          this.groupedDraggableList = this.itemsGroupBy
          this.expandedItems = this.groupBys
        } else {
          this.draggableList = this.items
        }
      },
      sortGroupedDragabbleList(sortBy, sortDesc) {
        let sortedGroupedDraggableList = {}
        this.groupedDraggableList = this.groupedDraggableList
        for (const groupBy in this.groupedDraggableList) {
          sortedGroupedDraggableList[groupBy] = _orderBy(this.groupedDraggableList[groupBy], [sortBy], [sortDesc ? 'desc' : 'asc'])
        }

        this.groupedDraggableList = sortedGroupedDraggableList
      },
      isFirstColumn(index) {
        return index === 0
      },
      displayDropdownAction(index) {
        return this.dropdownAction && this.isFirstColumn(index)
      },
      displayZoomAction(index) {
        return this.zoomAction && this.isFirstColumn(index)
      },
      isGroupOpen(group) {
        return this.expandedItems.includes(group)
      },
      toggleGroup(group, isGroupOpen) {
        if (isGroupOpen) {
          this.expandedItems = this.expandedItems.filter(item => item !== group)
        } else {
          this.expandedItems.push(group)
        }

        this.$nextTick(() => {
          this.$emit('toggleGroup')
        })
      },
      onStartCallback() {
        this.$emit('draggableOnStartCallback')
      },
      onMoveCallback(e) {
        this.movedItem = e.draggedContext.element
        this.destinationItem = e.relatedContext.element
        this.$emit('draggableOnMoveCallback', this.movedItem, this.destinationItem, e.draggedContext.futureIndex)
      },
      onEndCallback(e) {
        this.$emit('draggableOnEndCallback', this.movedItem, this.destinationItem, e.newIndex)
        this.movedItem = null
        this.destinationItem = null
      },
      itemColumnName(header) {
        return `item.${header.value}`;
      },
      headerGroupColumnName(header) {
        return `headerGroupItem.${header.value}`;
      },
      getAlignment(header) {
        if (header.align === "right") {
          return "justify-end"
        }
        return "justify-start"
      },
      getWidthColumn(header) {
        if (header.width) {
          return `width: ${header.width}`;
        } else {
          return "width: auto"
        }
      },
      getNonSlotValue(item, header) {
        const val = item[header.value];

        if (val) {
          return val;
        }
        return "";
      }
    },
    watch: {
      options: {
        handler (newOptions) {
          this.sortGroupedDragabbleList(newOptions.sortBy[0], newOptions.sortDesc[0])
        },
        deep: true
      },
      items: {
        handler (newItems, oldItems) {
          if ((newItems.length !== oldItems.length) || !this.softReload) {
            this.initDatas()
          }
        },
        deep: true
      },
    },
    mounted () {
      this.initDatas()
    }
  }
</script>

<style lang="stylus" scoped>
  .w-group-table .wrapper
    display: contents

    .not-active 
      background-color: #F7F9FA !important;
      cursor: pointer
    .active
      background-color: #E4F7F5 !important;
      cursor: pointer

    td
      border-bottom: thin solid rgba(0,0,0,.12)
      height: 48px
</style>
