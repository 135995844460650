<template>
  <div
    class="link flex-grow-1"
    :class="{ 'sub-nav': properties.isSubNav }"
  >
    <a
      class="d-flex align-center flex-grow-1 nowrap"
      v-bind="properties.attrs"
      v-on="properties.on"
      :active="properties.isActive"
      @click="onClicked"
      :class="linkClass"
    >
      <div
      class='d-flex align-center'
      :class="[properties.isSubNav ? 'mr-2' : 'mr-1']"
      >
        <v-icon
          v-if="properties.icon"
          class="f-14 mr-1"
          :class="{ active: properties.isActive, inactive: !properties.isActive }"
        >
          {{ properties.icon }}
        </v-icon>

        <w-icon
          v-if="properties.wicon"
          :icon='properties.wicon'
          :icon-style="{ fontSize: '14px' }"
          class="f-14 mr-1"
          :class="{ active: properties.isActive, inactive: !properties.isActive }"
        />

        <span
          :class="{ 'mr-auto': properties.isSubNav }"
        >
          {{ title }}
        </span>

        <div v-if="properties.childNav && properties.isActive">
          <v-icon
            class="f-14"
            v-if="open"
            :class="{ active: properties.isActive, inactive: !properties.isActive }"
          >
            mdi-chevron-up
          </v-icon>
          <v-icon
            v-else
            class="f-14"
            :class="{ active: properties.isActive, inactive: !properties.isActive }"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </div>

      <v-chip
        v-if="properties.optionEls && properties.optionEls.badge"
        x-small
        class="ml-auto c-white px-2"
        :color="badgeColor"
      >
        {{ badgeContent }}
      </v-chip>
    </a>
  </div>
</template>

<script>
  export default {
    name: "Link",
    data() {
      return {
        open: false
      }
    },
    props: {
      properties: {
        type: Object,
        required: true
      },
      replaceTitle: {
        type: false,
        required: false,
        default: true
      }
    },
    computed: {
      title() {
        return this.replaceTitle ?
          this.$t(this.properties.subNavTitle) || this.$t(this.properties.title) :
          this.$t(this.properties.title)
      },
      linkClass() {
        return [
          {
            'active': this.properties.isActive,
            'inactive': !this.properties.isActive,
            'border-bottom': this.properties.isActive && !this.properties.isSubNav
          },
          this.properties.className
        ]
      },
      badge() {
        if (this.properties?.subNavBadge) {
          return this.properties.subNavBadge
        } else {
          return this.properties.optionEls?.badge
        }
      },
      badgeContent() {
        if (this.replaceTitle) {
          return this.badge?.content
        } else {
          return this.properties.optionEls?.badge?.content
        }
      },
      badgeColor() {
        if (this.replaceTitle) {
          return this.badge?.color
        } else {
          return this.properties.optionEls?.badge?.color
        }
      }
    },
    methods: {
      onClicked(e) {
        if (this.properties.isActive) {
          this.toogleMenu()
        } else {
          this.$emit("activated", this.properties.index)
          this.properties.navigate(e)
        }
      },
      toogleMenu() {
        this.open = !this.open
      }
    },
    mounted() {
      if (this.properties.isSubNav && this.properties.isActive) {
        this.$emit("activated", this.properties.index)
      }
    },
  }
</script>

<style lang="stylus">
  @import '~@theme/colors.styl'
  .link
    a
      padding-bottom: 2px
    .active
      color: var(--info)
      &.border-bottom
        border-bottom: var(--info) 2px solid
    .inactive
      color: var(--lightgrey)
    &.sub-nav
      .v-badge__wrapper
        margin-left: 14px
        margin-right: 0px
</style>
