<template>
  <v-tooltip top class="w-tooltip" :disabled="disabled">
    <template #activator="{ on }">
      <slot name="default" :on="on">
        <span v-on="on">
          {{ text }} <v-icon v-if="icon" :color="smartColor" class="w-tooltip-help-icon" small>{{ icon }}</v-icon>
        </span>
      </slot>
    </template>

    <slot name="message">
      <span v-html="formatedMessage" />
    </slot>
  </v-tooltip>
</template>

<script>
  export default {
    name: "WTooltip",
    props: {
      disabled: { type: Boolean, required: false, default: false },
      message: { type: String, required: false, default: "" },
      text: { type: String, required: false, default: "" },
      color: { type: String | null, required: false, default: null },
      icon: { type: String | null, required: false, default: "mdi-help-circle-outline" }
    },
    computed: {
      formatedMessage() {
        return this.message.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>')
      },
      smartColor() {
        if (this.color) {
          return this.color
        }

        if (['mdi-cancel', 'mdi-bug'].includes(this.icon)) {
          return "error"
        } else if (['mdi-export'].includes(this.icon)) {
           return "info"
        } else if (['mdi-send', 'mdi-refresh'].includes(this.icon)) {
           return "success"
        } else {
          return "lightgrey"
        }
      }
    }
  }
</script>

<style lang="stylus">
  .w-tooltip-help-icon
    position: relative
    bottom: 2px
    cursor: pointer
</style>
