import ApiCall from '@api/apiCall.js'

function dashboardBasePath(dashboardType, dashboardId) {
  if (dashboardType === "DashboardBasic" || dashboardType === "mono") {
    return `/api/wizville/dashboard/mono/${dashboardId}`
  } else if (dashboardType === "DashboardMulti" || dashboardType === "multi") {
    return `/api/wizville/dashboard/multi/${dashboardId}`
  } else {
    throw new Error(`Bad dashboardType: ${dashboardType}`)
  }
}

export async function update(dashboardType, dashboardId, params) {
  const response = await new ApiCall().put(
    `${dashboardBasePath(dashboardType, dashboardId)}/update`, {
    dashboardId,
    dashboardType,
    dashboard: params
  });

  return response.data
}

export async function setFilters(dashboardType, dashboardId, filters) {
  const response = await new ApiCall().post(
    `${dashboardBasePath(dashboardType, dashboardId)}/set_filters`,
    { filters }
  )

  return response.data.filters
}