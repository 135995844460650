<template>
  <span class="w-trunc">
    <span v-if="truncated">
      {{ text }}
    </span>

    <v-tooltip top transition="none" v-else>
      <template #activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs" style="cursor: help">
          {{  truncatedText }}
        </span>
      </template>

      {{ text }}
    </v-tooltip>
  </span>
</template>

<script>
  export default {
    name: "WTrunc",
    props: [ 'text', 'limit' ],
    computed: {
      truncated() {
        return this.text.length <= this.limit
      },
      truncatedText() {
        return (this.text.length <= this.limit ? this.text : this.text.slice(0, this.limit) + '...')
      }
    }
  }
</script>

<style lang='stylus' scoped>
</style>
