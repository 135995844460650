<template>
  <v-card
    :elevation="1"
    class="mx-auto"
    min-height="165"
    outlined
  >
    <v-row>
      <v-col>
        <slot name="title">    
          <span class="fs-14 pa-3">{{this.title}}</span>
        </slot>
      </v-col>
      <v-col v-if="dropdown" class="pt-1">
      <slot name="dropdown">
        <div class="d-flex justify-end pr-3">
           <v-select
            class="wv-select ma-0 pa-0 sm"
            style="max-width: 100px;"
            :items="dropdown.items"
            v-model="dropdownItemSelected"
            @change="dropdown.onChangeHandler"
            :label="dropdown.label"
            single-line
          ></v-select>
        </div>
      </slot>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <slot name="main-section"/>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  
  export default {
    name: "WWidget",
    
    props: {
      title: {
        type: String,
        required: true
      },
      dropdown: {
        onChangeHandler: { type: Function, required: true },
        label: { type: String, required: false },
        items: { type: Object, required: false }
      },
    },
    data() {
      return {
        dropdownItemSelected: null,
      }
    },
  }
  
</script>

<style lang='stylus'>
</style>
