<template>
  <v-slider
    class="w-score-range"
    :value="value"
    :thumbColor="color"
    :trackColor="color"
    :trackFillColor="color"
    thumb-label="always"
    thumb-size="20"
    hide-details
    height="30"
    readonly
  >
    <template #prepend="">
      {{ scoreMin }}
    </template>

    <template #thumb-label="">
      <div class="w-thumb-label">
        {{ score | round1 }}
      </div>
    </template>
    
    <template #append="">
      {{ scoreMax }}
    </template>
  </v-slider>
</template>

<script>
  export default{
    name: "WScoreRange",
    props: {
      value: { type: Number, required: true }, 
      score: { type: Number, required: true }, 
      rangeScoreMin: { type: Number, required: true },
      rangeScoreMax: { type: Number, required: true },
      decimals: { type: Number, required: false, default: 1 },
      color: { type: String, required: false, default: "primary" }
    },
    computed: {
      scoreMin() {
        return this.rangeScoreMin?.toFixed(this.decimals)
      },
      scoreMax() {
        return this.rangeScoreMax?.toFixed(this.decimals)
      }
    }
  }
</script>

<style lang='stylus'>
</style>