<template>
  <div class="w-tree-select-checkbox d-flex align-center pr-2"
    v-if="!item.hidden"
    :class="{ 'active': item.active && !item.disabled, disabled: item.disabled }"
    :style="itemStyle"
    @mouseover="onOver"
    @mouseleave="onLeave"
  >
    <div>
      <v-checkbox
          class="mt-0 pt-0"
          :class="{ 'visually-hidden-checkbox': semanticPresence }"
          :ripple="false"
          dense
          hide-details
          @change="onChange"
          :input-value="isActive"
          :disabled="isDisabled"
          :color="'#087CA7'"
      >
        <template #label="{ item }">
        </template>
      </v-checkbox>
    </div>

    <div class="d-flex align-center pointer" @click="onExpand" style="min-height: 30px">
      <div class="w-tree-select-checkbox-label">
        <span class="fs-13">
          <slot name="label" :item="item">
          </slot>
         </span>
      </div>

      <div v-if="!item.isLeaf">
        <span>
          <slot name="expand" :item="item">
            <v-btn icon small :ripple="false" :color="hover && item.active && !item.disabled ? 'white' : 'primary'">
              <v-icon small> {{ item.expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
          </slot>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WTreeSelectCheckbox",
    props: {
      item: {
        type: [Object, Array],
        required: true
      },
      level: {
        type: [Number, String],
        required: true
      },
      semanticPresence: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        hover: false
      }
    },
    computed: {
      isActive() {
        return this.item.active || false
      },
      isDisabled() {
        return this.item.disabled || false
      },
      isExpanded() {
        return this.item.expanded || false
      },
      value() {
        return this.item.value
      },
      itemStyle() {
        return {
          paddingLeft: `${this.level * 15 + 5}px`,
          paddingTop: '2px',
          paddingBottom: '2px'
        }
      },
    },
    methods: {
      onOver() {
        this.hover = true
      },
      onLeave() {
        this.hover = false
      },
      onChange() {
        this.$emit("change", this.value)
      },
      onExpand() {
        if (!this.item.isLeaf) {
          this.$emit("expand", this.value)
        }
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .visually-hidden-checkbox
    display: none
  .w-tree-select-checkbox
    background-color: white

    .w-tree-select-checkbox-label
      color: var(--primary)

    &:hover
      background-color: var(--palegrey)

  .w-tree-select-checkbox.active, .w-tree-select-checkbox.disabled
    background-color: var(--verylightgrey)

  .w-tree-select-checkbox.active
    &:hover
      background-color: var(--primary)

      .w-tree-select-checkbox-label
        color: white

</style>
