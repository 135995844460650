<template>
  <v-list-item
    :class="{'w-side-bar-list-item': true, 'v-list-item--active active-item': item.to.name === $route.name}"
    :to="item.to"
    :ripple="false"
    color="info"
    v-if="item.show"
    linkmenu
  >
    <v-list-item-icon>
      <v-icon :class="itemColor" dense>{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class="fs-15" :class="itemColor">
        <v-badge class="mt-0" inline :value="item.notifications || 0" :content="item.notifications">
          {{ $t(item.title) }}
        </v-badge>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: "WSideBarListItem",
    props: [ 'item' ],
    computed: {
      itemColor() {
        return (this.item.color ? `c-${this.item.color}` : 'c-primary')
      }
    }
  }
</script>

<style lang="stylus" scoped>
 
</style>
