<template>
  <div class="w-transaction">
    <WSandbox 
      :request="transaction.context.debug()" 
      :default-result="result" 
      :scope="scope" 
      height="calc(50vh - 26px)" 
    />
  </div>
</template>

<script>
  export default {
    name: 'WTransaction',
    props: [ 'transaction', 'scope' ],
    computed: {
      result() {
        if (this.transaction.status === 'ok') {
          return this.transaction?.result?.data
        } else if (this.transaction.status === 'ko') {
          return this.transaction?.result?.response?.data
        } else {
          return ""
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .w-transaction
    overflow: auto
    border-bottom: thin solid rgba(0, 0, 0, 0.12)
</style>
