<template>
  <v-btn
    class="wiz-profiler-badge"
    elevation="2"
    @click="$emit('toggle')"
  >
    <v-badge :right="true" :inline="true" :content="String(nbTransactionsRunning)" color="grey"></v-badge>
    <v-badge :left="true" :inline="true" :content="String(nbTransactionsKo)" color="error"></v-badge>
    <v-badge :right="true" :inline="true" :content="String(nbTransactionsOk)" color="success"></v-badge>
  </v-btn>
</template>

<script>
  import { transactions } from '@api/transaction.js'

  export default {
    name: 'WProfilerBadge',
    props: [ 'nbTransactionsRunning', 'nbTransactionsOk', 'nbTransactionsKo' ]
  }
</script>

<style lang="stylus" scoped>
  .wiz-profiler-badge
    position: fixed
    left: 50%
    transform: translateX(-50%)
    bottom: 20px
    z-index: 10000
</style>
