import ApiCall from '@api/apiCall.js'

export async function create(campaignId, formattedProductMapping, productImportPreference) {
  return (await new ApiCall().post(`/api/wizville/product_import_preferences/${campaignId}/create`, {
    formattedProductMapping,
    productImportPreference,
  })).data
}

// shouldn't be here ?
export async function mappingColumns(campaignId, feedUrlByLocale) {
  return (await new ApiCall().post(`/api/wizville/product_import_preferences/${campaignId}/mapping_columns`,
    feedUrlByLocale
  )).data
}

export async function exportProducts(campaignId) {
  return (await new ApiCall().post(`/api/wizville/product_import_preferences/${campaignId}/export_products`)).data
}

export async function productsSample(campaignId) {
  return (await new ApiCall().post(`/api/wizville/product_import_preferences/${campaignId}/products_sample`)).data
}

export async function productsStats(campaignId) {
  return (await new ApiCall().post(`/api/wizville/product_import_preferences/${campaignId}/products_stats`)).data
}
