<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6">
        <div>
          <WCode v-model="basedRequestContent" :key="resetCounter" :readonly="readonly" :style="{ height: height }" style="overflow: auto;" />
        </div>

        <div class="text-align-right">
          <v-btn icon color="error" @click="resetRequest()" v-if="!readonly">
            <v-icon>
              mdi-restart-alert
            </v-icon>
          </v-btn>

          <v-btn icon color="error" @click="cancelRequest()" v-if="!readonly">
            <v-icon>
              mdi-cancel
            </v-icon>
          </v-btn>

          <v-btn icon color="success" :loading="exportLoading" @click="exportRequest(basedRequestContent)">
            <v-icon>
              mdi-export
            </v-icon>
          </v-btn>

          <v-btn icon color="success" :loading="sendLoading" @click="evalRequest(basedRequestContent)">
            <v-icon>
              mdi-send
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6">
        <iframe v-if="isHtml" :srcdoc='formatedResponse' :style="{ height: height }" class="full-width"></iframe>
        <WCode v-else :value="formatedResponse" :key="formatedResponse" :readonly="true" :style="{ height: height }" style="overflow: auto;" />
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import _debounce from "lodash/debounce"
  import ApiCall from '@api/apiCall.js'
  import BasedQuery from "@api/based/basedQuery"
  import removeExtraIndentation from '@shared/helpers/indent'

  export default{
    name: "WBasedSandbox",
    props: {
      autostart: { type: Boolean, required: false, default: false },
      request: { type: String, required: false, default: "" },
      scope: { type: String, required: false, default: "admin" },
      readonly: { type: Boolean, required: false, default: false },
      horizontal: { type: Boolean, required: false, default: false },
      height: { type: String, required: false, default: "auto" },
      defaultResult: { type: Object, required: false }
    },
    data() {
      return {
        basedRequestContent: this.request,
        defaultBasedRequest: this.request,
        sendLoading: false,
        exportLoading: false,
        resetCounter: 0,
        result: null,
        status: null
      }
    },
    computed: {
      response() {
        return this.result || this.defaultResult || {}
      },
      isHtml() {
        return typeof(this.response) === 'string' && this.response.match(/^<!DOCTYPE html>/)
      },
      formatedResponse() {
        try {
          if (this.isHtml) {
            return this.response
          } else {
            return JSON.stringify(this.response, null, '\t')
          }
        } catch (error) {
          return ""
        }
      }
    },
    methods: {
      autoIndentRequest() {
        if (this.basedRequestContent) {
          this.basedRequestContent = removeExtraIndentation(this.basedRequestContent).trim()
        }
      },
      evalRequest: _debounce(async function(content) {
        localStorage.evalRequest = content
        this.sendLoading = true

        try {
          let AsyncFunction = Object.getPrototypeOf(async function(){}).constructor
          
          const query = `
            async function run(basedQuery) {
              try { 
                ${content}
              } catch(error) { 
                return ({ message:  (error.response ? error.response.data.message : error.message), status: "ko" })
              }
            }
            return run(basedQuery)
          `
          let sandbox = new AsyncFunction('basedQuery', 'ApiCall', query)

          this.result = await sandbox(new BasedQuery({ scope: this.scope }), ApiCall)
        } catch (error) {
          this.result = { status: "ko", message: `js syntax error: ${error.message}` }
        }

        this.sendLoading = false
      }, 1000, { leading: true }),

      exportRequest: _debounce(async function(content) {
        this.exportLoading = true
        await this.evalRequest(content)
        this.$textToFile("based_request_export.json", this.formatedResult)

        this.exportLoading = false
      }, 1000, { leading: true }),

      resetRequest() {
        this.basedRequestContent = this.defaultBasedRequest
        this.autoIndentRequest()
        this.result = null
        this.resetCounter += 1
      },

      cancelRequest() {
        this.result = ""
      }
    },
    created() {
      this.autoIndentRequest()

      if (this.autostart == true) {
        this.evalRequest(this.request)
      }
    }
  }
</script>
