import _omit from 'lodash/omit'

import posthogService from './posthog'
import noTrackService from './notrack'

import { production } from "@shared/helpers/env-helpers.js"
import api from '@api'

import {
  serializeCurrentUser,
  serializeCurrentDashboard,
  serializeCurrentVoter
} from './contexts'

const PAGE_OMITED_KEYS = [
  'matched',
  'meta',
  'query.dashboard_filters'
]

export function trackUser() {
  if (ignoreActivityTracking()) {
    return
  }

  const currentUser = $store.getters.currentUser
  
  analyticsService().trackUser(currentUser?.id, serializeCurrentUser())
}

export function resetUser() {
  if (ignoreActivityTracking()) {
    return
  }
  
  analyticsService().resetUser()
}

export function trackPageView(to, from) {
  if (ignoreActivityTracking()) {
    return
  }

  const data = {
    to: buildPageData(to),
    from: buildPageData(from),
    dashboard: serializeCurrentDashboard(),
    voter: serializeCurrentVoter()
  }

  analyticsService().trackPageView(data)
}

export async function trackEvent(eventName, data) {
  if (ignoreActivityTracking()) {
    return
  }

  const event = trackingEvent(eventName)
  if (event) {
    if (event.tracked) {
      const eventData = {
        ...data,
        dashboard: serializeCurrentDashboard(),
        voter: serializeCurrentVoter()
      }

      analyticsService().trackEvent(event.label, eventData)
    }
  } else {
    await createTrackingEvent(eventName)
  }
}

function buildPageData(page) {
  return _omit(page, PAGE_OMITED_KEYS)
}

function analyticsService() {
  return !production ? noTrackService : posthogService
}

function ignoreActivityTracking() {
  const currentUser = $store.getters.currentUser
  const currentAdmin = $store.getters.currentAdmin
  
  return currentUser?.id == null ||
    currentUser?.isAdmin ||
    currentAdmin?.id != null
}

function trackingEvent(eventName) {
  const trackingEvents = $store.getters?.trackingEvents
  
  return trackingEvents.find(event => event.name === eventName)
}

async function createTrackingEvent(eventName) {
  await api.wizville.trackingEvents.create({
    name: eventName,
    label: null,
    type: 'ApiTrackingEvent',
    tracked: false
  })
}
