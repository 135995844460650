<template>
  <v-container fluid class="text-analysis-preferences">
    <WFormLayout v-if="ready" v-model="valid" class="openai-preferences px-3 my-3">
      <template #header />
      <template #content>
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="f-16 mb-2 c-primary">
              {{ $t('text_analysis_preference') }} <WTooltip :message="$t('text_analysis_activation_tooltip')"/>
            </h4>

            <v-btn-toggle
              v-model="textAnalysisPreference.enabled"
              mandatory
              color="info"
              flat
              outlined
            >
              <v-btn :value="false" class="v-btn v-size--default ">
                <span>OFF</span>
              </v-btn>
                <v-btn :value="true" class="v-btn v-size--default">
                <span>ON</span>
              </v-btn>
            </v-btn-toggle>

            <div class="f-14 mt-3" v-if="annotationStatus">
              <span v-if="beginDate !== undefined"><span class="fs-14">{{ $t('first_annotate') }}:</span> {{ beginDate | ddmmyyyy }}<br /></span>
              <span v-if="nbOk !== undefined"><span  class="fs-14">{{ $t('openai_preferences_annotated_verbatim') }}:</span> {{ nbOk }}<br /></span>
              <span v-if="nbKo !== undefined"><span class="fs-14">{{ $t('openai_preferences_error_verbatim') }}:</span> {{ nbKo }}</span>
            </div>

            <h4 class="f-16 mb-2 mt-2 c-primary">{{ $t('openai_preferences_prompt_customisation') }}</h4>
            <PromptHelpSection/>
            <PromptFirstParagraph
              v-if="textAnalysisPreference.promptFirstParagraph !== undefined"
              v-model="textAnalysisPreference.promptFirstParagraph"
              :defaultPromptFirstParagraph="defaultPromptFirstParagraph"
            />
            <PromptExamples
              v-if="textAnalysisPreference.promptExamples !== undefined"
              v-model="textAnalysisPreference.promptExamples"
              :defaultPromptExamples="defaultPromptExamples"
            />

            <div class="d-flex justify-center mt-4">
              <a
                class="text-decoration-underline c-dark"
                @click="showFinalPrompt"
              >
                {{ $t('openai_preferences_show_final_prompt') }}
              </a>
            </div>
            <FullPromptDialog
              v-model="fullPromptDialogShow"
              :prompt="fullPrompt"
            />

            <WHr />
            <Reannotation />
          </v-col>

          <v-col cols="12" md="6" v-if="base.taxonomy">
            <v-row>
              <v-col>
                <h4 class="f-16 c-primary">{{ $t('openai_preferences_taxonomy_table_title') }}</h4>
                <router-link
                  :to="{ name: 'CampaignTranslations', params: { feature: 'text_analysis' } }"
                  class="f-14 c-darkergrey font-italic"
                >
                  {{ $t('openai_preferences_taxonomy_table_translations_link') }}
                </router-link>
              </v-col>
            </v-row>
            <TaxonomyTable
              v-model="textAnalysisPreference.taxonomy"
              :locale="selectedLocale"
              :taxonomyName="taxonomyName"
              :taxonomyOptions="taxonomyOptions"
              groupBy="themeId"
              @updateReferenceTaxonomy="updateReferenceTaxonomy"
              @updateReloadTaxonomies="updateReloadTaxonomies"
            />
          </v-col>
        </v-row>
      </template>

      <template #footer-left>
        <div class="d-flex">
          <v-btn
            color="primary"
            @click="save"
            :loading="saving"
          >
            {{ $t("save") }}
          </v-btn>
        </div>
      </template>
    </WFormLayout>
    <v-skeleton-loader v-else type="paragraph" />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _differenceBy from "lodash/differenceBy"
  import _cloneDeep from "lodash/cloneDeep"
  import _merge from "lodash/merge"
  import PromptHelpSection from "./PromptHelpSection"
  import PromptFirstParagraph from "./PromptFirstParagraph"
  import PromptExamples from "./PromptExamples"
  import FullPromptDialog from "./FullPromptDialog"
  import TaxonomyTable from "./TaxonomyTable"
  import Reannotation from "./Reannotation"

  export default {
    name: 'TextAnalysisPreference',
    components: {
      PromptHelpSection,
      PromptFirstParagraph,
      PromptExamples,
      FullPromptDialog,
      TaxonomyTable,
      Reannotation
    },
    data() {
      return {
        taxonomyName: 'default',
        textAnalysisPreference: {
          enabled: false,
          taxonomy: undefined,
          promptFirstParagraph: undefined,
          promptExamples: undefined
        },
        base: {
          enabled: undefined,
          taxonomy: undefined,
          promptFirstParagraph: undefined,
          promptExamples: undefined
        },
        taxonomies: undefined,
        saving: false,
        valid: false,
        reloadTaxonomies: 0,
        fetchingTaxonomies: false,
        defaultPromptFirstParagraph: undefined,
        defaultPromptExamples: undefined,
        fullPromptDialogShow: false,
        fullPrompt: undefined,
        timer: null,
        scheduling: false,
        heartBeat: 0,
        locale: null,
      }
    },
    async created() {
      this.defaultPromptFirstParagraph = await this.asyncFetchDefaultPromptFirstParagraph()
      this.defaultPromptExamples = await this.asyncFetchDefaultPromptExamples()
      this.initTextAnalysisPreference()
      this.timer = setInterval(() => { this.heartBeat += 1 }, 10000)
    },
    beforeUnmount () {
      this.cancelAutoUpdate()
    },
    computed: {
      ...mapGetters([
        'freeQuestionOptions',
        'currentCampaignPreferencesCampaignId',
        'currentUser',
        'currentCampaignPreferences',
      ]),
      selectedLocale: {
        get() {
          // Currently it has been decided that the Master Locale is arbitrarily `fr`.
          return this.locale || 'fr'
        },
        set(value) {
          this.locale = value
        }
      },
      taxonomyOptions() {
        if (this.taxonomies && !this.fetchingTaxonomies) {
          return Object.keys(this.taxonomies).map(taxonomy => ({
            text: taxonomy,
            value: taxonomy
          }))
        }
      },
      ready() {
        return this.taxonomies && this.taxonomyName
      },
      nbOk() {
        return this.annotationStatus?.nbOk
      },
      nbKo() {
        return this.annotationStatus?.nbKo
      },
      nbScheduled() {
        return this.annotationStatus?.nbScheduled
      },
      status() {
        return this.annotationStatus?.status
      },
      beginDate() {
        return this.annotationStatus?.beginDate
      }
    },
    asyncComputed: {
      annotationStatus: {
        watch: ['heartBeat'],
        async get() {
          const request = this.$basedRequest().select({ voters: [
            { "COUNT_id": { as: "total" } },
            { "MIN_created_at": { as: "beginDate" } }
          ]}).where({
            place_campaigns_campaign_id: this.currentCampaignPreferencesCampaignId,
            main_verbatim_text_processing_status: [ 'ok', 'ko', 'scheduled' ]
          }).group(["main_verbatim_text_processing_status"])

          const response = (await this.$resolve(request))
          const nbOk = response?.data?.ok?.total || 0
          const nbKo = response?.data?.ko?.total || 0
          const nbScheduled = response?.data?.scheduled?.total || 0
          const total = nbOk + nbKo + nbScheduled
          const status = (total > 0 ? Math.floor(((nbOk + nbKo) * 100 / total)) : null)

          return { nbOk, nbKo, nbScheduled, status, total, beginDate: response?.data?.ok?.beginDate }
        }
      },
      availableLocales: {
        default: [],
        async get() {
          // Get campaign locales.
          let request = this.$adminBasedRequest()
                            .select({ campaigns: ['available_locales'] })
                            .where({ id: this.currentCampaignPreferencesCampaignId })
          const locales = new Set((await this.$resolve(request)).first().availableLocales)
          // Get all user locales that have access to that campaign.
          request = this.$adminBasedRequest()
                        .select({ users: ['DISTINCT_locale'] })
                        .where({ place_campaigns_campaign_id: this.currentCampaignPreferencesCampaignId })
          const response = await this.$resolve(request)
          response.data.users.forEach(data => locales.add(data.distinctLocale))
          // Sort and format data
          return [...locales].map(locale => ({ text: this.$t('languages.' + locale), value: locale }))
                             .sort((a, b) => a.text.localeCompare(b.text))
        }
      }
    },
    methods: {
      async rescheduleAnnotationKo() {
        this.scheduling = true
        await this.$api.wizville.textAnalysis.rescheduleAnnotationKo(
          this.currentCampaignPreferencesCampaignId,
          this.textAnalysisPreference
        )
        this.$store.dispatch('notifySuccess')
        this.scheduling = false
      },
      async showFinalPrompt() {
        const prompt = await this.asyncFetchFinalPrompt()

        if (prompt) {
          this.fullPrompt = prompt
          this.fullPromptDialogShow = true
        }
      },
      updateReferenceTaxonomy(newTaxonomyName) {
        if (newTaxonomyName != this.taxonomyName) {
          let topicOptions = this.topicOptions(newTaxonomyName)
          if (topicOptions.length > 0) {
            this.textAnalysisPreference.taxonomy = topicOptions
            this.base.taxonomy = topicOptions
          }
          this.taxonomyName = newTaxonomyName
        }
      },
      async save() {
        this.saving = true
        try {
          const response = await this.$api.wizville.textAnalysis.editTextAnalysisPreferences(
            this.currentCampaignPreferencesCampaignId,
            this.textAnalysisPreference
          )
          this.searchAndReplaceRandomId(response.textAnalysisTopics)
          this.$store.dispatch('notifySuccess')
          this.base = _cloneDeep(this.textAnalysisPreference)
          this.timer = setTimeout(() => { this.heartBeat += 1 }, 5000)
        } catch (e) {
          console.error(e)
          this.$store.dispatch('notifyError')
        }
        this.saving = false
      },
      searchAndReplaceRandomId(textAnalysisTopics) {
        this.textAnalysisPreference.taxonomy.map((taxonomy) => {
          let id = taxonomy.topicId.split('-')
          if (id[0] === 'new') {
            taxonomy.topicId = this.searchNewId(textAnalysisTopics, taxonomy.topicName, taxonomy.topicPublicName)
          }
        })
      },
      searchNewId(textAnalysisTopics, publicName, name) {
        let id = null;
        textAnalysisTopics.map((textAnalyseTopic) => {
          if (textAnalyseTopic.publicName === publicName && textAnalyseTopic.name === name) {
            id = textAnalyseTopic.id.toString()
          }
        })
        return id
      },
      async asyncFetchFinalPrompt() {
        const categories = this.textAnalysisPreference.taxonomy.map(taxonomy => taxonomy.topicName)

        const request = this.$basedRequest().select({
          campaigns: [{
            text_analysis_final_prompt: { params: {
              verbatim_ids: null,
              prompt_first_paragraph: this.textAnalysisPreference.promptFirstParagraph,
              prompt_examples: this.textAnalysisPreference.promptExamples,
              categories: categories
            }}
          }]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })

        return (await this.$resolve(request)).first()?.textAnalysisFinalPrompt
      },
      async asyncFetchDefaultPromptFirstParagraph() {
        const request = this.$basedRequest().select({
          campaigns: [
            'text_analysis_prompt_first_paragraph'
          ]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })

        return (await this.$resolve(request)).first()?.textAnalysisPromptFirstParagraph
      },
      async asyncFetchDefaultPromptExamples() {
        const request = this.$basedRequest().select({
          campaigns: [
            'text_analysis_prompt_examples'
          ]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })

        return (await this.$resolve(request)).first()?.textAnalysisPromptExamples
      },
      async asyncFetchTaxonomies() {
        this.fetchingTaxonomies = true
        const defaultTaxonomiesRequest = this.$basedRequest().select({ brands: [ 'taxonomies' ]}).limit(1)
        const customTaxonomiesRequest = this.$basedRequest().select({
          text_analysis_preferences: [
            { 'text_analysis_preference_templates_taxonomy_by_public_theme': { as: 'taxonomy_by_public_theme'} },
            { 'text_analysis_preference_templates_name': { as: 'taxonomyName'} }
          ]
        }).where({
          text_analysis_preference_templates_is_public: true
        })
        let customTaxonomiesResult = (await this.$resolve(customTaxonomiesRequest)).data.textAnalysisPreferences
        let customTaxonomies = customTaxonomiesResult?.reduce((h, row) => {
          h[row.taxonomyName] = row.taxonomyByPublicTheme
          return h
        }, {})

        let taxonomyRequestResult = (await this.$resolve(defaultTaxonomiesRequest)).data.brands[0].taxonomies
        let defaultTaxonomies = Object.fromEntries(Object.entries(taxonomyRequestResult).filter(([name, topics]) => Object.keys(topics).length > 0))

        this.fetchingTaxonomies = false
        return { ...defaultTaxonomies, ...customTaxonomies }
      },
      async initTextAnalysisPreference() {
        this.taxonomies = await this.asyncFetchTaxonomies()

        const request = this.$basedRequest().select({
          campaigns: [
            { 'text_analysis_preferences_enabled': { as: 'enabled' } },
            { 'text_analysis_preferences_taxonomy': { as: 'taxonomy' } },
            { 'text_analysis_preferences_nb_analysed_months': { as: 'nbAnalysedMonths' } },
            { 'text_analysis_preferences_prompt_first_paragraph': { as: 'promptFirstParagraph' } },
            { 'text_analysis_preferences_prompt_examples': { as: 'promptExamples' } }
          ]
        }).where({
          id: this.currentCampaignPreferencesCampaignId
        })
        const result = (await this.$resolve(request)).first()

        this.textAnalysisPreference.promptFirstParagraph =
          result.promptFirstParagraph || this.defaultPromptFirstParagraph

        this.textAnalysisPreference.promptExamples =
          result.promptExamples || this.defaultPromptExamples

        this.textAnalysisPreference.taxonomy = result.taxonomy || this.topicOptions(this.taxonomyName)
        this.textAnalysisPreference.nbAnalysedMonths = result.nbAnalysedMonths || 0
        this.textAnalysisPreference.enabled = result.enabled || false

        this.base.taxonomy = this.textAnalysisPreference.taxonomy
        this.base.nbAnalysedMonths = this.textAnalysisPreference.nbAnalysedMonths
        this.base.enabled = this.textAnalysisPreference.enabled
        this.base.promptFirstParagraph = this.textAnalysisPreference.promptFirstParagraph
        this.base.promptExamples = this.textAnalysisPreference.promptExamples
      },
      topicOptions(taxonomyName) {
        const topicOptions = []

        if (this.taxonomies && this.taxonomies[taxonomyName]) {
          const mainTaxonomy = this.taxonomies[taxonomyName]

          let themeIdx = 0
          let topicIdx = 0
          for (const [theme, topics] of Object.entries(mainTaxonomy)) {
            let randomThemeId = `themeId-${themeIdx}`
            themeIdx += 1
            for (const [topicPublicName, topicName] of Object.entries(topics)) {
              topicOptions.push({
                themeId: randomThemeId,
                topicId: `topicId-${topicIdx}`,
                themeName: theme,
                themePublicName: this.$helpers.string.capitalize(theme),
                topicName: topicName,
                topicPublicName: topicPublicName
              })

              topicIdx += 1
            }
          }
        }

        return topicOptions
      },
      updateReloadTaxonomies() {
        this.reloadTaxonomies += 1
      },
      cancelAutoUpdate () {
        clearInterval(this.timer)
      }
    }
  }
</script>

<style lang="stylus">
  .openai-preferences
    .v-skeleton-loader__bone
      width: 100%
</style>
