import ApiCall from '@api/apiCall.js'

export async function refreshBrand() {
  const response = await new ApiCall().post('/api/wizville/google_my_business/refresh_brand')

  return response.data
}

export async function destroyGoogleBrand(googleBrandId) {
  const response = await new ApiCall().post('/api/wizville/google_my_business/destroy_google_brand',
    {
    google_brand_id: googleBrandId
    }
  )

  return response.data
}

export async function refreshGoogleBrandPlaceReviews(googleBrandPlaceId) {
  const response = await new ApiCall().post('/api/wizville/google_my_business/refresh_google_brand_place_reviews', {
    google_brand_place_id: googleBrandPlaceId,
  });

  return response.data;
}

