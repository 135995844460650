function importDirectory(r) {
  let cache = {}
  let obj = {}

  r.keys().forEach(key => {
    cache[key] = r(key)
  });

  Object.keys(cache).forEach((moduleName) => {
    const name = moduleName.split("/").pop().split(".")[0]

    Object.keys(cache[moduleName]).forEach((apiFct) => {
      obj[name] = obj[name] || {}
      obj[name][apiFct] = cache[moduleName][apiFct]
    })
  })

  return (obj)
}

export default {
  localMonitor: importDirectory(require.context('./localMonitor', true, /\.(js)$/)),
  wizville: importDirectory(require.context('./wizville', true, /\.(js)$/)),
  admin: importDirectory(require.context('./admin', true, /\.(js)$/)),
  monitoring: importDirectory(require.context('./monitoring', true, /\.(js)$/)),
}
