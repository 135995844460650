import ApiCall from '@api/apiCall.js'

export async function create(achievementActionTemplateParams) {
  const response = await new ApiCall().post("/api/wizville/achievement_action_templates", {
    achievement_action_template: achievementActionTemplateParams
  });

  return response.data
}

export async function update(achievementActionTemplateId, achievementActionTemplateParams) {
  const response = await new ApiCall().put(`/api/wizville/achievement_action_templates/${achievementActionTemplateId}`, {
    achievement_action_template: achievementActionTemplateParams
  });

  return response.data
}

export async function destroy(achievementActionTemplateId) {
  const response = await new ApiCall().delete(`/api/wizville/achievement_action_templates/${achievementActionTemplateId}`);

  return response.data
}
