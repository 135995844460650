import html2canvas from 'html2canvas'

function parseHTML(html) {
  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe)
  iframe.contentWindow.document.open()
  iframe.contentWindow.document.write(html)
  iframe.contentWindow.document.close()
  return iframe.contentWindow.document.body
}

export default function htmlToPng(html, filename, html2CanvasOptions) {
  const element = (typeof(html) === 'string' ? parseHTML(html) : html)

  html2canvas(element, html2CanvasOptions).then(canvas => {
    canvas.toBlob(blob => {
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = URL.createObjectURL(blob);
      anchor.click();
      URL.revokeObjectURL(anchor.href);
    })
  });
}