import { render, staticRenderFns } from "./TopicsRecommendationsTableWidget.vue?vue&type=template&id=d11fd588"
import script from "./TopicsRecommendationsTableWidget.vue?vue&type=script&lang=js"
export * from "./TopicsRecommendationsTableWidget.vue?vue&type=script&lang=js"
import style0 from "./TopicsRecommendationsTableWidget.vue?vue&type=style&index=0&id=d11fd588&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports