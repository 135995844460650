<template>
  <v-tabs v-model="selectedTab" class="translations">
    <v-tab>
      Via fichier
    </v-tab>

    <v-tab>
      Manuel <WTooltip :message="$t('wtreeview_tips')" />   
    </v-tab>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item class="py-5 px-3">
        <v-skeleton-loader
          v-if="!(translations && features && locales)"
          type="paragraph"
        />
        <FileTranslations
          v-else
          v-model="translations"
          :campaignId="campaignId"
          :features="features"
          :locales="locales"
        />
      </v-tab-item>

      <v-tab-item  class="py-5 px-3">
        <v-skeleton-loader
          v-if="!(translations && locales)"
          type="paragraph"
        />
        <ManualTranslations
          v-else
          v-model="translations"
          :locales="locales"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
  import ManualTranslations from './ManualTranslations'
  import FileTranslations from './FileTranslations'

  export default {
    name: 'Translations',
    components: {
      ManualTranslations,
      FileTranslations
    },
    props: {
      campaignId: { type: Number, required: true },
      features: { type: Array, required: false },
      locales: { type: Array, required: false },
    },
    data() {
      return {
        selectedTab: 0,
        loadingTranslations: false,
      }
    },
    asyncComputed: {
      translations: {
        default: undefined,
        async get() {
          try {
            const results = await Promise.all(
              this.features.map(feature => {
                const request = this
                  .$adminBasedRequest()
                  .select({ campaigns: [`${feature}_translations`] })
                  .where({ id: this.campaignId })
                return this.$resolve(request)
              })
            )

            return results.reduce(
              (data, result) => {
                result = result.first()
                if (result != null) {
                  Object.values(result)[0].forEach(translation => {
                    data[translation.id] = translation
                  })
                }
                return data
              },
              {}
            )
          } catch (e) {
            this.$store.dispatch(
              'notifyError',
              { timeout: 5000, message: `Impossible de charger les traductions : ${e}` }
            )
          }
        }
      }
    }
  }
</script>
