<template>
  <v-tooltip
    :top="tooltipPosition === 'top'"
    :right="tooltipPosition === 'right'"
    :bottom="tooltipPosition === 'bottom'"
    :left="tooltipPosition === 'left'"
    :disabled="!tooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <span class="w-badge" :class="classNames" v-on="on" v-bind="attrs">
        <v-badge
          :color='badgeColor'
          :content='displayableContent'
          :value='displayableContent'
          :inline='inline'
          :class="classNames"
        >
          <slot />
        </v-badge>
      </span>
    </template>
    <span v-if='tooltip' v-html="tooltip" />
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'default'
      },
      content: {
        type: [String, Number],
        default: '0'
      },
      inline: {
        type: Boolean,
        default: true
      },
      tooltip: {
        type: String,
        default: ''
      },
      tooltipPosition: {
        type: String,
        default: 'top'
      },
      classNames: {
        type: String
      }
    },
    computed: {
      badgeColor() {
        const colors = {
          success: 'success',
          danger: 'error',
          default: 'grey',
        }

        return colors[this.type]
      },
      displayableContent() {
        if (this.content === 0) {
          return "0"
        }

        return this.content
      }
    },
  }
</script>

<style lang="stylus" scoped>

  .v-badge
    width: 100%

</style>
