<template>
  <div class="center-aligned">
    <v-tooltip :disabled="!!this.rank" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="rank" v-bind="attrs" v-on="on" class="rank" :class="circledClasses" :style="{ width: circleSize, height: circleSize}">
          <p class="c-darkergrey f-13" :style="labelStyle">
            {{ displayedRank }}
            <sup class="f-8" v-if="ordinal">
              {{ displayedRank | ordinal }}
            </sup>
          </p>
        </div>
        <div class="d-flex align-center ga-1" v-else>
          <span class="c-darkergrey f-13 ml-2">{{ displayedRank }}</span>
          <a
            v-if="infoLink"
            :href="infoLink"
            class="infoLink"
            target="_blank"
          >
            <v-icon
              v-if="rank === null"
              :class="`f-12 ${infoIconColor} tooltip`"
              class="infoLink_icon"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </a>
          <span v-else>
            <v-icon v-if="rank === null" v-bind="attrs" v-on="on" :class="`f-12 ${infoIconColor} tooltip`" small>mdi-information-outline</v-icon>
          </span>
        </div>
      </template>
      <span>{{ nrTooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'WRank',
    props: {
      rank: {
        type: Number,
        required: false,
        default: null
      },
      infoIconColor: {
        type: String,
        required: false,
        default: 'c-lightgrey'
      },
      infoLink: {
        type: String,
        required: false,
      },
      size: {
        type: String,
        required: false,
        default: null
      },
      ordinal: {
        type: Boolean,
        required: false,
        default: false
      },
      nrTooltipText: { type: String, require: false }
    },
    computed: {
      circleSize() {
        if (this.size == 'small') {
          return '30px'
        } else {
          return '36px'
        }
      },
      labelStyle() {
        if (this.size == 'small') {
          return {
            top: '1px'
          }
        } else {
          return {
            top: '4px'
          }
        }
      },
      circledClasses() {
        return {
          'border-color-rank-1': this.rank === 1,
          'border-color-rank-2': this.rank === 2,
          'border-color-rank-3': this.rank === 3,
          'border-color-transparent': !this.rank || this.rank > 3
        };
      },
      displayedRank() {
        return this.rank || this.$t('nc');
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .rank
    text-align: center
    border-radius: 50%
    border: 4px solid

  p
    position: relative
    white-space: nowrap
    margin-bottom: 0px

  .infoLink
    vertical-align: text-bottom

  .infoLink_icon
    position: relative
    top: -0.5px
</style>
