import {
  getHeaders,
  mergeBasedColumns,
  mapBasedColumnsByLoadingGroup,
  sourceForColumnName
} from "./ranking_table_columns.js"
import { mapGetters } from 'vuex'
import _concat from "lodash/concat"
import _flatten from "lodash/flatten"
import _capitalize from "lodash/capitalize"
import _uniq from "lodash/uniq"
import Pdf from '@shared/helpers/exportToPdf/pdf'
import { exportToExcel, formatExcelValues } from '@shared/helpers/export-to-excel.js'
import { i18n } from '@i18n/setup'

export default {
  props: [ 'customRankingRelatedIndicator' ],
  data() {
    return {
      loading: true,
      asyncColumns: {},
      asyncColumnsLoadingCount: 0,
      selectedScope: null
    }
  },
  computed: {
    ...mapGetters([
      'brandDashboardFilterRequest',
      'dashboardFilterRequest',
      'dashboardFilterReady',
      'rankingRelatedIndicator',
      'currentDashboardRequest',
      'currentDashboardBasedTable',
      'minimalNumberOfFeedbackForRanking',
      'currentDashboard',
      'dashboardFilterDates',
      'groupRankingPlaceIds',
      'networkRankingPlaceIds',
      'placeIdsScope',
      'hasAccessToAllDashboardPlaces',
      'dashboardAvgScale',
      'dashboardPlaceIds',
      'isMonoPlace',
      'isDashboardMulti',
      'mainCampaignId',
    ]),
    avgScoreScale() {
      return this.campaign?.avgScoreScale || this.dashboardAvgScale
    },
    displayedScope() {
      return this.selectedScope || this.scope
    },
    rankBy() {
      if (this.customRankingRelatedIndicator) {
        return this.customRankingRelatedIndicator
      } else if (this.isDashboardMulti) {
        return `avg_score_campaign_${this.mainCampaignId}`
      } else {
        return this.rankingRelatedIndicator
      }
    },
    dashboardRequestBase() {
      let request = this.brandDashboardFilterRequest

      if (this.displayedScope === 'mine') {
        request = this.dashboardFilterRequest
      } else if (this.displayedScope == 'group') {
        request = this.brandDashboardFilterRequest.where({ place_campaigns_place_id: this.groupRankingPlaceIds })
      } else if (this.displayedScope == 'network') {
        request = this.brandDashboardFilterRequest.where({ place_campaigns_place_id: this.networkRankingPlaceIds })
      }
      if (this.campaign) {
        request = request.where({ campaign_id: this.campaign.id })
      }

      return request
    },
    items() {
      let rank = 1
      const header = this.headers.find(header => header.nbReviewValue == this.rankingRelatedColumn.nbReview) || {}
      const items = this.baseRows.map(item => {
        return { 
          ...item, 
          ...{  [this.rankingRelatedColumn.nbReview]: 
            item[this.rankingRelatedColumn.nbReview] || ((item[header.nbDetractors] || 0) + (item[header.nbNeutrals] || 0) + (item[header.nbPromoters] || 0)) } 
        }
      })

      const loadingGroups = Object.keys(this.asyncColumns)
      const sortedItems = Object.values(items).sort((item1, item2) => {
        return this.sortItems(item1, item2)
      }).map(baseRow => {
        let item = baseRow

        for (const loadingGroup of loadingGroups) {
          const asyncColumnsValues = this.asyncColumns[loadingGroup][baseRow[this.aggregation.key]]

          if (asyncColumnsValues) {
            for (const [key, value] of Object.entries(asyncColumnsValues)) {
              item[key] = value
            }
          }
        }

        if (item[this.rankingRelatedColumn.nbReview] >= this.minimalNumberOfFeedbackForRanking) {
          item.rank = rank
          rank += 1
        }

        if (this.isMonoPlace &&
            this.dashboardPlaceIds.includes(item.placeId)
          ) {
          item.active = true
        }

        if (item.placeId &&
            this.placeIdsScope?.includes(item.placeId) &&
            !this.hasAccessToAllDashboardPlaces &&
            this.scope !== 'mine'
          ) {
          item.active = true
        }

        return item
      })

      return sortedItems
    },
    formatedItems() {
      const items = this.items.map((item) => {
        const formattedItem = { ...item }

        this.headers.forEach((header) => {
          if (item.empty) {
            formattedItem[header.value] = (formattedItem[header.value] !== undefined ? formattedItem[header.value] : '-');
          } else {
            const value = formattedItem[header.value];

            if (value !== this.$LOADING) {
              if (value === null) {
                formattedItem[header.value] = '-';
              } else if (value === undefined) {
                formattedItem[header.value] = this.$LOADING;
              } else if (header.value == 'insatisfactionsAvgProcessingTime' && formattedItem["insatisfactionsAvgProcessingTime"] == -1) {
                formattedItem["insatisfactionsAvgProcessingTime"] = '-'
              } else if (header.displayAs === 'repartition') {
                formattedItem[header.value] = this.$options.filters.round(value)
                const nbNps = (formattedItem['nbPromoters'] || 0) + (formattedItem['nbNeutrals'] || 0) + (formattedItem['nbDetractors'] || 0)

                formattedItem['nbReviewNpsScore'] = nbNps
                formattedItem['ratePromoters'] = (nbNps > 0 ? this.$options.filters.round(formattedItem['nbPromoters'] * 100 / nbNps) || 0 : 0)
                formattedItem['rateNeutrals'] =  (nbNps > 0 ? this.$options.filters.round(formattedItem['nbNeutrals'] * 100 / nbNps) || 0 : 0)
                formattedItem['rateDetractors'] = (nbNps > 0 ? this.$options.filters.round(formattedItem['nbDetractors'] * 100 / nbNps) || 0 : 0)
              } else {
                switch (header?.presenter) {
                  case 'round':
                    formattedItem[header.value] = this.$options.filters.round(value);
                    break;
                  case 'round1':
                    formattedItem[header.value] = this.$options.filters.round1(value);
                    break;
                  case 'round2':
                    formattedItem[header.value] = this.$options.filters.round2(value);
                    break;
                  case 'avg':
                    formattedItem[header.value] = this.$options.filters.round2(value);
                    break;
                  case 'hour':
                    formattedItem[header.value] = this.$options.filters.round(value);
                    break;
                  case 'rate':
                    formattedItem[header.value] = this.$options.filters.round(value);
                  break;
                }
              }
            }
          }
        })

        return formattedItem;
      })

      return items
    },
    basedColumnsByLoadingGroup() {
      return mapBasedColumnsByLoadingGroup(this.headers)
    },
    rankingRelatedColumn() {
      if (this.rankBy === 'avg_satisfaction') {
        return { score: 'avgSatisfaction', nbReview: 'nbReviewSatisfaction' }
      } else if (this.rankBy === 'avg_score') {
        return { score: 'avgSatisfaction', nbReview: 'nbReviewSatisfaction' }
      } else if (this.rankBy === 'g_processing_time') {
        return { score: 'insatisfactionsAvgProcessingTime', nbReview: 'nbProcessedInsatisfactions', sortDirection: 'asc', ignoredSortingValue: -1 }
      } else if (this.rankBy === 'nps_score') {
        return { score: 'npsScore', nbReview: 'nbReviewNpsScore' }
      } else if (this.rankBy.match(/^avg_score_campaign_\d+$/)) {
        const campaignId = this.rankBy.match(/^avg_score_campaign_(\d+)$/)[1]
        return { score: `avgScoreCampaign${campaignId}`, nbReview: `nbReviewCampaign${campaignId}` }
      } else if (this.rankBy.match(/^t\_\d+$/)) {
        const topicId = Number(this.rankBy.match(/t\_(\d+)/)[1])
        return { score: `avgTopic${topicId}`, nbReview: `nbReviewTopic${topicId}` }
      } else if (this.rankBy.match(/^i\_\d+$/)) {
        const campaignIndicatorId = Number(this.rankBy.match(/i\_(\d+)/)[1])
        return { score: `avgCampaignIndicator${campaignIndicatorId}`, nbReview: `nbReviewCampaignIndicator${campaignIndicatorId}` }
      } else if (this.rankBy.match(/^avg_voters_\w+$/)) {
        const source = this.rankBy.match(/^avg_voters_(\w+)$/)[1]
        return { score: `avgScoreSource${sourceForColumnName(source)}`, nbReview: `nbReviewSource${sourceForColumnName(source)}` }
      } else {
        return { score: 'avgScore', nbReview: 'totalReview' }
      }
    }
  },
  asyncComputed: {
    headers: {
      async get() {
        let customTableColumns = null
        const columnOptions = {}

        if (this.staticColumns) {
          if (Array.isArray(this.staticColumns)) {
            customTableColumns = this.staticColumns
          } else {
            customTableColumns = Object.keys(this.staticColumns)

            for (const [column, option] of Object.entries(this.staticColumns)) {
              columnOptions[column] = option
            }
          }
        } else {
          const request = this.currentDashboardRequest.select({
            [this.currentDashboardBasedTable]: [ { [this.customTableColumns.based]: { as: this.customTableColumns.key } }]
          })

          const result = (await this.$resolve(request)).first()[this.customTableColumns.key]

          customTableColumns = result.columns

          if (this.ignoredColumnNames) {
            customTableColumns = customTableColumns.filter( ( el ) => !this.ignoredColumnNames.includes(el) );
          }

          for (const option of Object.values(result.columnOptions)) {
            columnOptions[option.column] = option
          }
        }

        columnOptions[this.rankBy] = columnOptions[this.rankBy] || {}
        columnOptions[this.rankBy].loadingGroup = "base"

        const headers = getHeaders(this.aggregation.baseColumn, this.rankBy, customTableColumns, { ...columnOptions, ...this.columnsOptions })
        headers[0].class = headers[0].cellClass = [ 'sticky-left' ]

        return headers
      },
      default: []
    },
    baseRows: {
      async get() {
        if (this.dashboardFilterReady && this.basedColumnsByLoadingGroup.base && this.headers.length > 0) {
          this.setLoading(true);
          let request = this.addConditions(this.dashboardRequestBase.select(
            mergeBasedColumns(this.basedColumnsByLoadingGroup.base, this.aggregation.basedColumn)
          ).group(
            Object.values(this.aggregation.basedColumn)
          ))

          let baseRows =  Object.values((await this.$resolve(request))?.data || [])
          const placeIdsWithVoter = baseRows.reduce((h, e) => { h[e.placeId] = true ; return h }, {})

          if (this.aggregation?.baseColumn === "placeName") {
            const placeIdsWithoutVoter = this.dashboardRequestBase.getQuery(
            ).where.place_campaigns_place_id.filter(place_id => !placeIdsWithVoter[place_id])

            if (placeIdsWithoutVoter.length > 0) {
              const request = this.$basedRequest().select({ public_places: [ 'name', 'alias_names' ] }).where({ id: placeIdsWithoutVoter }).group(['id'])
              const placeNameById = (await this.$resolve(request)).data

              baseRows = _concat(baseRows, placeIdsWithoutVoter.map(placeId => ({
                empty: true,
                totalReview: 0,
                placeId: placeId,
                placeName: placeNameById[placeId]?.name || `${placeId}`,
                placeCodes: placeNameById[placeId]?.aliasNames,
                [this.rankingRelatedColumn.nbReview]: 0
              })))
            }
          }

          return baseRows
        }

        return []
      },
      default: [],
      watch: [ 'dashboardRequestBase', 'headers', 'basedColumnsByLoadingGroup' ]
    },
  },
  watch: {
    baseRows(baseRows) {
      if (baseRows.length > 0) {
        this.asyncColumns = {}
        this.asyncColumnsLoadingCount = Object.keys(this.asyncColumns).length;
        if (this.asyncColumnsLoadingCount === 0) {
          this.setLoading(false);
        }
        for (const [loadingGroup, basedColumns] of Object.entries(this.basedColumnsByLoadingGroup)) {
          if (loadingGroup !== 'base') {
            try {
              this.addAsyncColumns(loadingGroup, basedColumns)
            } catch(e) {
              console.error(e)
            }
          }
        }
      }
    }
  },
  methods: {
    scoreForStars(header, item) {
      if (item[header.value] === null) {
        return 0
      }

      const score = item[header.value]
      const avgScoreScale = header?.avgScoreScale || this.avgScoreScale
      const displayedsScore = avgScoreScale.max === 5 ? score : score / 2

      return Math.round(displayedsScore * 2) / 2;
    },
    updateSelectedScope(scope) {
      this.selectedScope = scope
    },
    sortItems(item1, item2) {
      if ((item1[this.rankingRelatedColumn.nbReview] === 0 || item1[this.rankingRelatedColumn.nbReview] < this.minimalNumberOfFeedbackForRanking) &&
          (item2[this.rankingRelatedColumn.nbReview] === 0 || item2[this.rankingRelatedColumn.nbReview] < this.minimalNumberOfFeedbackForRanking)) {
        return this.sortByRankingColumn(item1, item2)
      } else if (item1[this.rankingRelatedColumn.nbReview] === 0 || item1[this.rankingRelatedColumn.nbReview] < this.minimalNumberOfFeedbackForRanking) {
        return 1
      } else if (item2[this.rankingRelatedColumn.nbReview] === 0 || item2[this.rankingRelatedColumn.nbReview] < this.minimalNumberOfFeedbackForRanking) {
        return -1
      } else {
        return this.sortByRankingColumn(item1, item2)
      }
    },
    sortByRankingColumn(item1, item2) {
      const ignoredSortingValue = this.rankingRelatedColumn.ignoredSortingValue
      let a = Number(item1[this.rankingRelatedColumn.score])
      let b = Number(item2[this.rankingRelatedColumn.score])

      if (ignoredSortingValue) {
        a = a === ignoredSortingValue ? null : a
        b = b === ignoredSortingValue ? null : b
      }

      if (a !== null && b !== null) {
        if (a == b) {
          return this.sortByNbReview(item1, item2)
        } else {
          return this.rankingRelatedColumn.sortDirection === 'asc' ? a - b : b - a
        }
      } else if (a === null && b === null){
        return this.sortByNbReview(item1, item2)
      } else if (a === null) {
        return 1
      } else {
        return -1
      }
    },
    sortByNbReview(item1, item2) {
      if (item1[this.rankingRelatedColumn.nbReview] == item2[this.rankingRelatedColumn.nbReview] ) {
        return item1[this.aggregation.baseColumn].localeCompare(item2[this.aggregation.baseColumn])
      } else {
        return (item1[this.rankingRelatedColumn.nbReview] > item2[this.rankingRelatedColumn.nbReview]  ? -1 : 1)
      }
    },
    async addAsyncColumns(loadingGroup, basedColumns) {
      const columnsSelect =  mergeBasedColumns(basedColumns, this.aggregation.basedColumn)
      let request = this.addConditions(this.dashboardRequestBase.select(
        columnsSelect
      ).group(
        Object.values(this.aggregation.basedColumn)
      ))

      let columns = (await this.$resolve(request)).data

      if (this.aggregation.key === 'voterStringInfoValue') {
        const asyncColumns = {}

        for (const column of Object.values(columns)) {
          asyncColumns[column[this.aggregation.key]] = column
        }

        columns = asyncColumns
      }

      this.asyncColumns = {
        ...this.asyncColumns,
        ...{ [loadingGroup]: columns }
      }
      this.asyncColumnsLoadingCount--;

      if (this.asyncColumnsLoadingCount === 0) {
        this.setLoading(false);
      }
    },
    setLoading(value) {
      this.loading = value;
      this.$emit('tableLoading', value);
    },
    addConditions(request) {
      let requestWithCondition = request

      if (this.conditions) {
        for (const condition of this.conditions) {
          requestWithCondition = requestWithCondition.where(condition)
        }
      }

      return requestWithCondition
    },
    exportFileName() {
      const pageName = i18n.t(this.$route.name);
      const campaignName = this.currentDashboard.name;
      const date = this.$date().locale(this.$i18n.locale).format('ddd DD MMM HH_mm_ss');

      return `${pageName} - ${campaignName} - ${date}`;
    },
    headerValues() {
      const headerValues = ['rank'];
      headerValues.push(...(_flatten(this.headers.map((header) => {
        if (header.value === 'placeName') {
          return [
            header.value,
            'placeCodes'
          ]
        } else if (header.displayAs === 'score') {
          return [
            header.value,
            header.nbReviewValue
          ]
        } else if (header.displayAs === 'repartition') {
          let repartitionValues = []

          if (!header.hideScore) {
            repartitionValues.push(header.value)
          }

          repartitionValues = repartitionValues.concat([
            'rateDetractors',
            'rateNeutrals',
            'ratePromoters',
            'nbDetractors',
            'nbNeutrals',
            'nbPromoters',
          ])

          if (!header.hideNbReview) {
            repartitionValues.push(header.nbReviewValue || 'nbReview')
          }
          return repartitionValues
        } else {
          return [
            header.value
          ]
        }
      }))))

      return _uniq(headerValues)
    },
    headerTexts(headerValues) {
      let previousValue = ""

      return headerValues.map(headerValue => {
        let header = this.headers.find((header) => header.value === headerValue)
        let isAvgSatisfaction = this.headers.find((header) => header.displayAs === 'repartition')?.scoreType === 'avgSatisfaction'
        let text = null

        if (headerValue == 'placeCodes') {
          text = this.$t('placeCodes')
        } else if (headerValue == 'ratePromoters') {
          text = isAvgSatisfaction ? `% ${this.$t('very_satisfied')}` : `% ${this.$t('promoters')}`
        } else if (headerValue == 'rateNeutrals') {
          text = isAvgSatisfaction ? `% ${this.$t('satisfied')}` : `% ${this.$t('neutrals')}`
        } else if (headerValue == 'rateDetractors') {
          text = isAvgSatisfaction ? `% ${this.$t('unsatisfied')}` : `% ${this.$t('detractors')}`
        } else if (headerValue == 'nbPromoters') {
          text = isAvgSatisfaction ? `${this.$t('nb_very_satisfied')}` : `${this.$t('nb_promoters')}`
        } else if (headerValue == 'nbNeutrals') {
          text = isAvgSatisfaction ? `${this.$t('nb_satisfied')}` : `${this.$t('nb_neutrals')}`
        } else if (headerValue == 'nbDetractors') {
          text = isAvgSatisfaction ? `${this.$t('nb_unsatisfied')}` : `${this.$t('nb_detractors')}`
        } else if (headerValue == 'nbReviewNpsScore') {
          text = `👤 NPS`
        } else if (["nbReviewNotSocial", "nbReviewPagesJaunes", "nbReviewGoogle", "nbReviewFacebook","nbReviewTripAdvisor"].includes(headerValue)) {
          text = `👤 ${header.text}`
        } else if (headerValue.match(/nbReview.*/)) {
          text = `${this.$t('nb_reviews')} ${previousValue}`
        } else if (header) {
          text = `${header?.text}${header?.subtext ? ' ' + header.subtext : ''}`
        } else {
          text = headerValue
        }

        previousValue = text
        return text
      })
    },
    async exportToExcel() {
      this.$store.dispatch("notifyInfo");

      const fileName = this.exportFileName();
      const sheetName = i18n.t(this.$route.name);

      const data = this.formatedItems.map((item) => {
        const entries = Object.fromEntries(
          Object.entries(item)
          .filter(([key]) => this.headerValues().includes(key))
        )
        entries.rank = entries.rank || 'NC'

        return entries
      });
      const headerValues = this.headerValues()
      const headerTexts = this.headerTexts(headerValues)

      // check if we are in the good page and tab
      const categoryExportEnable = this.$route.name == 'Ranking' && this.$route.params.rankBy == 'places';
      if (categoryExportEnable) {
        // fetching the extra categories headers (with translation)
        const categoryHeaders = (await this.getExtraCategoriesHeadersWithTranslation());
        if (categoryHeaders) {
          let extraColumnsData = {};
          // fetch data to insert in the rows 
          const allGroupSemantics = (await this.campaignGroupSemantic());
          // formatting the data to merge in the excel
          extraColumnsData = this.extraExcelCategoriesDataFormatting(allGroupSemantics);
          // mutate data for excel
          this.mutateDataWithCategories(extraColumnsData, data);
          // mutating the headers
          categoryHeaders?.brands.map((headerColumn) => {
            headerColumn?.groupSemanticsWithTranslations?.map((groupSemantic) => {
              headerValues.push(groupSemantic.value);
              headerTexts.push(groupSemantic.text);
            });
          });
        }
      }
      // will format the insatisfaction avg processing time
      this.formatInsatisfactionsAvgProcessingTime (data);
      // manipulation of the data
      const formattedDatas = formatExcelValues(data, { rateDetractors: { suffix: "%" }, rateNeutrals: { suffix: "%" }, ratePromoters: { suffix: "%" }, insatisfactionsRate: { suffix: "%" } })
      exportToExcel(fileName, sheetName, formattedDatas, headerTexts, headerValues);
    },
    formatInsatisfactionsAvgProcessingTime (data) {
      data.map((dataDetail) => {
        this.verifyAndTransformHoursToDayData(dataDetail)
      })
    },
    verifyAndTransformHoursToDayData(dataDetail) {
      if (Number(dataDetail.insatisfactionsAvgProcessingTime)) {
        dataDetail.insatisfactionsAvgProcessingTime = this.transformHoursToDay(dataDetail.insatisfactionsAvgProcessingTime);
      }
      if (Number(dataDetail.insatisfactionsMaxProcessingTime)) {
        dataDetail.insatisfactionsMaxProcessingTime = this.transformHoursToDay(dataDetail.insatisfactionsMaxProcessingTime);
      }
    },
    transformHoursToDay (hourData) {
      return Math.floor(hourData / 24).toFixed(0);
    },
    extraExcelCategoriesDataFormatting (allGroupSemantics) {
      const categoriesData = {};
      
      for (const semanticKey in allGroupSemantics) {
        const keyValuesDictionary = {};
        const groupSemanticKeys = allGroupSemantics[semanticKey].arrayAggGroupsSemantic;
        const groupNames = allGroupSemantics[semanticKey].arrayAggGroupsName;
        const placeCodes = allGroupSemantics[semanticKey].aliasNames;
        // groupSemanticKeys and groupNames are 2 arrays that we want to merge in order to obtain
        // the key value array.
        for (let i = 0; i < groupNames.length; i++) {
          if (!keyValuesDictionary.hasOwnProperty(groupSemanticKeys[i])) {
            keyValuesDictionary[groupSemanticKeys[i]] = groupNames[i];
          } else {
            if (keyValuesDictionary[groupSemanticKeys[i]] ) {
              if (!keyValuesDictionary[groupSemanticKeys[i]].includes(groupNames[i])){
                keyValuesDictionary[groupSemanticKeys[i]]  += "," + groupNames[i];
              }
            } else {
              keyValuesDictionary[groupSemanticKeys[i]]  = groupNames[i];
            }
          }
        }
        // to match the data, we put all in the placeCodes (aliasNames, placeCodes)
        categoriesData[placeCodes] = keyValuesDictionary;
      }
      return categoriesData;
    },
    mutateDataWithCategories (extraColumnsData, data) {
      for (const placeCode in extraColumnsData) {
        const filteredData = data.filter((searchedData) => {
          return searchedData['placeCodes'] == placeCode
        })
        if (filteredData){
          for (const [catKey, catValue] of Object.entries(extraColumnsData[placeCode])) {
            filteredData.forEach(fData => {
              fData[catKey] = catValue;
            });
          }
        }
      }
    },
    async campaignGroupSemantic () {
      const request = this.$basedRequest().select({
        places: ["id", "name", "alias_names", "ARRAY_AGG_groups_semantic", "ARRAY_AGG_groups_name"]}
      ).where(
        { 'campaign_dashboards_dashboard_id': { 'in': this.currentDashboard.id} }
      ).group(
        ["id"]
      );
      const placesGroupsSemantics = (await this.$resolve(request)).data;
      return placesGroupsSemantics;
    },
    async getExtraCategoriesHeadersWithTranslation() {
      const grSemanticsWtTansRequest = this.$basedRequest().select({ 
        brands: [ 
          { 'group_semantics_with_translations': { 
            params: { 
              dashboard_type: this.currentDashboard.type,
              dashboard_id: this.currentDashboard.id,
              no_alone_group: false
            }
          }}
        ] 
      });
      const grSemanticsWtTansResponse = (await this.$resolve(grSemanticsWtTansRequest)).data;
      return grSemanticsWtTansResponse;
    },
    async exportToPdf() {
      const exportPageTitle = () => {
        const pageName = i18n.t('rankingTabPdfExport.pageName');
        const period = this.dashboardFilterDates.text;
        return [pageName, period].join(' - ');
      }

      const computeColumnWidth = (header) => {
        if (header.pdfMinWidth) {
          return header.pdfMinWidth
        }

        switch (header.displayAs) {
          case 'score':
            return 80
          case 'repartition':
            return 200
          default:
            return 0
        }
      }

      const columnWidth = (header) => {
        if (Object.keys(colWidths).length === 0) {
          const fixedColumns = this.headers.filter((header) => {
            return header.pdfMinWidth ||
            header.displayAs === 'score' ||
            header.displayAs === 'repartition'
          })
          const fixedColumnsCount = fixedColumns.length
          const fixedWidths = fixedColumns.reduce((width, header) => {
            return width + computeColumnWidth(header)
          }, 0)
          const colMarginLeft = 5;
          const defaultColumnWidth = (pdf.currentPage.bodyWidth - fixedWidths - rankWidth - ( colMarginLeft * this.headers.length) ) / (this.headers.length - fixedColumnsCount)
          for (const h of this.headers) {
            if (h.pdfMinWidth) {
              colWidths[h.text] = h.pdfMinWidth
            } else if (h.displayAs === 'score') {
              colWidths[h.text] = 80
            } else if (h.displayAs === 'repartition') {
              colWidths[h.text] = 200
            } else {
              colWidths[h.text] = defaultColumnWidth > 50 ? defaultColumnWidth : 50
            }
          }
        }
        return colWidths[header.text]
      }

      const exportHeader = async(pdf) => {
        const title = exportPageTitle();

        const campaignName = this.currentDashboard.name;
        const subTitle = campaignName;

        await pdf.addRow({}, async(row) => {
          await row.addCol({}, async(col) => {
            col.addText(title, { fontSize: fontSize, color: blackColor, font: { style: 'bold' }});
          });
        });
        await pdf.addRow({ marginTop: 5 }, async(row) => {
          await row.addCol({}, async(col) => {
            col.addText(subTitle, { fontSize: fontSize - 2, font: { style: 'bold' }});
          });
        });

        await exportTableHeaders(pdf);
      }

      const exportTableHeaders = async(pdf) => {

        await pdf.addRow({ marginTop: 20 }, async(row) => {
          await row.addCol({ width: rankWidth })

          await this.headers.slice(0, 10).reduce(async (promise, header) => {
            await promise

            await row.addCol({ width: columnWidth(header), marginLeft: colMarginLeft }, async(col) => {
              col.addText(`${header.text}${header.subtext ? ' ' + header.subtext : ''}`, { fontSize: fontSize - 2, overflow: 'linebreak' });
            });
          }, undefined);
        });
      }

      const exportRank = async(item, row) => {
        const rank = item.rank?.toString() || 'NC';

        await row.addCol({ width: rankWidth, height: row.height }, async(col) => {
          if (item.rank && item.rank <= 3) {
            const rankColors = ['#FFD333', '#A5C0C4', '#C89C73'];
            const bigCircleColor = rankColors[item.rank - 1] || '#FFF';
            const smallCircleColor = item.highlighted ? '#DFF0F1' : '#FFF'

            col.addCircle({ radius: rankWidth/2 - 2 , baseline: 'middle', align: 'center', color: bigCircleColor });
            col.addCircle({ radius: rankWidth/2 - 4, baseline: 'middle', align: 'center', color: smallCircleColor });
          }

          col.addText(rank, { align: 'center', fontSize: fontSize });
        })
      }

      const exportScore = async(header, item, row, width) => {
        await row.addCol({ width: width, height: row.height, marginLeft: colMarginLeft }, async(col) => {
          await col.addRow({ marginTop: marginTopWithSubline }, async(row) => {
            await row.addCol({}, async(col) => {
              if (item[header.nbReviewValue]) {
                await col.addRating(this.scoreForStars(header, item), { size: 10 })
              }
            })
            await row.addCol({ marginLeft: 5 }, async(col) => {
              if (item[header.nbReviewValue]) {
                col.addText(item[header.value].toString(), { fontSize: fontSize, color: blackColor })
              } else {
                col.addText("-", { fontSize: fontSize, color: blackColor })
              }
            })
          })
          await col.addRow({}, async(row) => {
            await row.addCol({ marginLeft: 2 }, async(col) => {
              if (item[header.nbReviewValue]) {
                col.addText(
                  `${this.$t('rankingPdfExport.nbReview', { nbReview: item[header.nbReviewValue] })}`,
                  { fontSize: subLineFontSize }
                )
              }
            })
          })
        })
      }

      const exportRepartition = async(header, item, row, width) => {
        const npsBarWidth = item.npsScore ? width - 50 : width - 30
        await row.addCol({ width: width, height: row.height, marginLeft: colMarginLeft }, async(col) => {
          await col.addRow({ height: !header.hideNbReview ? row.height - 10 : row.height }, async(row) => {
            await row.addCol({ height: row.height }, async(col) => {
              const nbReviewValue = (item[header.nbPromoters] || 0) + (item[header.nbNeutrals] || 0) + (item[header.nbDetractors] || 0)

              col.addRepartitionBar({
                width: npsBarWidth,
                height: row.height - 5,
                voterCount: nbReviewValue,
                promoterCount: item[header.nbPromoters],
                neutralCount: item[header.nbNeutrals],
                detractorCount: item[header.nbDetractors],
                baseline: 'middle'
              })
            })
            if (!header.hideScore && item[header.value] && item[header.value] !== "-") {
              await row.addCol({ height: row.height, marginLeft: 4 }, async(col) => {
                col.addText((this.$options.filters.round(parseFloat(item[header.value])))?.toString(), { fontSize: fontSize, font: { style: 'bold' }, color: '#1e547b' });
              })
            }
          })
          if(!header.hideNbReview && item[header.value] && item[header.value] !== "-") {
            await col.addRow({}, async(row) => {
              await row.addCol({}, async(col) => {
                const nbReviewValue = (item[header.nbPromoters] || 0) + (item[header.nbNeutrals] || 0) + (item[header.nbDetractors] || 0)

                col.addText(
                  `${this.$t('rankingPdfExport.nbReview', { nbReview: nbReviewValue })}`,
                  { fontSize: subLineFontSize }
                )
              })
            })
          }
        })
      }

      const exportFooter = async(pdf) => {
        const title = exportPageTitle();
        const pagination = i18n.t('rankingPdfExport.paginationWithoutTotal', { page: pdf.pageCount });
        const footer = [title, pagination].join(' - ');

        await pdf.addRow({}, async(row) => {
          await row.addCol({ width: '12' }, async(col) => {
            col.addText(footer, { fontSize: fontSize - 2, align: 'right' });
          });
        });
      }

      this.$store.dispatch("notifyInfo");

      const rowHeight = 30;
      const rankWidth = 24;
      const fontSize = 8;
      const subLineFontSize = 6
      const marginTopWithSubline = 4
      const colMarginLeft = 5

      const blackColor = "#212121";
      const borderColor = "#e0e0e0";
      const fileName = `${this.exportFileName()}.pdf`;

      const columnsCount = this.headers.length + 1;
      const data = this.formatedItems;
      const defaultOrientation = 'l'
      const colWidths = []

      const pdf = new Pdf({
        defaultBodyMargin: { left: 40, top: 10 },
        defaultOrientation: defaultOrientation,
        defaultHeader: { marginLeft: 40, marginTop: 10, content: exportTableHeaders },
        defaultFooter: { height: 40, marginLeft: 40, content: exportFooter }
      });

      await pdf.addPage({
        header: { marginLeft: 40, marginTop: 20, content: exportHeader }
      });
      const rowParams = { height: rowHeight, borders: ['top', 'bottom'], borderColor: borderColor };

      await data.reduce(async (itemPromise, item) => {
        await itemPromise;

        await pdf.addRow(rowParams, async(row) => {
          await exportRank(item, row, rankWidth);
          await this.headers.slice(0, 10).reduce(async (promise, header) => {

            await promise
            switch (header.displayAs) {
              case 'score':
                await exportScore(header, item, row, columnWidth(header));
                break;
              case 'repartition':
                await exportRepartition(header, item, row, columnWidth(header));
                break;
              default:
                if (header.value == 'insatisfactionsAvgProcessingTime' || header.value == 'insatisfactionsMaxProcessingTime') {
                  await row.addCol({ width: columnWidth(header), height: row.height, marginLeft: colMarginLeft }, async(col) => {
                    col.addText(this.transformHoursToDay(item[header.value]), { fontSize: fontSize });
                  });
                } else {
                  await row.addCol({ width: columnWidth(header), height: row.height, marginLeft: colMarginLeft }, async(col) => {
                    col.addText(item[header.value], { fontSize: fontSize });
                  });
                }
            }
          }, undefined);
        });
      }, undefined);

      pdf.save(fileName);
    }
  }
}
