export function trackUser(currentUserId, currentUserData) { 
}

export function resetUser() {
}

export function trackPageView(data) {
}

export function trackEvent(eventLabel, data) {
}

export default { trackUser, resetUser,  trackPageView, trackEvent }