import WizClient from '@shared/http/wizClient'

async function places(name, options = {}) {
  const response = await WizClient.get('/api/auto_complete/places', {
    params: { ...options, name }
  })

  return response.data.data
}

export {
  places
}
