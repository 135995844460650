
export const ADVANCED_SEARCH_RIGHTS = [
    {
        header: 'DROITS - Général'
    },
    {
        text: 'Filtres sur tableau de bord',
        value: 'right_use_filters',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Accès aux préférences',
        value: 'access_to_preferences',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Création / édition de campagne',
        value: 'right_edit_campaigns',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet collaborateurs',
        value: 'right_manage_collaborators',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Edition collaborateurs',
        value: 'right_edit_collaborators',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Cacher les informations client',
        value: 'hide_customer_informations',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Gestion du réseau',
        value: 'right_edit_accounts',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Peut accéder à tous les rapports des collaborateurs',
        value: 'other_users_reportings',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Accès aux campagnes payantes',
        value: 'right_access_paid_campaign',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Affichage des avis lus/non lus',
        value: 'mark_feedback_as_read',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Accès au bouton Actions',
        value: 'access_to_campaign_actions',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Rôle de modérateur',
        value: 'moderator',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Export des emails invalides',
        value: 'export_invalid_emails',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Connexion via SSO uniquement',
        value: 'sso_only',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    },
    {
        header: 'DROITS - Droits de réponse',
    },
    {
        text: 'Réponse aux clients',
        value: 'right_answer_customers',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Réponse intelligente',
        value: 'right_smart_answer',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    },
    {
        header: 'DROITS - Wizville App'
    },
    {
        text: 'Activation',
        value: 'access_to_new_platform',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Lien ancienne / nouvelle plateforme',
        value: 'redirect_to_new_platform',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet concurrence',
        value: 'access_to_new_platform_local_insight_tab',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet avis',
        value: 'access_to_new_platform_feedback_tab',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet classement',
        value: 'access_to_new_platform_ranking_tab',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet statistiques',
        value: 'access_to_new_platform_statistic_tab',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet statistiques: analyse verbatim',
        value: 'access_to_new_platform_statistic_verbatim_analysis',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Reclassification verbatim',
        value: 'right_classify_verbatims',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Onglet succès',
        value: 'access_to_new_platform_achievements_tab',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Partager des succès',
        value: 'right_to_share_achievements',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Fonctionnalité NIS',
        value: 'access_to_new_platform_nis_feature',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Accès au taux de collecte',
        value: 'access_to_voter_collection',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    }
]