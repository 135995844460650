import WizClient from '@shared/http/wizClient'

async function fetch(from, external_id, no_category = false) {
  const response = await WizClient.post('/api/local_monitor/places/fetch', {
    no_category,
    client: from,
    external_id
  })

  return response.data.data
}

async function searchNearby(id) {
  const response = await WizClient.get('/api/local_monitor/places/search_nearby', {
    params: { id }
  })

  return response.data.data
}

export {
  fetch,
  searchNearby
}
