<template>
  <v-tooltip top transition="none" v-if="iconData">
    <template #activator="{ on }">
      <w-icon v-on="on" :icon="icon" :icon-style="iconStyle" v-if="iconStyle" />
    </template>

    {{ status }}
  </v-tooltip>
</template>

<script>
  export default {
    name: "WTrustvilleBadge",
    props: [ 'status', 'tooltipEnabled' ],
    computed: {
      iconData() {
        return {
          private: { icon: 'trustville', style: { color: 'grey' } },
          not_diffused: { icon: 'trustville', style: { color: 'grey' } },
          trustville_reporting_status_or_not_diffused: { icon: 'trustville', style: { color: 'grey' } },
          soon_online_waiting_for_moderation: { icon: 'trustville-dot-red', style: { color: this.$colors.primary } },
          waiting_for_moderation: { icon: 'trustville-dot-red', style: { color: this.$colors.primary } },
          soon_online_with_mediation: { icon: 'trustville-dot-red', style: { color: this.$colors.primary } },
          soon_online_but_a_priori: { icon: 'trustville-dot-red', style: { color: this.$colors.primary } },
          soon_online: { icon: 'trustville', style: { color: this.$colors.primary } },
          online_but_a_priori: { icon: 'trustville', style: { color: this.$colors.success } },
          online: { icon: 'trustville', style: { color: this.$colors.success } }
        }[this.status]
      },
      iconStyle() {
        if (this.iconData) {
        const style = this.iconData?.style
        style.fontSize = "18px"
        
        return style
        }
      },
      icon() {
        return this.iconData?.icon
      }
    }
  }
</script>

<style lang='stylus' scoped>
</style>
