<template>
  <div style="width: 100%">
    <h2
      v-if="!!title"
      class="text-f10"
    >
      {{ title }}
    </h2>

    <Chart :options="chartOptions" />

    <div class="legend">
      <ul>
        <li
          v-for="(serie, index) in series"
          :key="serie.name"
        >
          <span
            :style="{ backgroundColor: colors[index] }"
            class="legend-color"
          />
          {{ serie.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

const DEFAULT_COLORS = [
  "#FFB800",
  "#DB3572",
  "#88C788",
  "#BA0022",
  "#2E1C4D",
  "#C48754"
]

export default {
  components: { Chart },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    categories: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: false,
      default: () => DEFAULT_COLORS
    }
  },
  computed: {
    chartOptions () {
      let options = {
        chart: {
          type: 'spline'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            tickLength: 0
          },
          alternateGridColor: '#f9fafb',
          categories: this.categories,
          lineColor: 'transparent',
        },
        plotOptions: {
          series: {
            marker: {
              symbol: 'circle',
              fillColor: 'white',
              lineWidth: 2,
              lineColor: null
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '14px'
              }
            }
          }
        },
        yAxis: {
          visible: false,
          gridLineColor: 'transparent'
        },
        legend: {
          enabled: false
        }
      }

      let series = this.series.map ( (s, index) => {
        return { ...s, color: this.colors[index] }
      })

      return { ...options, series }
    }
  }
}
</script>

<style lang='stylus' scoped>
.legend
  ul
    li
      display: inline
      list-style: none
      margin-right: 1em
      .legend-color
        vertical-align: middle
        display: inline-block
        width: 10px
        height: 2px
</style>
