import WizClient from "@shared/http/wizClient"

export async function update(id, params) {
  const response = await WizClient.put(
    `/admin_constants/${id}`,
    {
      admin_constant: params
    }
  )

  return response.data
}
