<template>
  <div class="public-platform-facebook">
    <FacebookBrandsTable/>
    <PlacesTable/>
    <UnasignedFacebookBrandPlacesTable/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import FacebookBrandsTable from './FacebookBrandsTable'
  import PlacesTable from './PlacesTable'
  import UnasignedFacebookBrandPlacesTable from './UnasignedFacebookBrandPlacesTable'

  export default {
    name: "Google",
    components: {
      FacebookBrandsTable,
      PlacesTable,
      UnasignedFacebookBrandPlacesTable
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
      ]),
    }
  }
</script>

<style lang="stylus" scoped>
</style>
