<template>
  <v-row class="products" no-gutters>
    <v-col>
      <v-row v-if="eligibleCampaigns.length > 1" no-gutters class="mb-3 mx-3">
        <v-col cols="12">
          <ProductsCampaignAutocomplete
            v-if="campaign"
            :campaignId="campaign.id"
            :eligibleCampaigns="eligibleCampaigns"
            class="d-flex"
          />
        </v-col>
      </v-row>

      <template v-if="campaign">
        <v-row no-gutters>
          <v-col>
            <CategoriesProductsWidget
              :mode="mode"
              :categoryId="categoryId"
              :cols="12"
              class="pt-0"
              :campaign="campaign"
            />
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from "vuex"

  import CategoriesProductsWidget from './CategoriesProductsWidget'
  import ProductsCampaignAutocomplete from './ProductsCampaignAutocomplete'

  export default {
    name: "Products",
    props: {
      mode: {
        type: String,
        required: false,
        default: 'product_categories'
      },
      campaignId: {
        required: false,
        default: 'default'
      },
      productCategoryId: { required: false }
    },
    components: {
      ProductsCampaignAutocomplete,
      CategoriesProductsWidget
    },
    computed: {
      ...mapGetters([
        'currentDashboardScopedCampaigns',
        'dashboardFilterRequest'
      ]),
      categoryId() {
        return this.productCategoryId ? parseInt(this.productCategoryId) : null
      },
      campaign() {
        const campaignId = this.campaignId === 'default' ?
          this.mostVotersCampaignId :
          Number(this.campaignId)

        return this.eligibleCampaigns.find(campaign => campaign.id === campaignId)
      },
      eligibleCampaigns() {
        return this.currentDashboardScopedCampaigns.filter(
          (campaign) => campaign.hasProductCategoryType
        )
      }
    },
    asyncComputed: {
      mostVotersCampaignId: {
        async get() {
          const request = this.$basedRequest().select({
            voters: [
              { COUNT_DISTINCT_id: { as: 'nbReview' } },
              { campaign_id: { as: 'campaignId' } }
            ]
          })
          .where({
            campaign_id: this.eligibleCampaigns.map(campaign => campaign.id),
            avg_score: { 'is_not_null': {} }
          }).order(
            [
              ['COUNT_DISTINCT_id', 'desc'],
            ]
          ).group(['campaign_id'])

          const response = (await this.$resolve(request))?.data?.voters?.[0]?.campaignId

          return response
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~@theme/constants.styl'
      
  .products
    padding-top: "calc(%s + 15px)" % $filters-height
</style>
