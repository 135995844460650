<template>
  <v-tooltip
    :disabled="!tooltip"
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="v-btn-square no-pdf"
        :icon="!highlighted"
        :color="highlighted ? 'secondary' : 'primary'"
        @click="click"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-eye-outline</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
  export default{
    name: "WZoomButton",
    props: {
      highlighted: { type: Boolean, required: false },
      tooltip: { type: String, required: false }
    },
    methods: {
      click(evt) {
        evt.stopPropagation()
        this.$emit('click')
      }
    }
  }
</script>
