import ApiCall from '@api/apiCall.js'

export async function fileUpdateCheck(file, newPlaceAllowed = false) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("newPlaceAllowed", newPlaceAllowed);
  const response = await new ApiCall().post(`/api/wizville/places/file_update_check`, formData)

  return response.data
}

export async function applyModifications(modifications) {
  const response = await new ApiCall().post("/api/wizville/places/apply_modifications", {
    modifications
  })

  return response.data
}


export async function update(place_id, params) {
  const response = await new ApiCall().put(`/api/wizville/places/${place_id}`, {
    place: params
  })

  return response.data
}

export async function exportPlaces(placeIds) {
  const response = await new ApiCall().get(`/api/wizville/places/export?place_ids=${placeIds ? placeIds?.join(",") : ""}`)

  return response.data
}
