
export const ADVANCED_SEARCH_NOTIFICATIONS = [
    {
        header: 'NOTIFICATIONS - Général'
    },
    {
        text: 'Générales (demandes de réponse, messages clients)',
        value: 'notifications_enabled',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    },
    {
        header: 'NOTIFICATIONS - Résumés'
    },
    {
        text: 'Inclure les pièces jointes',
        value: 'notification_satisfaction_attachment',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Insatisfactions (instantanées)',
        value: 'notification_insatisfaction_right_away',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Insatisfactions (quotidien)',
        value: 'notification_insatisfaction_daily',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Insatisfactions (hebdomadaire)',
        value: 'notification_insatisfaction_weekly',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Insatisfactions (mensuel)',
        value: 'notification_insatisfaction_monthly',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Rappel insatisfactions',
        value: 'notification_reminder_insatisfaction',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Félicitations en cas de satisfaction client',
        value: 'notification_satisfaction_greetings',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    },
    {
        header: 'NOTIFICATIONS - Autres'
    },
    {
        text: 'Rappel conversations non-répondues',
        value: 'notification_reminder_conversations',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Nouvel avis',
        value: 'notification_new_voter',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Attestations TrustVille',
        value: 'notifications_trustville_certificate',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Inscription à la newsletter',
        value: 'newsletter_subscribed',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Réponses soumises pour validation (quotidien)',
        value: 'submitted_responses_notification',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Nouveau succès débloqué',
        value: 'notification_achievements_unlocked',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        divider: true
    }
]