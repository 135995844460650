<template>
  <div class="w-form-input d-flex align-center" :style="{ minHeight: minHeight }">
    <div :style="{ minWidth: labelMinWidth }">
      <slot name="label">
      </slot>
    </div>

    <div class="d-flex align-center">
      <slot name="input">
      </slot>
    </div>
  </div>
</template>

<script>
  export default{
    props: {
      minHeight: { type: String, required: false, default: "50px" },
      labelMinWidth: { type: String, required: false, default: "150px" }
    },
    name: "WFormInput"
  }
</script>

<style lang="stylus" scoped>
</style>