<template>
  <div class="w-stats-section pb-2" :class="{ 'd-none': !show }">
    <div class="fs-14 lh-16 c-primary uppercase pb-2">
      <slot name="header">
        {{ title }}
      </slot>
    </div>

    <v-row no-gutters>
      <slot name="content">
      </slot>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "WStatsSection",
    props: {
      title: { type: String, required: false, default: "" },
      show: { type: Boolean, required: false, default: true }
    },
    data() {
      return {

      }
    },
  }
</script>

<style lang="stylus" scoped>
 
</style>
