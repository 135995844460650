import ApiCall from '@api/apiCall.js'

export async function createLexicon(dashboardId) {
  const response = await new ApiCall().post("/api/wizville/lexicons", {
    dashboardId,
    dashboardType: "mono"
  })

  return response.data
}


export async function updateLexicon(lexiconId, locale, translationGrid) {
  const response = await new ApiCall().put(`/api/wizville/lexicons/${lexiconId}`, {
    locale,
    lexicon: translationGrid
  })

  return response.data
}