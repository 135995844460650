<template>
  <div>
    <div>
      <v-autocomplete
        v-model="primaryListSelectedItems"
        :items="primaryListItems"
        :label="this.$i18n.t(primaryListLabelI18nKey)"
        multiple
        clearable
        chips
        small-chips
        deletable-chips
      >
        <template v-slot:item="{ item }">
          <v-list-item-action>
              <v-checkbox
                :input-value="item.selected"
                class="pr-2"
              />
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title v-text="item.text" />
          </v-list-item-content>
          <v-list-item-action>
              <v-switch
                v-model="item.enabled"
                :disabled="!item.selected"
                :label="switchLabel(item)"
                :color="switchColor(item)"
                @click.stop="primaryListSelectedItemsChanged()"
                class="pr-2"
              />
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </div>
    <div>
      <v-autocomplete
        v-model="secondaryListSelectedItems"
        :items="secondaryListFilteredItems"
        :label="this.$i18n.t(this.secondaryListLabelI18nKey, { count: this.secondaryListFilteredItems.length })"
        multiple
        clearable
        chips
        small-chips
        deletable-chips
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WFilterableAutocompleteSet',
    data: function() {
      return {
        primaryListSelectedItems: [],
        secondaryListSelectedItems: [],
        secondaryListFilteredItems: this.secondaryListItems
      }
    },
    props: {
      primaryListItems: {
        type: Array,
        required: true
      },
      primaryListLabelI18nKey: {
        type: String,
        required: true
      },
      secondaryListItems: {
        type: Array,
        required: false
      },
      secondaryListLabelI18nKey: {
        type: String,
        required: true
      }
    },
    methods: {
      switchLabel(item){
        let translation_key = item.enabled ? 'yes' : 'no'
        return this.$i18n.t('components.autologin.advanced_search.' + translation_key)
      },
      switchColor(item){
        if(item.selected) return item.enabled ? 'green' : 'red'
        return 'grey'
      },
      updatePrimaryListItemsSelection(){
        this.primaryListItems.forEach(item => {
          item.selected = this.primaryListSelectedItems.includes(item.value)
          if(item.selected == false) item.enabled = item.defaultEnabled
        })
      },
      primaryListSelectedItemsWithState() {
        let itemsWithState = {}
        this.primaryListItems.filter(item => item.selected).forEach(async item => {
          itemsWithState[item.value] = item.enabled
        })
        return itemsWithState
      },
      primaryListSelectedItemsChanged(){
        this.updatePrimaryListItemsSelection()
        this.$emit("primary-list-selected-items-changed", this.primaryListSelectedItemsWithState())
      },
      secondaryListSelectedItemsChanged(){
        this.$emit("secondary-list-selected-items-changed", this.secondaryListSelectedItems)
      }
    },
    watch: {
      primaryListSelectedItems() {
        this.primaryListSelectedItemsChanged()
      },
      secondaryListSelectedItems() {
        this.secondaryListSelectedItemsChanged()
      },
      secondaryListItems() {
        this.secondaryListFilteredItems = this.secondaryListItems
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .v-autocomplete__content
    .v-list >>> .v-subheader
      font-size: 1.15rem

    .v-list >>> .v-list-item .v-list-item__action:first-child
      margin-right: 5px

    .v-input--switch >>> .v-label
      margin-left: 10px

    .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) >>> .v-input--switch__thumb
      color: #F44336

    .v-input--switch >>> .v-input--switch__track
      color: #F44336
</style>