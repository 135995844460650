// French [fr]
import dayjs from 'dayjs'
var locale = {
  name: 'fr',
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  weekStart: 1,
  yearStart: 4,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  relativeTime: {
    future: 'dans %s',
    past: '%s',
    s: '< 1 min',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 heure',
    hh: '%d heures',
    d: '1 jour',
    dd: '%d jours',
    M: '1 mois',
    MM: '%d mois',
    y: '1 an',
    yy: '%d ans'
  },
  ordinal: function ordinal(n) {
    var o = n === 1 ? 'er' : '';
    return "" + n + o;
  }
};
dayjs.locale(locale, null, true);
export default locale;
