
export const ADVANCED_SEARCH_USER_ATTRIBUTES = [
    {
        text: 'Droit sur tous les points de vente',
        value: 'rights_on_all_places',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Droit sur des régions',
        value: 'rights_on_regions',
        selected: false,
        enabled: true,
        defaultEnabled: true
    },
    {
        text: 'Droit sur des pays',
        value: 'rights_on_countries',
        selected: false,
        enabled: true,
        defaultEnabled: true
    }
]