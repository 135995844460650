function importDirectory(r) {
  let cache = {}
  let obj = {}

  r.keys().forEach(key => {
    cache[key] = r(key)
  });

  Object.keys(cache).forEach((moduleName) => {
    const name = moduleName.split("/").pop().split(".")[0]

    Object.keys(cache[moduleName]).forEach((apiFct) => {
      obj[name] = obj[name] || {}
      obj[name][apiFct] = cache[moduleName][apiFct]
    })
  })

  return (obj)
}

export default importDirectory(require.context('../helpers', true, /\.(js)$/))