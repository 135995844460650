<template>
  <div class="w-search-input d-flex" style="height: 20px">
    <v-text-field
      v-show="expanded"
      v-model="search"
      hide-details
      dense
      :clearable="clearable"
      regular
      class="pt-0 mt-0 ml-1"
      @focus="searchFocus = true"
      @blur="!search || search.length === 0 ? expanded = false : setSearch(search)"
      @keypress.enter="setSearch(search)"
      @keydown.esc="clear()"
      @click:clear="clear()"
      ref="input"
      :style="{ width: inputWidth }"
    />
    <v-icon class="text-right" @click="toggleInput()">mdi-magnify</v-icon>
  </div>
</template>

<script>
  export default {
    name: 'WSearchInput',
    props: {
      value: {
        type: String | null,
        required: true
      },
      minSize: {
        type: Number,
        required: false,
        default: 1
      },
      inputWidth: {
        type: String,
        required: false,
        default: "300px"
      },
      updateOnChange: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        search: this.value,
        searchFocus: false,
        expanded: this.value
      }
    },
    watch: {
      search(newVal) {
        if (this.updateOnChange) {
          this.setSearch(newVal)
        }
      }
    },
    methods: {
      toggleInput() {
        this.expanded = !this.expanded
        this.$nextTick(() => this.$refs.input.focus())
      },
      setSearch(search) {
        if (search && search.length > this.minSize) {
          this.$emit('input', this.search)
        } else {
          this.$emit('input', null)
        }
      },
      clear() {
        this.search = null
        this.expanded = false
        this.$emit('input', this.search)
      }
    }
  }
</script>

<style lang="stylus" scoped>


</style>
