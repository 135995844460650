import { jsPDF } from "jspdf";

import Page from './page';
import Row from './row';
import IconToBase64Exporter from '../icon-to-base64-exporter';
import { applyTextStyle } from './utils';

export default class Pdf {
  constructor({
    defaultOrientation = 'p',
    defaultBodyMargin = { left: 0, top: 0 },
    defaultTextColor = 120,
    defaultFontSize = 10,
    defaultFont = { family: 'SourceSansPro', style: 'regular' },
    defaultHeader,
    defaultFooter,
    firstPageParams
  } = {}) {
    this.defaultOrientation = defaultOrientation;
    this.defaultBodyMargin = defaultBodyMargin;
    this.defaultTextColor = defaultTextColor;
    this.defaultFontSize = defaultFontSize;
    this.defaultHeader = defaultHeader;
    this.defaultFooter = defaultFooter;
    this.defaultFont = defaultFont
    this.defaultBackgroundColor = '#FFF';
    this.iconToBase64Exporter = new IconToBase64Exporter();
    this.pageCount = 0;
  }

  async addPage({ orientation, margin, header, footer } = {}) {
    if (this.pageCount === 0) {
      this.doc = new jsPDF({
        orientation: orientation || this.defaultOrientation,
        unit: 'px',
        format: 'a4',
        hotfixes: ["px_scaling"]
      });
    } else {
      this.doc.addPage('a4', orientation || this.defaultOrientation);
    }
    this.currentPage = new Page({
      pdf: this,
      orientation: orientation || this.defaultOrientation,
      bodyMargin: margin || this.defaultBodyMargin,
      header: header || this.defaultHeader,
      footer: footer || this.defaultFooter
    });
    this.pageCount++;
    await this.currentPage.init();
  }

  async addRow({ height, backgroundColor, borders, borderColor, marginTop = 0, marginLeft = 0 } = {}, content) {
    if (this.currentPage.notEnoughtHeightInBody(height, marginTop)) {
      await this.addPage();
    }
    const row = new Row({
      pdf: this,
      parent: this,
      posX: this.currentPage.cursorX,
      posY: this.currentPage.cursorY,
      height: height,
      backgroundColor: backgroundColor,
      borders: borders,
      borderColor: borderColor,
      marginTop: marginTop,
      marginLeft: marginLeft,
      content: content
    });

    row.displayBackground();

    if (content) {
      await content(row);
    }

    row.height = row.height || row.maxColHeight;

    row.displayBorders();
    this.currentPage.cursorY += row.height + marginTop;
  }

  save(filename) {
    this.doc.save(filename);
  }

  computeTextHeight({ text, fontSize, containerWidth }) {
    applyTextStyle(this, null, null, fontSize);
    const dimensions = this.doc.getTextDimensions(
      this.doc.splitTextToSize(text, containerWidth)
    );
    return dimensions.h;
  }
}
