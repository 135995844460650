function capitalize(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

function random() {
  return (Math.random() + 1).toString(36).substring(7)
}

function slugify(str) {
  return str?.toLowerCase()
            ?.replace(/^\s+|\s+$/g, '')
            ?.replace(/[^a-z0-9-]/g, '')
            ?.replace(/\s+/g, '-')
            ?.replace(/-+/g, '-')
}

export {
  capitalize,
  random,
  slugify,
}
