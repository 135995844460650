import '../../i18n/setup'
import { i18n } from '@i18n/setup'
import { project } from "@shared/helpers/env-helpers.js"
import axios from 'axios'
import Vue from 'vue'

let WizClient = axios.create()

WizClient.defaults.responseType = 'json'

// Correctly format params sent to rails (Array/Hash params are sent as string without that)
WizClient.defaults.paramsSerializer = params => {
  const qs = require('qs')
  return qs.stringify(params, { arrayFormat: 'brackets' })
}

WizClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.wizvilleSessionToken}`
    config.headers['Accept-Language'] = i18n.locale
    config.headers['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    return config
  },
  (error) => { return Promise.reject(error) }
)

WizClient.interceptors.response.use(
  response => response,
  async error => {
    const response = error.response

    if (response.status === 401 && response.data?.message !== 'invalid_credentials') {
      if (!window.vueRouter?.history?.current?.path?.startsWith("/login")) {
        if (project === 'admin') {
          window.location.href = '/logout'
        } else {
          logout(response.data?.message)
        }
      }
    }
    
    if (response.status === 403) {
      if (project === 'admin') {
        window.location.href = '/logout'
      } else {
        if (window.vueRouter.history.current.path.startsWith("/dashboards")) {
          logout()
        } else {
          window.$store?.dispatch('notifyError', {
            message: Vue.prototype.$t('wizclient_403_error'),
            timeout: 6000
          })
          window.vueRouter.push('/dashboards')
        }
      }
      
      return
    }

    throw error
  }
)

function logout(logoutMessage = "") {
  window.location = `/logout?error=${logoutMessage}`
}

export default WizClient
