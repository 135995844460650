import ApiCall from '@api/apiCall.js'

export async function editTextAnalysisPreferences(campaignId, textAnalysisPreference) {
  const response =  await new ApiCall().post(`/api/wizville/text_analysis/${campaignId}/edit_text_analysis_preferences`, {
    textAnalysisPreference,
  });

  return response.data
}

export async function rescheduleAnnotationKo(campaignId, textAnalysisPreference) {
  const response =  await new ApiCall().post(`/api/wizville/text_analysis/${campaignId}/reschedule_annotation_ko`, {
    textAnalysisPreference,
  });

  return response.data
}

export async function editTextAnalysisPreferenceTemplates(campaignId, textAnalysisPreferenceTemplate) {
  const response =  await new ApiCall().post(`/api/wizville/text_analysis/${campaignId}/edit_text_analysis_preference_templates`, {
    textAnalysisPreferenceTemplate,
  });

  return response.data
} 
